import React, {Component} from 'react';
import AddAddressModalContainer from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/container/index";
import CheckoutCreditCardFormContainer from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/CheckoutCreditCardForm/container/index";
import {Redirect} from "react-router-dom";
import SEO from "components/SEO/index";

class SelectPaymentMethod extends Component {
  constructor(props){
    super(props);

    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
  }

  /**
   * Handles clicks on the add credit card button to open the modal.
   */
  handleAddButtonClick(){
    const creditCard = {};  // a new, empty credit card
    this.props.selectCreditCard(creditCard);
  }

  render(){
    /*
    IMPORTANT: If there is no selected shipping address in the store, the checkout process could not
    be completed, so we need to redirect the user to the cart for they to start over with the process.
    This will happen when the user gets here by reloading the page or by navigating to the url directly.
     */

    if( !this.props.checkoutReducer.selectedShippingAddressId )
      return <Redirect to="/store/cart"/>;

    return(
        <section id="cart">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Checkout</h1>
                <div className="alert alert-warning" role="alert" style={{textTransform: "none"}}>
                  If purchasing multiple items, you might expect separate deliveries.
                </div>
                <CheckoutCreditCardFormContainer/>
              </div>
            </div>
          </div>
          <AddAddressModalContainer/>
          <SEO url='checkout/payment'/>
        </section>
    )
  }
}

export default SelectPaymentMethod