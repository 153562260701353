import React from 'react';
import {Link} from "react-router-dom";
import Breadcrumb from "utils/components/Breadcrumb";
import SEO from "components/SEO/index";

const AboutMenu = ({match}) => (
    <section className="menu-list">
      <Breadcrumb
          crumbs={[
            {title: "About", to: "/about"}
          ]}/>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li>
                <Link to={`${match.url}/us`}>About us</Link>
              </li>
              <li>
                <Link to={`${match.url}/our-history`}>Our History</Link>
              </li>
              <li>
                <Link to={`${match.url}/now-hiring`}>Career Opportunities</Link>
              </li>
              <li>
                <Link to={`${match.url}/faq`}>FAQ</Link>
              </li>
              <li>
                <Link to={`${match.url}/privacy-policy`}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={`${match.url}/return-policy`}>Return Policy</Link>
              </li>
              <li>
                <Link to={`${match.url}/terms-of-use`}>Terms of Use</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <SEO url='about'/>
    </section>
);

export default AboutMenu;