import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import "./styles/AdvancedSearch.scss";
import { isEmpty, isEqual } from 'lodash';
import {renderInput, renderSelect} from "utils/forms/renderers";
import {number} from "utils/forms/validators";
import vintageHistory from "services/browser-history/index";
import {
  PRICE_GTE,
  PRICE_LTE, QUERY_PARAM_CAST,
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_DESCRIPTION,
  QUERY_PARAM_GAMING_PLATFORM,
  QUERY_PARAM_GENRES,
  QUERY_PARAM_MEDIA_TYPE,
  QUERY_PARAM_NAME,
  QUERY_PARAM_SKU
} from "scenes/Store/scenes/ProductsList/constants/query-params";
import vintageAxios from "services/api";
import {GAME, MOVIE} from "scenes/Store/scenes/AdvancedSearch/constants/constants";
import {NAME} from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/constants/constans";
import {FILTERS} from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/constants/filters";
import {withRouter} from "react-router-dom";
import {CATEGORIES_ID_SHOW} from "scenes/Store/scenes/ProductsList/constants/product-categories";

export class AdvancedSearchForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      moviesGenres: [],
      gamesGenres: [],
      categories: [],
      isEmpty: true,
    };

    this.onFilterClick = filter => {
      return this._handleFilterClick.bind(this, filter)
    };

    this.handleReset = this.handleReset.bind(this);

  }

  componentDidMount() {

    this.fetchGenres();
    this.fetchCategories().then(response => {
      const allCategories = response.data;
      /*Categories list to show*/
      const categories = allCategories.filter(item => {
        return CATEGORIES_ID_SHOW.find(
            category => category === item.id
        ) ? item : undefined;
      }).map(category => {
        /*Get subcategories*/
        category["subcategories"] = allCategories.filter(_category => _category.parent === category.id);
        return category;
      });

      // Save in Store all categories
      this.props.receiveCategories(allCategories);
      const options = AdvancedSearchForm.mediaTypeToShowFromCategories(allCategories);
      AdvancedSearchForm
          .addFilterToFiltersList(AdvancedSearchForm.buildObjectMediaType(options));
          
      this.setState({
        categories: categories,
        mediaType: AdvancedSearchForm.mediaTypeToShowFromCategories(allCategories)
      });

    });

    this.fetchPlatforms().then(response => {
      const options = AdvancedSearchForm.buildPlatformOptions(response.data.results);
      AdvancedSearchForm
          .addFilterToFiltersList(AdvancedSearchForm.buildObjectPlatform(options));
    });
  }

  /**
   * Fetch genres from the API.
   * @returns {AxiosPromise}
   */
  fetchGenres() {
    const promise = vintageAxios.get('/products/genres/');
    promise.then(response => {
      const genres = response.data.results;
      this.setState({
        moviesGenres: genres.filter(genre => genre.types.includes(MOVIE)),
        gamesGenres: genres.filter(genre => genre.types.includes(GAME))
      });
    });
    return promise;
  }

  /**
   * Fetch categories from the API.
   * @returns {AxiosPromise}
   */
  fetchCategories() {
    const {categories} = this.props;
    // Addresses already fetched, no need to re fetch them
    if (categories && categories.length > 0){
      return Promise.resolve({data: categories});
    }

    const promise = vintageAxios.get('/products/categories/');
    return promise;
  }

  /**
   * Fetch platforms from the API.
   * @returns {AxiosPromise}
   */
  fetchPlatforms() {
    const promise = vintageAxios.get('/products/platforms/');
    return promise;
  }

  /**
   * Save filter y redirect to FiltersOptions Component
   * @param {Object} filter
   */
  _handleFilterClick(filter) {
    this.props.selectFilter(null);
    this.props.updateAdvancedSearch(this.props.formValues);
    vintageHistory.push({
      pathname: `/store/advanced-search/filter`,
      search: `${this.props.location.search}&${NAME}=${filter.name}`
    });
  }

  /**
   * Create string with the selected options
   * @param {Object} filter
   * @return {String} string
   */
  _getLabelSelect(filter) {
    if (this.props.advancedSearch[filter.name] &&
        this.props.advancedSearch[filter.name].length > 0) {
      const length = this.props.advancedSearch[filter.name].length;
      let string = "";
      let item = null;
      this.props.advancedSearch[filter.name].forEach((option, index) => {
        if (
            filter.name !== QUERY_PARAM_MEDIA_TYPE
            && filter.name !== QUERY_PARAM_GAMING_PLATFORM
        )
          item = filter.options.find(item => item.value === option);
        else
          item = filter.options.find(item => item.value === parseInt(option, 10));
        string = string + item.label;
        if ((index + 1) < length) {
          string += ", ";
        }
      });

      return string;
    }

    return filter.options[0].label;
  }

  _validateFilters(){
    return FILTERS.find((filter)=>{
      return this.props.advancedSearch[filter.name]?.length > 0;
    })
  }

  /**
   * Add filter to filters list
   * @param filter
   */
  static addFilterToFiltersList(filter) {
    const isFound = FILTERS.find(item => item.name === filter.name);
    if (!isFound)
      FILTERS.unshift(filter);
  }

  /**
   * List of media type to show from categories
   * @param categories
   */
  static mediaTypeToShowFromCategories(categories) {
    const mediaTypeToShow = ["DVD", "Blu-ray", "Games"];
    return categories.filter(item => {
      return mediaTypeToShow.find(
          category => category === item.name
      ) ? item : undefined;
    })
  }

  /**
   * Build object media type with array of options to show
   * @param options
   * @return {{name: string, label: string, slug: string, options: *[]}}
   */
  static buildObjectMediaType(options) {
    const mediaType = {
      name: "media_type",
      label: "Media Type",
      slug: "media-type",
      options: [
        {
          value: "",
          label: "Select a media type"
        },
        {
          value: options.find(option => option.name === "DVD").id,
          label: "DVD"
        },
        {
          value: options.find(option => option.name === "Blu-ray").id,
          label: "Blu-ray"
        },
        {
          value: options.find(option => option.name === "Games").id,
          label: "Games"
        }
      ]
    };

    return mediaType;
  }

  /**
   * Build platform filter options from platforms list
   * @param platforms
   */
  static buildPlatformOptions(platforms) {
    let options = platforms.map(option => {
      return {
        value: option.id,
        label: option.name
      }
    });

    options.unshift({
      value: "",
      label: "Select a media type"
    });

    return options;
  }

  /**
   * Build object media type with array of options to show
   * @param options
   * @return {{name: string, label: string, slug: string, options: *[]}}
   */
  static buildObjectPlatform(options) {
    const platform = {
      name: QUERY_PARAM_GAMING_PLATFORM,
      label: "Platform",
      slug: QUERY_PARAM_GAMING_PLATFORM,
      options: options
    };

    return platform;
  }

  /**
   * Reset form fields.
   */
  handleReset() {
    this.props.resetAdvancedSearch();
    this.props.reset();
    FILTERS.forEach(filter => {
      this.props.updateSelectFilter(filter.name, []);
    });
  }

  _isEmptyForm() {
    return Object.values(this.props.advancedSearch)
      .every(value => value === undefined || value === null || value === "");
  }

  render() {
    const {moviesGenres, gamesGenres, categories} = this.state;
    const {handleSubmit} = this.props;

    return (
        <form id="advanced-search-form" className="form advanced-search-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name__search">Name</label>
            <Field id={QUERY_PARAM_NAME}
                   name={QUERY_PARAM_NAME}
                   component={renderInput}
                   type="text"
                   className="form-control"
                   placeholder='Type product name'/>
          </div>
          <div className="form-group">
            <label htmlFor={QUERY_PARAM_DESCRIPTION}>Short Description</label>
            <Field id={QUERY_PARAM_DESCRIPTION}
                   name={QUERY_PARAM_DESCRIPTION}
                   component={renderInput}
                   type="text"
                   className="form-control"
                   placeholder="Type short description"/>
          </div>
          <div className="form-group">
            <label htmlFor={QUERY_PARAM_SKU}>SKU</label>
            <Field id={QUERY_PARAM_SKU}
                   name={QUERY_PARAM_SKU}
                   component={renderInput}
                   type="text"
                   className="form-control"
                   placeholder="Type product SKU"/>
          </div>
          <div className="form-group">
            <div className="price-selector-range">
              <label id="price-label" htmlFor={PRICE_GTE}>Price</label>
              <div className="price-range">
                <div className="form-group">
                  <Field id={PRICE_GTE}
                         name={PRICE_GTE}
                         component={renderInput}
                         type="number"
                         aria-labelledby="price-label"
                         className="form-control"
                         validate={[number]}
                         placeholder="Min"/>
                </div>
                <div className="form-group">
                  <label htmlFor={PRICE_LTE} className="sr-only">Max Price</label>
                  <Field id={PRICE_LTE}
                         name={PRICE_LTE}
                         component={renderInput}
                         type="number"
                         aria-labelledby="price-label"
                         className="form-control"
                         validate={[number]}
                         placeholder="Max"/>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className="form-group">
            <label htmlFor={QUERY_PARAM_CAST}>Artist</label>
            <Field id={QUERY_PARAM_CAST}
                   name={QUERY_PARAM_CAST}
                   component={renderInput}
                   type="text"
                   className="form-control"
                   placeholder='Type an artist name'/>
          </div>
          <div className="form-group">
            <label htmlFor={QUERY_PARAM_GENRES}>Genre</label>
            <Field name={QUERY_PARAM_GENRES}
                   component={renderSelect}
                   className="form-control">
              <optgroup label="All">
                <option value=''>All</option>
              </optgroup>
              <optgroup label="Movies">
                {
                  moviesGenres.map(movieGenre => {
                    return <option
                        key={`movie_genre${movieGenre.id}`}
                        value={movieGenre.id}>
                      {movieGenre.name}
                    </option>
                  })
                }
              </optgroup>
              <optgroup label="Gaming">
                {
                  gamesGenres.map(gameGenre => {
                    return <option
                        key={`movie_genre${gameGenre.id}`}
                        value={gameGenre.id}>
                      {gameGenre.name}
                    </option>
                  })
                }
              </optgroup>
            </Field>
          </div>
          <hr/>
          <div className="form-group advanced-search-form__filters">
            {
              FILTERS.map((filter, index) =>
                  <div key={`select${index}`} onClick={this.onFilterClick(filter)}>
                    <label htmlFor={`${filter.label}`}>{filter.label}</label>
                    <div className="form-group has-feedback">
                      <div className="advanced-search-form__filters__field-box">
                        <span className="advanced-search-form__filters__field-box__label">
                          {this._getLabelSelect(filter)}
                        </span>
                        <span className="icon icon-arrow-right form-control-feedback icon-padding"/>
                      </div>
                      <Field multiple={true} type="select-multiple"
                             name={`${filter.label}`}
                             component={renderSelect}
                             className="form-control">
                        <option value="" disabled defaultValue={true}>
                          {this._getLabelSelect(filter)}
                        </option>
                      </Field>
                    </div>
                  </div>
              )
            }
          </div>
          <div className="form-group">
          <label htmlFor={QUERY_PARAM_CATEGORIES}>Categories</label>
          <Field name={QUERY_PARAM_CATEGORIES}
                  component={renderSelect}
                  className="form-control">
            <optgroup label="All">
              <option value=''>All</option>
            </optgroup>
            {
              categories.length > 0 ? categories.map(category => {
                if (category.subcategories.length > 0) {
                  return <optgroup label={category.name}
                                    key={`category${category.name}`}>
                    {
                      category.subcategories.map(subcategory => {
                        return <option
                          key={`subcategory${subcategory.id}`}
                          value={subcategory.id}>
                          {subcategory.name}
                        </option>
                      })
                    }
                  </optgroup>
                } else {
                  return <option 
                    value={category.id} 
                    key={`category${category.name}`}>
                    {category.name}
                  </option>;
                }
              }):null
            }
          </Field>
          </div>
          <input 
            type="submit" 
            disabled={(this.props.isFormEmpty || this.props.pristine) && !this._validateFilters()}
            className={(this.props.isFormEmpty || this.props.pristine) && !this._validateFilters() ? 'btn-disabled' : ''}
            value="SEARCH"/>
          <br/>
          <br/>
          <input 
            type="reset" 
            value="RESET" 
            className="btn btn-gray btn-block" 
            onClick={this.handleReset}/>
        </form>
    )
  }
}

const AdvancedSearchReduxForm = reduxForm({
  form: 'vintageAdvancedSearchForm',
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    const isFormEmpty = Object.values(values).every(
      value => value === undefined || value === null || value === ""
    );
    props.updateIsFormEmpty(isFormEmpty);
  }
})(AdvancedSearchForm);

export default withRouter(AdvancedSearchReduxForm);
