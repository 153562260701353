import React, {useEffect, useState} from "react"
import _ from "lodash"
import vintageAxios from "services/api"
import {useHistory, useLocation} from "react-router"
import QueryParams from "utils/QueryParams"
import {
  COMING_SOON,
  COMING_SOON_SLUG,
  NEW_THIS_WEEK,
  NEW_THIS_WEEK_SLUG
} from "scenes/Store/scenes/ProductsList/constants/product-categories"
import {QUERY_PARAM_CATEGORIES, QUERY_PARAM_VIEW_TYPE} from "scenes/Store/scenes/ProductsList/constants/query-params"
import FiltersBox from "scenes/Store/scenes/FiltersListV2/components/FiltersBox"

export default function CategoriesFilter() {
  const location = useLocation()
  const history = useHistory()
  const [categories, setCategories] = useState([])
  const [activeKey, setActiveKey] = useState(null)
  const queryParams = new QueryParams(location.search)

  /**
   * Fetch categories the first time
   */
  useEffect(() => {
    if (categories.length === 0)
      fetchCategories()
  }, [categories])

  const firstLinks = [
    {title: NEW_THIS_WEEK, slug: NEW_THIS_WEEK_SLUG},
    // {title: MOST_VIEWED, slug: MOST_VIEWED_SLUG},
    // {title: COMING_SOON, slug: COMING_SOON_SLUG}
  ];

  /**
   * Fetch categories from the API
   * @returns {AxiosPromise<any>}
   */
  function fetchCategories() {
    const promise = vintageAxios.get('/products/categories/')
    promise.then(response => {
      let categories = response.data;
      categories = _.filter(categories, category => category.parent !== null);
      // Products is the group with all the parent categories
      categories = _.filter(categories, category => category.parent.name !== "Products");
      categories = _.groupBy(categories, category => category.parent.name);
      setCategories(categories);
    })
    return promise
  }

  /**
   * Handles click on the header of the panel
   * @param category
   */
  function handleHeaderClick(category) {
    setActiveKey(category.id);
  }

  function handleChange(event) {
    let query_param = "" 
    let clean_param = ""

    if(NEW_THIS_WEEK_SLUG === event.target.value){
      clean_param = QUERY_PARAM_CATEGORIES;
      query_param = QUERY_PARAM_VIEW_TYPE;
    }else{
      clean_param = QUERY_PARAM_VIEW_TYPE;
      query_param = QUERY_PARAM_CATEGORIES;
    }

    queryParams.update({
      [clean_param]: null,
      [query_param]: event.target.value
    })
    history.push({
      pathname: location.pathname,
      search: queryParams.asSearchString
    })
  }

  return (
      <FiltersBox
          title="Categories">
        <ul>
          {
            firstLinks.map(({slug, title}) =>
                <li
                    key={slug}>
                  <div className="radio">
                    <label htmlFor={slug}>
                      <input
                          type="radio"
                          name="category"
                          id={slug}
                          value={slug}
                          checked={queryParams.find(QUERY_PARAM_VIEW_TYPE) === slug}
                          onChange={handleChange}/>
                      {title}
                    </label>
                  </div>
                </li>
            )
          }
          {
            Object.keys(categories).map(key => {
              const arr = categories[key];
              const category = arr[0].parent;

              return (
                  <React.Fragment key={key}>
                    <li>
                      <div className="radio">
                        <label htmlFor={`category${category.id}`}>
                          <input
                              type="radio"
                              name="category"
                              id={`category${category.id}`}
                              value={category.id}
                              checked={queryParams.find(QUERY_PARAM_CATEGORIES) === category.id.toString()}
                              onChange={handleChange}/>
                          {category.name}
                        </label>
                      </div>
                    </li>
                    <li>
                      <ul
                          style={{
                            paddingLeft: "30px",
                            marginBottom: "15px"
                          }}>
                        {
                          arr.map(({id, name}) =>
                              <li
                                  key={id}>
                                <div className="radio">
                                  <label htmlFor={`category${id}`}>
                                    <input
                                        type="radio"
                                        name="category"
                                        id={`category${id}`}
                                        value={id}
                                        checked={queryParams.find(QUERY_PARAM_CATEGORIES) === id.toString()}
                                        onChange={handleChange}/>
                                    {name}
                                  </label>
                                </div>
                              </li>
                          )
                        }
                      </ul>
                    </li>
                  </React.Fragment>
              )
            }
          )
          }
        </ul>
      </FiltersBox>
  )
}