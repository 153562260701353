import { connect } from 'react-redux';
import {
  receiveCategories, 
  selectFilter, 
  updateSelectFilter, 
  resetAdvancedSearch, 
  updateIsFormEmpty, 
  updateAdvancedSearch
} from "scenes/Store/actions/index";
import { getFormValues } from "redux-form";
import AdvancedSearchReduxForm from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/index";

const mapStateToProps = (state) => {
  return {
    initialValues: state.storeReducer.advancedSearch,
    advancedSearch: state.storeReducer.advancedSearch,
    categories: state.storeReducer.categories,
    isFormEmpty: state.storeReducer.isFormEmpty,
    formValues: getFormValues("vintageAdvancedSearchForm")(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectFilter: (filter, options) => dispatch(updateSelectFilter(filter, options)),
    selectFilter: filter => dispatch(selectFilter(filter)),
    receiveCategories: categories => dispatch(receiveCategories(categories)),
    resetAdvancedSearch: () => dispatch(resetAdvancedSearch()),
    updateIsFormEmpty: (isFormEmpty) => dispatch(updateIsFormEmpty(isFormEmpty)),
    updateAdvancedSearch: (values) => dispatch(updateAdvancedSearch(values)),
  };
};

const AdvancedSearchReduxContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvancedSearchReduxForm);

export default AdvancedSearchReduxContainer;