import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import Suggestion from "components/SearchBar/components/SuggestionsBox/components/Suggestion";
import {Link} from "react-router-dom";
import "./styles/SuggestionsBox.scss";
import vintageAxios from "services/api";
import debounce from "lodash/debounce";

/**
 * Fetch suggestions from the API
 * @param query {String}
 * @param productType {String}
 * @param onCompleted {Function}
 * @returns {Promise}
 */
function fetchSuggestions(query, productType, onCompleted) {
  if (!query || query.length < 2)
    return new Promise(resolve => resolve())
  let params = {
    search: query,
  };
  if (productType)
    params["type"] = productType
  const promise = vintageAxios.get("/misc/autocomplete/products/", {
    params
  });
  promise.then(response => {
    onCompleted(response.data)
  });
  return promise;
}

const debouncedFetch = debounce(fetchSuggestions, 500)

export default function SuggestionsBox({query, productType, onAfterSuggestionClick}) {
  const [loading, setLoading] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    if (query) {
      setLoading(true)
      setSuggestions([])  // reset them
      debouncedFetch(query, productType, onAfterFetch)
    } else {
      setLoading(false)
    }
  }, [query, productType])

  /**
   * After fetch callback for debounced fetch
   * @param suggestions
   */
  function onAfterFetch(suggestions) {
    setLoading(false)
    setSuggestions(suggestions)
  }

  return (
      <div className="search-bar__suggestions">
        {
          loading ? (
              <p className="text-center">
                Looking for results...
              </p>
          ) : (
              <React.Fragment>
                {
                  !query
                      ?
                      (
                          <p className="text-center">
                            Start typing to search!
                          </p>
                      )
                      :
                      (
                          <React.Fragment>
                            {
                              suggestions.length > 0 ? (
                                  <ul>
                                    {
                                      suggestions.map((suggestion, index) =>
                                          <Suggestion
                                              key={`suggestion${index}`}
                                              suggestion={suggestion}
                                              onAfterSuggestionClick={onAfterSuggestionClick}/>
                                      )
                                    }
                                  </ul>
                              ) : (
                                  <div>
                                    {
                                      query.length > 0 && suggestions.length === 0 && (
                                          <p className="text-center">
                                            We did not find anything matching your search criteria.
                                          </p>
                                      )
                                    }
                                  </div>
                              )
                            }
                          </React.Fragment>
                      )
                }
              </React.Fragment>
          )
        }
        <div className="link-advanced-search">
          <Link to="/store/advanced-search">
            <i className="fa fa-filter"/>
            &nbsp; Advanced Search</Link>
        </div>
      </div>
  )
}

SuggestionsBox.propTypes = {
  query: PropTypes.string,
  onAfterSuggestionClick: PropTypes.func.isRequired
};