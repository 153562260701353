import React from "react";
import SEO from "components/SEO"
import SortByFilter from "scenes/Store/scenes/FiltersListV2/components/SortByFilter"
import CategoriesFilter from "scenes/Store/scenes/FiltersListV2/components/CategoriesFilter"
import ConditionsFilter from "scenes/Store/scenes/FiltersListV2/components/ConditionsFilter"
import PriceFilter from "scenes/Store/scenes/FiltersListV2/components/PriceFilter"
import {useHistory, useLocation} from "react-router"
import AvailabilityFilter from "scenes/Store/scenes/FiltersListV2/components/AvailabilityFilter"
import "./styles/FiltersListV2.scss"

export default function FiltersListV2() {
  const history = useHistory()
  const location = useLocation()

  function handleBtnClick(event) {
    history.push({
      pathname: "/store/products",
      search: location.search
    })
  }

  return (
      <React.Fragment>
        <section className="filters-list">
          <div className="container">
            <AvailabilityFilter/>
            <SortByFilter/>
            <CategoriesFilter/>
            <ConditionsFilter/>
            <PriceFilter/>
            <button
                type="button"
                className="button button-filters"
                onClick={handleBtnClick}>
              APPLY FILTERS
            </button>
          </div>
        </section>
        <SEO url='store/filters'/>
      </React.Fragment>
  )
}