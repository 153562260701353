import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import "./styles/ProductImage.scss"
import {
  ALL_ICONS,
  DEFAULT_ICON
} from "scenes/Store/scenes/ProductsList/components/Product/components/ProductImage/contants/icons";
import ProductIcon
  from "scenes/Store/scenes/ProductsList/components/Product/components/ProductImage/components/ProductIcon";

class ProductImage extends Component {
  render() {
    const {productSlug, productName, productImageUrl, productIcon, withProductPhoto, onClick} = this.props;

    let icon = productIcon;
    if (ALL_ICONS.indexOf(icon) === -1)
      icon = DEFAULT_ICON;

    return (
        <div className="product__product-image">
          <div className="product__product-type text-center">
            <ProductIcon icon={icon}/>
          </div>
          {
            withProductPhoto &&
            <div className="product__photo text-center">
              {
                productSlug ?
                    <Link
                        to={`/store/products/${productSlug}`}
                        onClick={onClick}>
                      <img
                          src={productImageUrl}
                          alt={productName}
                          className="img-responsive"/>
                    </Link>
                    :
                    <img
                        src={productImageUrl}
                        alt={productName}
                        className="img-responsive"/>
              }
            </div>
          }
        </div>
    )
  }
}

ProductImage.propTypes = {
  productName: PropTypes.string.isRequired,
  productImageUrl: PropTypes.string.isRequired,
  productIcon: PropTypes.string,
  productSlug: PropTypes.string,
  withProductPhoto: PropTypes.bool,
  onClick: PropTypes.func
};

ProductImage.defaultProps = {
  withProductPhoto: true
};

export default ProductImage
