import { connect } from 'react-redux';
import ContactReduxForm from "../index";
import {change} from 'redux-form';

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: (field, value) => dispatch(
        change('vintageContactForm', field, value)
    )
  };
};

const ContactReduxFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactReduxForm);

export default ContactReduxFormContainer;