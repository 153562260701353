export const ORDER_AWAITING_PAYMENT = 0;
export const ORDER_CONFIRMED = 1;
export const ORDER_PROCESSING = 2;
export const ORDER_ISSUES = 3;
export const ORDER_COMPLETED = 4;
export const ORDER_REFUNDED = 5;
export const ORDER_PARTIALLY_REFUNDED = 6;
export const ORDER_RETURNED = 7;
export const ORDER_CANCELLED = 8;
export const ORDER_PULLED = 9;
export const ORDER_CLOSED = 10;
export const ORDER_AWAITING_PAYMENT_EXECUTION = 11;
export const ORDER_PARTIALLY_SHIPPED = 12;
export const ORDER_NEED_PAYMENT_REVIEW = 20;

export const ORDER_STATUSES = [
  [ORDER_AWAITING_PAYMENT, 'Awaiting payment'],
  [ORDER_CONFIRMED, 'Confirmed'],
  [ORDER_PROCESSING, 'Processing'],
  [ORDER_ISSUES, 'Issues'],
  [ORDER_COMPLETED, 'Completed'],
  [ORDER_REFUNDED, 'Refunded'],
  [ORDER_PARTIALLY_REFUNDED, 'Partially refunded'],
  [ORDER_RETURNED, 'Returned'],
  [ORDER_CANCELLED, 'Cancelled'],
  [ORDER_PULLED, 'Pulled'],
  [ORDER_CLOSED, 'Closed'],
  [ORDER_AWAITING_PAYMENT_EXECUTION, 'PayPal Awaiting Pay Execution'],
  [ORDER_PARTIALLY_SHIPPED, 'Partially Shipped'],
  [ORDER_NEED_PAYMENT_REVIEW, 'Need Payment Review'],
];