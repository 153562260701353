import React, {Component} from 'react';
import Breadcrumb from "utils/components/Breadcrumb/index";
import {getParsedQueryParams, updateQueryParams} from "services/browser-history/index";
import FiltersOptionsReduxFormContainer from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/components/FiltersOptions/components/FiltersOptionsForm/container/index";
import {FILTERS} from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/constants/filters";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import {NAME} from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/constants/constans";
import {PropTypes} from 'prop-types';
import NewsletterSubscribe from "components/NewsletterSubscribe/index";
import {
  QUERY_PARAM_ESRB_RATING, QUERY_PARAM_GAMING_PLATFORM, QUERY_PARAM_MEDIA_TYPE,
  QUERY_PARAM_MPAA_RATING
} from "scenes/Store/scenes/ProductsList/constants/query-params";
import vintageAxios from "services/api/index";
import {AdvancedSearchForm} from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/index";

class FiltersOptions extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    switch (getParsedQueryParams()[NAME]) {
      case QUERY_PARAM_MEDIA_TYPE:
        this.fetchCategories().then(response => {
          const allCategories = response.data;
          // Save in Store all categories
          this.props.receiveCategories(allCategories);
          const options = AdvancedSearchForm.mediaTypeToShowFromCategories(allCategories);
          this.props.selectFilter(AdvancedSearchForm.buildObjectMediaType(options));
        });
        break;
      case QUERY_PARAM_GAMING_PLATFORM:
        this.fetchPlatforms().then(response => {
          const options = AdvancedSearchForm.buildPlatformOptions(response.data.results);
          this.props.selectFilter(AdvancedSearchForm.buildObjectPlatform(options));
        });
        break;
      default:
        /**
         * Change the filter property of the store when obtaining parameter from url
         */
        this.props.selectFilter(FILTERS.find(e => e.name === getParsedQueryParams()[NAME]));
        break;
    }
  }

  /**
   * Fetch platforms from the API.
   * @returns {AxiosPromise}
   */
  fetchPlatforms() {
    const promise = vintageAxios.get('/products/platforms/');
    return promise;
  }

  /**
   * Fetch categories from the API.
   * @returns {AxiosPromise}
   */
  fetchCategories() {
    const {categories} = this.props;
    // Addresses already fetched, no need to re fetch them
    if (categories && categories.length > 0)
      return Promise.resolve({data: categories});

    const promise = vintageAxios.get('/products/categories/');
    return promise;
  }

  /**
   * Verify if selected other rating field when selected already
   */
  validateSelectedRating() {
    const filterName = getParsedQueryParams()[NAME];
    if (filterName === QUERY_PARAM_ESRB_RATING) {
      this.props.updateSelectFilter(QUERY_PARAM_MPAA_RATING, []);
      this.props.changeFieldValue(QUERY_PARAM_MPAA_RATING, []);
    }
    else if (filterName === QUERY_PARAM_MPAA_RATING) {
      this.props.updateSelectFilter(QUERY_PARAM_ESRB_RATING, []);
      this.props.changeFieldValue(QUERY_PARAM_ESRB_RATING, []);
    }
  }

  /**
   * Handles form submit.
   * @param {Object} data
   */
  handleSubmit(data) {
    this.validateSelectedRating();
    this.props.updateSelectFilter(this.props.filter.name, Object.keys(data).filter(key => {
      return data[key]
    }));
    updateQueryParams({name: null}, '/store/advanced-search');
  }

  render() {
    const {filter} = this.props;

    return (
        <div>
          {
            filter === null ? (
                <LoadingCassette/>
            ) : (
                <div>
                  <Breadcrumb
                      crumbs={[
                        {title: "Home", to: "/"},
                        {title: "Advanced Search", to: "/store/advanced-search"},
                        {title: `${filter.label}`, to: `/store/advanced-search/${filter.slug}`},
                      ]}/>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="body">
                        <FiltersOptionsReduxFormContainer
                            filter={filter}
                            onSubmit={this.handleSubmit}/>
                      </div>
                    </div>
                  </div>
                </div>
            )
          }
          <NewsletterSubscribe/>
        </div>
    )
  }
}

FiltersOptions.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })
};

export default FiltersOptions;