import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import "./styles/CreditCardList.scss"
import CreditCardInfo from "utils/components/CreditCardInfo";

class CreditCardList extends Component {
  constructor() {
    super();

    this.handleCreditCardChange = creditCard => this._handleCreditCardChange.bind(this, creditCard);
  }

  _handleCreditCardChange(creditCard) {
    this.props.setCreditCardAsDefault(creditCard);
    this.props.onAfterSelectCreditCard()
  }

  render() {
    const {creditCards, selectedCreditCard} = this.props;

    return (
        <div className="credit-card-list">
          <div className="radio">
            {
              creditCards.map(creditCard => (
                  <div
                      className=""
                      key={`checkoutPaymentMethod${creditCard.id}`}>
                    <div
                        className={classNames({
                          "credit-card-list__card payment-method__credit-card--details": true,
                          "credit-card-list__selected": selectedCreditCard === creditCard
                        })}>
                      <label>
                        <div className="credit-card-list__card--input">
                          <input
                              type="radio"
                              name="creditCard"
                              value={0}
                              onChange={this.handleCreditCardChange(creditCard)}
                              checked={creditCard.id === selectedCreditCard.id}/>
                        </div>
                        <CreditCardInfo
                            creditCard={creditCard}/>
                      </label>
                    </div>
                  </div>
              ))
            }
          </div>
          <hr/>
          <div className="checkout-step__body--actions">
            <a
                onClick={this.props.openCreditCardModal}
                className="btn btn-block btn-gray"><span>ADD NEW CARD</span>
            </a>
            <a
                onClick={this.props.onUseThisCreditCardButtonClick}
                className="btn btn-block"><span>USE THIS CARD</span>
            </a>
          </div>
        </div>
    )
  }
}

CreditCardList.propTypes = {
  onUseThisCreditCardButtonClick: PropTypes.func.isRequired,
  onAfterSelectCreditCard: PropTypes.func.isRequired,
  creditCards: PropTypes.array,
  selectedCreditCard: PropTypes.object
};

export default CreditCardList
