import {connect} from 'react-redux';
import CheckoutAddress from "../index";
import {selectCheckoutBillingAddress, selectCheckoutShippingAddress} from "scenes/Store/scenes/Checkout/actions/index";
import {selectAddress} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    checkoutReducer: state.checkoutReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectCheckoutShippingAddress: addressId => dispatch(selectCheckoutShippingAddress(addressId)),
    selectCheckoutBillingAddress: addressId => dispatch(selectCheckoutBillingAddress(addressId)),
    selectAddress: address => dispatch(selectAddress(address))
  };
};

const CheckoutAddressContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutAddress);

export default CheckoutAddressContainer;