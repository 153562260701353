import { connect } from 'react-redux';
import {selectOrder} from "scenes/MyAccount/actions/index";
import GuestOrderDetails from "scenes/MyAccount/scenes/GuestOrderDetails/index";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrder: order => dispatch(selectOrder(order))
  };
};

const GuestOrderDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GuestOrderDetails);

export default GuestOrderDetailsContainer;