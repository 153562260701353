import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AddressInfo from "utils/components/AddressInfo/index";
import vintageAxios from "services/api/index";
import classNames from 'classnames/bind';
import {DEFAULT_BY_SHIPPING} from "scenes/MyAccount/scenes/AddressBook/components/Address/constants/index";
import {addOrUpdateAddress, setAddressBookAsDefault} from "scenes/MyAccount/actions";
import {saveAddressInLocalStorage} from "scenes/Store/scenes/Checkout/services/storage";
import reduxStore from "redux/store";

class Address extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      settingAsDefault: false
    };

    this.handleSetAsDefaultClick = this.handleSetAsDefaultClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }


  /**
   * Handles clicks on edit button.
   */
  handleEditClick() {
    this.props.selectAddress(this.props.address);
  }

  /**
   * Deletes address through the API.
   * @returns {AxiosPromise}
   */
  deleteAddress() {
    const {address} = this.props;
    const promise = vintageAxios.delete(`/management/customers/addresses/${address.id}/`);
    promise.then(response => {
      this.props.removeAddress(this.props.address);
      this.setState({deleting: false});
    });
    return promise;
  }

  /**
   * Handles clicks on delete button.
   */
  handleDeleteClick() {
    this.setState({
      deleting: true
    }, () => {
      this.deleteAddress();
    });
  }

  /**
   * Set address book as default in the API and update state.
   * @returns {AxiosPromise}
   */
  setAsDefault(byDefaultAddress) {
    const {address} = this.props;
    const promise = Address.setAddressAsDefault(address, byDefaultAddress);
    promise.then(response => {
      this.props.setAddressBookAsDefault(response.data);
      this.setState({
        settingAsDefault: false
      });
    });
    return promise;
  }

  /**
   * Set address book as default in the API.
   * @param {Object} address
   * @param {Number} byDefaultAddress
   * @returns {AxiosPromise<any>}
   */
  static setAddressAsDefault(address, byDefaultAddress) {
    const promise = vintageAxios.put(`/management/customers/addresses/${address.id}/`, {
      ...address,
      default: true,
      address_type: byDefaultAddress
    });
    return promise;
  }

  /**
   * 1. Create or update an address in the API, dispatch addOrUpdateAddress action
   * 2. If this address is default by shipping, calls Address.setAddressAsDefault and dispatch
   *  setAddressBookAsDefault action.
   * 3. Calls saveAddressInLocalStorage utils function.
   * @param address
   * @return {AxiosPromise}
   */
  static createOrUpdateAddress(address) {
    let promise;
    if (address.id)  // edit mode
      promise = vintageAxios.put(`/management/customers/addresses/${address.id}/`, address);
    else  // create mode
      promise = vintageAxios.post('/management/customers/addresses/', address);

    promise.then(response => {
      reduxStore.dispatch(addOrUpdateAddress(response.data));

      let promiseAddressByDefault = null;
      if (address["byDefaultAsShipping"])
        promiseAddressByDefault = Address.setAddressAsDefault(response.data, DEFAULT_BY_SHIPPING);

      if (promiseAddressByDefault)
        promiseAddressByDefault.then(response => {
          reduxStore.dispatch(setAddressBookAsDefault(response.data));
          saveAddressInLocalStorage(response.data);
        });
      else
        saveAddressInLocalStorage(response.data);
    });

    return promise
  }

  /**
   * Handles clicks on the set as default button.
   */
  handleSetAsDefaultClick() {
    if (!this.state.settingAsDefault)
      this.setState({
        settingAsDefault: true
      }, () => {
        this.setAsDefault(DEFAULT_BY_SHIPPING);
      });
  }

  render() {
    const {address} = this.props;
    const {deleting, settingAsDefault} = this.state;
    const isShowSetAsDefault = !address.default
        || (address.default && address.address_type !== DEFAULT_BY_SHIPPING);

    return (
        <div className={classNames({
          "address-item": true,
          "address-item--default": address.default
          && address.address_type === DEFAULT_BY_SHIPPING
        })}>
          <div className="address-item__content">
            <div className="row">
              <div className="col-xs-5">
                <p>{address.first_name} {address.last_name}</p>
              </div>
              <div className="col-xs-7 address-item__by-default">
                {
                  address.default && address.address_type === DEFAULT_BY_SHIPPING &&
                  <p className="address-item__by-default__shipping">
                    default for shipping
                  </p>
                }
              </div>
            </div>
            <div className="address-item__content__address">
              <h6>Address</h6>
              <p><span>{address.address_1}</span></p>
              <AddressInfo
                  address={address}/>
            </div>
            <div className="address-item__content__email">
              <h6>Email</h6>
              <p>{address.email}</p>
            </div>
          </div>
          <hr/>
          <div className="address-item__actions address-item__actions--center">
            <div className="address-item__actions__edit address-item__actions__hover">
              <span onClick={this.handleEditClick}>
                <i className="fa fa-pencil"/>Edit
              </span>
            </div>
            <div className={classNames({
              "address-item__actions__delete": true,
              "address-item__actions__delete--bar-right": !address.default,
              "address-item__actions__hover": true
            })}>
              <span onClick={this.handleDeleteClick}>
                <i className="fa fa-trash"/>
                {deleting ? 'Deleting...' : 'Delete'}
              </span>
            </div>
            <div className="address-item__actions__status">
              {
                settingAsDefault ? (
                    <span
                        className="address-item__actions__status__setting">Setting...</span>
                ) : (
                    isShowSetAsDefault && (
                        <span
                            className="address-item__actions__hover address-item__actions__status__set-as-default"
                            onClick={this.handleSetAsDefaultClick}>
                          <i className="fa fa-check-square"/>Set as default
                        </span>
                    )
                )
              }
            </div>
          </div>
        </div>
    )
  }
}

Address.propTypes = {
  address: PropTypes.object.isRequired
};

export default Address;