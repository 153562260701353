import React, {Component} from 'react';
import NewsletterModal from "scenes/Home/components/NewsletterModal/index";
import SubscribedModal from "components/NewsletterSubscribe/components/SubscribedModal/index";

class NewsletterSubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewsletterModal: false,
      subscribedModalIsOpen: false,
      success: null
    };
    this.handleSubscribeButtonClick = this.handleSubscribeButtonClick.bind(this);
    this.onHideNewsletterModal = this.onHideNewsletterModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeSubscribedModal = this.closeSubscribedModal.bind(this);
  }

  /**
   * Handle modal open.
   */
  handleSubscribeButtonClick() {
    this.setState({
      showNewsletterModal: true
    })
  }

  /**
   * Handle modal close.
   */
  onHideNewsletterModal() {
    this.setState({
      showNewsletterModal: false
    })
  }

  handleSubmit(success) {
    this.setState({
      subscribedModalIsOpen: true,
      success
    })
  }

  closeSubscribedModal() {
    this.setState({
      subscribedModalIsOpen: false
    });
  }

  render() {
    const {showNewsletterModal, subscribedModalIsOpen, success} = this.state;
    return (
        <div>
          <section id="newsletter">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3>Sign Up For Free Special Saving Offers!</h3>
                  <p>Get exclusive savings offers, along with new product releases!</p>
                  <p>
                    <button
                        type="button"
                        className="btn btn-blue btn-block"
                        onClick={this.handleSubscribeButtonClick}>
                      SIGN ME UP!
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <NewsletterModal
              show={showNewsletterModal}
              onHide={this.onHideNewsletterModal}
              onSubmit={this.handleSubmit}/>
          <SubscribedModal
              show={subscribedModalIsOpen}
              success={success}
              onHide={this.closeSubscribedModal}/>
        </div>
    );
  }
}

export default NewsletterSubscribe;