import React from 'react';
import PropTypes from 'prop-types';
import "./styles/AddressInfo.scss";

const AddressInfo = ({address}) => (
    <span>
      <p className="address-info">
        <strong>{address.first_name} {address.last_name}</strong><br/>
        {address.address_1}&nbsp;
      </p>
      <p>
        {address.city_name}, {address.region_name}&nbsp;
        {address.country_code} {address.zip_code}
      </p>
    </span>

);

AddressInfo.propTypes = {
  address: PropTypes.object.isRequired
};

export default AddressInfo;
