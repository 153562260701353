import { connect } from 'react-redux';
import AddressBook from "../index";
import {receiveAddresses, selectAddress} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveAddresses: addresses => dispatch(receiveAddresses(addresses)),
    selectAddress: address => dispatch(selectAddress(address)),
  };
};

const AddressBookContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressBook);

export default AddressBookContainer;