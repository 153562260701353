import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form'
import {Link} from "react-router-dom";
import validate from "./validate";
import {ORDER_ITEM_CANCELLATION_REASONS, ORDER_ITEM_RETURN_REASONS} from "./constants/return-reasons";
import ItemToBeReturned from "../ItemToBeReturned";
import {CANCEL, RETURN} from "scenes/MyAccount/constants/action";
import {CANCELLED, RETURNED} from "scenes/MyAccount/constants/status";
import {renderCheckbox, renderSelect} from "utils/forms/renderers";
import {NORMAL_ITEM, SHIPMENT_ITEM} from "scenes/MyAccount/constants/order-item-type";

const OrderItemList = ({itemList, type}) => {
  return(
      itemList.map((item, index) =>
          <div className="item" key={`orderItem${item.order_item_data.id}`}>
            <ItemToBeReturned
                item={item.order_item_data} type={type} />
          </div>)
  );
}

const renderItems = ({ fields, returnItemsForm, order, action, meta: { error, submitFailed } }) => {
  const reasons = action === RETURN ? ORDER_ITEM_RETURN_REASONS : ORDER_ITEM_CANCELLATION_REASONS;
  return(
      <div>
        <OrderItemList itemList={order.items.returned} type={RETURNED} />
        <OrderItemList itemList={order.items.cancelled} type={CANCELLED} />
        {
          fields.map((member, index) => (
              <div className="item" key={`orderItem${index}`}>
                <div className="custom-checkbox">
                  <Field
                      name={`${member}.will_be_returned`}
                      component={renderCheckbox} />
                </div>
                <ItemToBeReturned item={order.items[action === CANCEL ? NORMAL_ITEM : SHIPMENT_ITEM][index].order_item_data} />
                {
                  returnItemsForm && returnItemsForm.values.items[index].will_be_returned &&
                  <div>
                    <div className="form-group">
                      <label htmlFor={`${member}.return_reason`}>
                        What is your reason for&nbsp;
                        {action === RETURN ? 'returning' : 'canceling'}?</label>
                      <Field
                          name={`${member}.return_reason`}
                          component={renderSelect}
                          className="form-control">
                        <option value="">Select an option</option>
                        {
                          reasons.map((reason, index) =>
                              <option
                                  key={`returnReason${index}`}
                                  value={reason[0]}>
                                {reason[1]}
                              </option>
                          )
                        }
                      </Field>
                    </div>
                  </div>
                }
              </div>
          ))
        }
      </div>
  )
}

const ReturnItemsForm = ({handleSubmit, pristine, reset, submitting, returnItemsForm, myAccountReducer, loading, action}) => {
  const order = myAccountReducer.selectedOrder;

  return (
      <form onSubmit={handleSubmit}>
        <FieldArray
            name="items"
            component={renderItems}
            // Send returnItemsForm coming from the redux store in order to have context of the value in the
            // will_be_returned field, so we can show and hide the <select> input.
            returnItemsForm={returnItemsForm}
            // Send the selected order from the redux store in order to have information of the items.
            order={order} action={action} />

        <div className="options">
          <button
              className="btn-custom"
              type="submit" disabled={submitting || loading}>
            {
              !loading ? (
                  <span>{action === RETURN ? 'RETURN' : 'CANCEL' }</span>
              ) : (
                  <span>{action === RETURN ? 'RETURNING ...' : 'CANCELING ...' }</span>
              )
            }
          </button>
          <br/>
          <br/>
          <Link to={`/my-account/order-history/${order.uuid}`}><span
              className="btn-custom cancel">GO BACK</span></Link>
        </div>
      </form>
  )
}

ReturnItemsForm.propTypes = {
  action: PropTypes.oneOf([RETURN, CANCEL]),
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  myAccountReducer: PropTypes.shape({
    selectedOrder: PropTypes.object.isRequired
  })
}

export default reduxForm({
  form: 'returnItemsForm',
  validate
})(ReturnItemsForm)