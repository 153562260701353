import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames/bind";
import {NEW, USED} from "utils/constants/product-conditions";
import "./styles/ProductCondition.scss";

const ProductCondition = ({condition}) => (
    <span className={classNames({
      "item-condition": true,
      "item-condition--new": condition === NEW,
      "item-condition--used": condition === USED
    })}>
      {condition === NEW ? ('New') : ('Used')}
    </span>
);

ProductCondition.propTypes = {
  condition: PropTypes.number.isRequired
};

export default ProductCondition;
