import React, {useState} from "react";
import PropTypes from "prop-types";
import StoreAvailabilityModal from "scenes/Store/components/StoreAvailabilityModal"

export default function FindInStoreBtn({className}) {
  const [showModal, setShowModal] = useState(false);

  /**
   * Handles check availability button click to open the modal
   * @param event {Object}
   */
  function handleClick(event) {
    setShowModal(true);
  }

  /**
   * Handles modal onHide callback
   */
  function handleModalOnHide() {
    setShowModal(false);
  }

  return (
      <React.Fragment>
        <button
            className={className}
            onClick={handleClick}>
          <span className="store-availability__available-text">FIND IN STORE</span>
        </button>
        <StoreAvailabilityModal
            show={showModal}
            onHide={handleModalOnHide}/>
      </React.Fragment>
  );
}

FindInStoreBtn.propTypes = {
  className: PropTypes.string
};