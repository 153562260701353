import React from 'react';
import "./styles/Footer.scss";
import {Link} from "react-router-dom";
import TrustElements from "utils/components/TrustElements";

const Footer = ( ) => (
    <footer>
      <div className="footer-trust-elements">
        <div className="container">
          <TrustElements/>
          <hr/>
        </div>
      </div>
      <div className="top">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mobile">
                <p>Follow us for deals and promotions</p>
                <p>
                  <a href="https://twitter.com/vintagestocksgf" aria-label="twitter icon" target="_blank" rel="noopener noreferrer">
                    <span className="icon-twitter" aria-hidden="true"/>
                  </a>
                  &nbsp;
                  <a href="https://web.facebook.com/VintageStock/" aria-label="facebook icon" target="_blank" rel="noopener noreferrer">
                    <span className="icon-facebook" aria-hidden="true"/>
                  </a>
                </p>
                <hr/>
                <ul>
                  <li><Link to='/about/terms-of-use'>Terms</Link></li>
                  <li><Link to='/about/privacy-policy'>Privacy</Link></li>
                  <li>
                    <Link to="/about/faq">FAQs</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>© {(new Date("2008").getFullYear())}–{new Date().getFullYear()} {process.env.REACT_APP_SITE_NAME}. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
);

export default Footer;
