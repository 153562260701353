import { connect } from 'react-redux';
import ForgotPassword from "scenes/Auth/scenes/ForgotPassword/index";

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const ForgotPasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);

export default ForgotPasswordContainer;