import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {Link} from "react-router-dom";
import {renderInput} from "utils/forms/renderers";
import {required} from "utils/forms/validators";
import PropTypes from "prop-types";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";
import validateResetPasswordForm from "scenes/Auth/scenes/ResetPassword/components/ResetPasswordForm/validate";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";

export class ResetPasswordForm extends Component {
  render() {
    const {status, handleSubmit, errors} = this.props;

    return (
        <form
            className="form form-auth"
            onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="new_password">New password <span className="required">*</span></label>
            <Field id="new_password"
                   name="new_password"
                   component={renderInput}
                   type="password"
                   className="form-control"
                   validate={[required]}
                   placeholder="New password"/>
          </div>
          <div className="form-group">
            <label htmlFor="re_new_password">Confirm new Password <span className="required">*</span></label>
            <Field id="re_new_password"
                   name="re_new_password"
                   component={renderInput}
                   type="password"
                   className="form-control"
                   validate={[required]}
                   placeholder="Confirm new password"/>
            {
              errors && errors.map((error, index) => (
                  <span
                      key={`passwordError${index}`}
                      className="help-block">
                  {error}
                </span>
              ))
            }
          </div>
          {/*<Recaptcha
              afterCheckedRecaptcha={this.props.afterCheckedRecaptcha}
              onExpiredRecaptcha={this.props.onExpiredRecaptcha}/>*/}
          <br/>
          <div className="form-group">
            <button type="submit"
                    disabled={
                      status === LOADING_STATUS
                      || status === SUCCESSFUL_STATUS
                      || this.props.checkedRecaptcha}>
              {
                status === LOADING_STATUS ? (
                    <span>SENDING...</span>
                ) : (
                    status === SUCCESSFUL_STATUS ? (
                        <span>
                          <i className="fa fa-check"/>SENT
                        </span>
                    ) : (
                        <span>SEND</span>
                    )
                )
              }
            </button>
          </div>
          <div className="options">
            <p className="text-center">
              <Link to="/auth/login"><span>Login</span></Link>
              <span className="vline"/>&nbsp;|&nbsp;
              <Link to="/auth/signup"><span className="green">Create an Account&nbsp;</span></Link>
            </p>
          </div>
        </form>
    )
  }
}

ResetPasswordForm.propsTypes = {
  status: PropTypes.oneOf(
      [INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS]
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default withRecaptcha(reduxForm({
  form: 'resetPasswordForm',
  validate: validateResetPasswordForm
})(ResetPasswordForm));