import React, {useEffect} from 'react';
import {Redirect} from "react-router-dom";
import SEO from "components/SEO/index";
import vintageAxios from "services/api";
import {getCartSession} from "services/store";
import {useHistory} from "react-router"

export default function SelectShippingMethod({checkoutReducer: {selectedShippingAddressId}, cartTotals, receiveCartTotals}) {
  const history = useHistory()

  useEffect(() => {
    if (selectedShippingAddressId)
      fetchTotals()
  }, [])

  /**
   * Handles submit event on the <form> and redirects to next step.
   * @param {Object} event
   */
  function handleFormSubmit(event) {
    event.preventDefault();
    history.push({
      pathname: '/store/checkout/payment'
    });
  }

  /**
   * Fetch totals from the API.
   */
  function fetchTotals() {
    let data = {},
        url = `/management/shopping-carts/${getCartSession()}/totals/`;
    data["shipping_address"] = selectedShippingAddressId;
    const promise = vintageAxios.post(url, data);
    promise.then(response => {
      receiveCartTotals(response.data);
    });
    return promise;
  }

  /*
  IMPORTANT: If there is no selected shipping address in the store, the checkout process could not
  be completed, so we need to redirect the user to the cart for they to start over with the process.
  This will happen when the user gets here by reloading the page or by navigating to the url directly.
   */

  if (!selectedShippingAddressId)
    return <Redirect to="/store/cart"/>;

  return (
      <section id="cart">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Checkout</h1>
              <div className="checkout-step checkout-step--shipping-method">
                <div className="checkout-step__header">
                  <h2>
                    <span className="circle">2</span>
                    <span>Shipping Method</span>
                  </h2>
                </div>
                <div className="checkout-step__body">
                  <form onSubmit={handleFormSubmit}>
                    <div className="radio">
                      <label>
                        <input type="radio" name="optionsRadios" id="optionsRadios1" value="option1" defaultChecked/>
                        {
                          !cartTotals.shipping_fee ? (
                              <div>
                                <strong>Standard Shipping</strong>&nbsp;<span className="price">$4.95</span>
                              </div>
                          ) : (
                              parseFloat(cartTotals.shipping_fee) > 0 && (
                                  <div>
                                    <strong>Standard Shipping</strong>&nbsp;<span
                                      className="price">${cartTotals.shipping_fee}</span>
                                  </div>
                              )
                          )
                        }
                      </label>
                    </div>
                    <br/>
                    <input className="btn btn-block" type="submit" value="CHOOSE PAYMENT METHOD"/>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SEO url='checkout/shipping'/>
      </section>
  )
}