import React, {Component} from 'react';
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import {NEW, USED} from "utils/constants/product-conditions";
import {removeFromWishList} from "services/store/wishlist/index";
import AddToCartButtonContainer from "scenes/Store/scenes/ProductDetails/components/AddToCartButton/container/index";

class WishListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    };

    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  /**
   * Deletes the item from the wish list through the API.
   * @returns {AxiosPromise}
   */
  deleteWishListItem() {
    const promise = removeFromWishList(this.props.item);
    promise.then(response => {
      this.props.removeWishListItem(this.props.item);
    });
    return promise;
  }

  /**
   * Handles clicks on the delete button. Changes the state and calls the API.
   */
  handleDeleteButtonClick() {
    this.setState({
      deleting: true
    }, () => {
      this.deleteWishListItem();
    });
  }

  render() {
    const {item, readOnly} = this.props;
    const {deleting} = this.state;

    return (
        <div className="wish-item">
          <div className="product">
            <div className="photo">
              <img
                  src={item.profile_picture}
                  alt={item.product_name}/>
            </div>
            <div className="info">
              <p>{item.product_name}</p>
              <p>
                <span className="cond">
                  {item.condition === NEW ? 'NEW' : 'USED'}
                </span>
                <span className={classNames({
                  "price": true,
                  "new": item.condition === NEW,
                  "used": item.condition === USED,
                })}>
                  ${item.condition === NEW ? item.product_price_new : item.product_price_used}
                </span>
              </p>
            </div>
          </div>
          <div className="options">
            <AddToCartButtonContainer
                contentTypeId={item.content_type}
                productId={item.object_id}
                quantity={1}
                selectedCondition={item.condition}
            />
            {
              !readOnly &&
              <button
                  className="options__delete right"
                  disabled={deleting}
                  onClick={this.handleDeleteButtonClick}>
              <span className="remove">
                {deleting ? 'Deleting...' : 'Delete'}
              </span>
              </button>
            }
          </div>
        </div>
    )
  }
}

WishListItem.defaultProps = {
  readOnly: false
};

WishListItem.propTypes = {
  item: PropTypes.object.isRequired,
  readOnly: PropTypes.bool
};

export default WishListItem;