import React, {useState} from 'react';
import PropTypes from 'prop-types';
import "./styles/Product.scss";
import {PRODUCTS_LIST_AS_LIST} from "scenes/Store/scenes/ProductsList/constants/constants";
import ProductGridView from "scenes/Store/scenes/ProductsList/components/Product/components/ProductGridView/index";
import ProductListView from "scenes/Store/scenes/ProductsList/components/Product/components/ProductListView/index";
import {ProductContext} from "@vintagestock/vintage-common-components";
import {NEW, USED} from "utils/constants/product-conditions";
import {useLocation} from "react-router";
import QueryParams from "utils/QueryParams";
import {
  QUERY_PARAM_PICKUP_AVAILABLE,
  QUERY_PARAM_PRODUCT_CONDITION,
  QUERY_PARAM_STORE
} from "scenes/Store/scenes/ProductsList/constants/query-params";
import {parseCondition} from "utils/products";
import {
  RADIO_OFFLINE_NEW,
  RADIO_OFFLINE_USED,
  RADIO_ONLINE_NEW,
  RADIO_ONLINE_USED
} from "scenes/Store/components/PriceTable/constants/radios"
import queryString from "query-string"

export default function Product({product, viewAs, selectProduct}) {
  const location = useLocation()
  const queryParams = new QueryParams(location.search)
  const [quantity, setQuantity] = useState(1);
  const [activeRadio, setActiveRadio] = useState(
      product.total_stock_used > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined
          ?
          RADIO_ONLINE_USED
          :
          product.is_pre_order || (product.total_stock_new > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined)
              ?
              RADIO_ONLINE_NEW
              :
              product.pickup_available_used
                  ?
                  RADIO_OFFLINE_USED
                  :
                  RADIO_OFFLINE_NEW
  );
  const [condition, setCondition] = useState((activeRadio === RADIO_ONLINE_USED || activeRadio === RADIO_OFFLINE_USED) ? USED : NEW)

  /**
   * Parses the product status from query params and returns it or null by default.
   * @returns {Number} productStatus
   */
  function productStatusQueryParam() {
    const conditionQueryParam = queryParams.find(QUERY_PARAM_PRODUCT_CONDITION);
    return conditionQueryParam ? parseCondition(conditionQueryParam) : null;
  }

  /**
   * Determine the right url to redirect the user when a product is clicked.
   * This is based on "product-status" query params, which determines the product condition to give priority to.
   * @returns {Object}
   */
  function productUrl() {
    let params = {}

    const productStatus = productStatusQueryParam();
    if (productStatus)
      params[QUERY_PARAM_PRODUCT_CONDITION] = productStatus

    const store = queryParams.find(QUERY_PARAM_STORE)
    if (store)
      params[QUERY_PARAM_STORE] = store

    const pickupAvailable = queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE)
    if (pickupAvailable)
      params[QUERY_PARAM_PICKUP_AVAILABLE] = pickupAvailable

    return {
      pathname: `/store/products/${product.slug}`,
      search: `?${queryString.stringify(params)}`
    };
  }

  /**
   * Handles clicks on the product.
   */
  function handleProductClick() {
    selectProduct(product);
  }

  return (
      <ProductContext.Provider value={{
        product,
        condition,
        setCondition,
        quantity,
        setQuantity,
        activeRadio,
        setActiveRadio
      }}>
        {
          viewAs && viewAs === PRODUCTS_LIST_AS_LIST
              ?
              <ProductListView
                  productUrl={productUrl()}
                  onClick={handleProductClick}/>
              :
              <ProductGridView
                  productUrl={productUrl()}
                  onClick={handleProductClick}/>
        }
      </ProductContext.Provider>
  );
}

Product.propTypes = {
  product: PropTypes.object.isRequired
};
