import React, {Component} from 'react';
import AddressReduxFormContainer
  from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressForm/container/index";
import {Modal} from "react-bootstrap";
import Address from 'scenes/MyAccount/scenes/AddressBook/components/Address/index';
// TODO test doesn't works importing it this way:
// import Modal from "react-bootstrap/es/Modal";

class AddAddressModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: false,
      errors: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Close this modal after a second.
   */
  closeModal() {
    setTimeout(() => {
      this.props.deselectAddress();
      this.setState({success: false});  // reset it
    }, 1000);
  }

  /**
   * Creates or edits an address.
   * @param {Object} data
   * @returns {AxiosPromise<any>}
   */
  saveAddress(data) {
    let promise;
    promise = Address.createOrUpdateAddress(data);

    promise.then(response => {
      this.setState({
        loading: false,
        success: true
      });
      this.closeModal();

    });

    promise.catch(error => {
      this.setState({
        loading: false,
        errors: error.response.data
      });
    });
    return promise;
  }

  /**
   * Handles form submit
   * @param {Object} data
   */
  handleSubmit(data) {
    this.setState({
      loading: true
    }, () => {
      this.saveAddress(data);
    });
  }

  render() {
    const {myAccountReducer, deselectAddress} = this.props;
    const {loading, errors, success} = this.state;

    return (
        <Modal
            show={myAccountReducer.selectedAddress !== null}
            onHide={deselectAddress}>
          <Modal.Header closeButton>
            <Modal.Title>
              {(myAccountReducer.selectedAddress && myAccountReducer.selectedAddress.id) ? 'Edit Address' : 'Add Address'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddressReduxFormContainer
                onSubmit={this.handleSubmit}
                isLoading={loading}
                success={success}
                errors={errors}/>
          </Modal.Body>
        </Modal>
    )
  }
}

export default AddAddressModal;