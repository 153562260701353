import React, {Component} from 'react';
import ContactReduxFormContainer from "./components/ContactForm/container/index";
import vintageAxios from "services/api";
import "./styles/Contact.scss";
import SEO from "components/SEO/index";

class Contact extends Component {
  constructor(props){
    super(props);

    this.state = {
      success: false,
      loading: false,
      errors: {}
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Creates the contact message through the API
   * @param {Object} data
   */
  createContactMessage(data){
    const promise = vintageAxios.post('/misc/contact-us/', data);
    promise.then(response => {
      this.setState({
        loading: false,
        success: true
      });
    });
    promise.catch(error => {
      this.setState({
        errors: error.response.data,
        loading: false
      });
    });
    return promise;
  }

  /**
   * Handles submit on the form element
   * @param {Object} data
   */
  handleFormSubmit(data){
    if (data.message_type !== 'store' && data.store)
      delete data.store;

    return this.setState({
      loading: true
    }, () => {
      this.createContactMessage(data);
    });
  }

  render(){
    const {success, loading, errors} = this.state;

    return(
        <section className="page-content">
          <br/>
          <div className="container">
            <div className="wrapper">
              <h1>Contact us</h1>
              <p><strong>Contact Information</strong></p>
              <div className="contact-info">
                {/*<p className="contact-item">
                  <a href="tel:+14176231550" className="clearfix">
                    <span className="icon icon-phone"/>
                    <span className="text">417-623-1550 ext 203</span>
                  </a>
                </p>*/}
                {/*<p className="contact-item">
                  <a href="mailto:support@vintagestock.com" className="clearfix">
                    <span className="icon icon-phone"/>
                    <span className="text">Online Orders - <a href="mailto:support@vintagestock.com">support@vintagestock.com</a></span>
                  </a>
                </p>*/}
                <p className="contact-item">
                  <a href="mailto:orders@vintagestock.com" className="clearfix">
                    <span className="icon icon-mail"/>
                    <span className="text">orders@vintagestock.com</span>
                  </a>
                </p>
              </div>
              <hr/>
              <h1>Contact form</h1>
              {
                success ? (
                    <span>
                      <p>Thanks for contacting us, your message has been sent.</p>
                      <p>We will get in touch with you as soon as possible.</p>
                    </span>
                ) : (
                    <ContactReduxFormContainer
                        onSubmit={this.handleFormSubmit}
                        isLoading={loading}
                        errors={errors}/>
                )
              }
            </div>
            </div>
          <SEO url='contact' />
        </section>
    )
  }
}

export default Contact
