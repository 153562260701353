import _ from "lodash";
import queryString from "query-string";

export default class QueryParams {
  /**
   * Class constructor
   * @param queryParams {String | Object}
   */
  constructor(queryParams) {
    if (typeof queryParams === "string")
      this.queryParams = queryString.parse(queryParams);
    else
      this.queryParams = queryParams;
  }

  /**
   * Update query params based on the given object
   * @param newParams {Object}
   * @returns {{}|{}}
   */
  update(newParams) {
    let updatedQueryParams = Object.assign(this.queryParams, newParams);
    this.queryParams = _.pickBy(updatedQueryParams);  // remove empty
    return this.queryParams;
  }

  // /**
  //  * Resets all pagination query params
  //  * @returns {{}}
  //  */
  // resetPagination(){
  //   return this.update({
  //     [QUERY_PARAM_BEFORE_CURSOR]: null,
  //     [QUERY_PARAM_LAST]: null,
  //     [QUERY_PARAM_AFTER_CURSOR]: null,
  //   })
  // }

  /**
   * Finds and retrieves a query param or undefined if not found
   * @param param {String}
   * @returns {String | Object | undefined}
   */
  find(param) {
    return this.queryParams[param];
  }

  /**
   * Retrieves the query params as object
   * @returns {Object}
   */
  get asObj() {
    return this.queryParams;
  }

  /**
   * Retrieves the query params as string to be used in a url
   * @returns {string}
   */
  get asString() {
    return queryString.stringify(this.queryParams);
  }

  /**
   * Retrieves the query params as a search string to be used when performing a history.push to update query params
   * @returns {string}
   */
  get asSearchString() {
    return `?${this.asString}`
  }
}