import React from 'react';
import PropTypes from 'prop-types';
import slugify from "utils/snippets/slugify";
import "./styles/CreditCardInfo.scss";

const CreditCardInfo = ({creditCard}) => (
    <div className="credit-card-info">
      <p>
        {
          creditCard ? (
              <span>
                <span className={`icon ${slugify(creditCard.credit_card_type)}`}/>
                <span className="full-name">
                  {creditCard.full_name}
                </span>
                <span className="number">
                  {creditCard.credit_card_type} ending in {creditCard.safe_num.replace(/\*/g, '')}
                </span>
              </span>
          ) : (
              <span className="icon paypal"/>
          )
        }
      </p>
    </div>
);

CreditCardInfo.propTypes = {
  creditCard: PropTypes.shape({
    credit_card_type: PropTypes.string.isRequired,
    safe_num: PropTypes.string.isRequired
  })
};

export default CreditCardInfo;
