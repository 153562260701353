import { connect } from 'react-redux';
import MainMenu from "components/MainMenu/index";
import {setMainMenuIsOpen, setResetQuerySearch} from "components/MainMenu/actions/index";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    mainMenuReducer: state.mainMenuReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainMenuIsOpen: mainMenuIsOpen => dispatch(setMainMenuIsOpen(mainMenuIsOpen)),
    setResetQuerySearch: resetQuerySearch => dispatch(setResetQuerySearch(resetQuerySearch))
  };
};

const MainMenuContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainMenu);

export default MainMenuContainer;