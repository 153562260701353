import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames/bind";
import { Field, reduxForm } from 'redux-form';
import {renderInput} from "utils/forms/renderers";
import {email, onlyText, required} from "utils/forms/validators";

export const MyProfileForm = ({errors, isLoading, success, handleSubmit}) => (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="first_name">First Name <span className="required">*</span></label>
        <Field id="first_name"
               name="first_name"
               component={renderInput}
               type="text"
               className="form-control"
               validate={[required, onlyText]}
               placeholder="Type your first name"/>
      </div>
      <div className="form-group">
        <label htmlFor="last_name">Last Name <span className="required">*</span></label>
        <Field id="last_name"
               name="last_name"
               component={renderInput}
               type="text"
               className="form-control"
               validate={[required, onlyText]}
               placeholder="Type your last name"/>
      </div>
      <div className={classNames({
        "form-group": true,
        "has-error": errors.email || errors.non_field_errors
      })}>
        <label htmlFor="email">Email <span className="required">*</span></label>
        <Field id="email"
               name="email"
               component={renderInput}
               type="email"
               className="form-control"
               validate={[required, email]}
               placeholder="Type your email address"/>
        {errors.email && errors.email.map((msj, index) => (
            <span className="help-block" key={`myProfileFormEmailError${index}`}>{msj}</span>
        ))}
        {errors.non_field_errors && errors.non_field_errors.map((msj, index) => (
            <span className="help-block" key={`myProfileFormNonFieldError${index}`}>{msj}</span>
        ))}
      </div>
      <div className="bottom">
        <div className="form-group">
          {
            isLoading ? (
                <input type="submit" value="SAVING..." disabled={true}/>
            ) : (
                success ? (
                    <button type="submit" disabled={true}>
                      <i className="fa fa-check"/> CHANGES WERE SAVED!
                    </button>
                ) : (
                    <input type="submit" value="SAVE CHANGES"/>
                )
            )
          }
        </div>
      </div>
    </form>
);

MyProfileForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const MyProfileReduxForm = reduxForm({
  form: 'authMyProfileForm'
})(MyProfileForm);

export default MyProfileReduxForm