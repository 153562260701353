import { connect } from 'react-redux';
import OrderHistory from "../index";
import {clearOrders, receiveOrders} from "scenes/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveOrders: orders => dispatch(receiveOrders(orders)),
    clearOrders: () => dispatch(clearOrders())
  };
};

const OrderHistoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderHistory);

export default OrderHistoryContainer;