import React from 'react';
import {Link} from "react-router-dom";
import Breadcrumb from "utils/components/Breadcrumb";
import SEO from "components/SEO/index";

const AccountMenu = ({match}) => (
    <section className="menu-list icon">
      <Breadcrumb
          crumbs={[
            {title: "My Account", to: "/my-account"},
          ]}/>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li className="icon-user">
                <Link to={`${match.url}/profile`}>My Profile</Link>
              </li>
              <li className="icon-padlock">
                <Link to={`${match.url}/password`}>Password</Link>
              </li>
              <li className="icon-shopping-bag">
                <Link to={`${match.url}/order-history`}>Order History</Link>
              </li>
              <li className="icon-address-book">
                <Link to={`${match.url}/address-book`}>Address Book</Link>
              </li>
              <li className="icon-card">
                <Link to={`${match.url}/credit-cards`}>Credit Card Info</Link>
              </li>
              <li className="icon-wishlist-book">
                <Link to={`${match.url}/wishlist`}>Wishlist</Link>
              </li>
              <li className="icon-logout">
                <Link to={'/auth/logout'}>Sign Out</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <SEO url="my-account" />
    </section>
);

export default AccountMenu;