import React, {useState} from "react"
import classNames from "classnames/bind";
import PropTypes from "prop-types"
import mapMarker from "./img/map-pin.svg"
import "./styles/StoreAccordion.scss"

export default function StoreAccordion({store}) {
  const [isOpen, setIsOpen] = useState(true)

  if (!store) return null;

  const toggle = event => setIsOpen(!isOpen)

  return (
      <div
          className={classNames({
            "store-accordion": true,
            "store-accordion--is-open": isOpen
          })}
          onClick={toggle}>
        <div className="store-accordion__header">
          <div>
            <img src={mapMarker} alt=""/>
            <div>
              <p>
                <strong>{store.name}</strong>
              </p>
              {
                isOpen &&
                <div className="store-accordion-more-info">
                  <p>{store.address_1}{store.address_2 && `, ${store.address_2}`} {store.city_name} {store.region_name} {store.zip_code}</p>
                  <p>Call us <strong>{store.phone_number_1}</strong>, to pick up or ship from this
                    store. <span className="ship-limit-message">Ship from store maximum order is $100.</span>
                  </p>
                </div>
              }
            </div>
          </div>
          {/*<div>
            <i className="fa fa-chevron-down"/>
          </div>*/}
        </div>
      </div>
  )
}

StoreAccordion.propTypes = {
  id: PropTypes.number.isRequired
}