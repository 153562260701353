import {connect} from 'react-redux';
import NotifyMeWhenAvailableForm from "utils/components/NotifyMeWhenAvailableButton/components/NotifyMeWhenAvailableForm/index";

const mapStateToProps = (state) => {
  return {
    initialValues: {
      email: state.authReducer.user ?  state.authReducer.user.email : null
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

const NotifyMeWhenAvailableFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotifyMeWhenAvailableForm);

export default NotifyMeWhenAvailableFormContainer;