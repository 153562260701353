import React from "react";
import {Panel, PanelGroup} from "react-bootstrap"
import {SORTING_PARAMS} from "scenes/Store/scenes/FiltersList/FilterListForm/constants"
import {useHistory, useLocation} from "react-router"
import QueryParams from "utils/QueryParams"
import {QUERY_PARAM_ORDERING} from "scenes/Store/scenes/ProductsList/constants/query-params"
import FiltersBox from "scenes/Store/scenes/FiltersListV2/components/FiltersBox"

export default function SortByFilter() {
  const location = useLocation()
  const history = useHistory()
  const queryParams = new QueryParams(location.search)

  /**
   * Handles change on the radio inputs
   * @param event {Object}
   */
  function handleChange(event) {
    queryParams.update({
      [QUERY_PARAM_ORDERING]: event.target.value
    })
    history.push({
      pathname: location.pathname,
      search: queryParams.asSearchString
    })
  }

  return (
      <FiltersBox
          title="Sort by">
        <ul>
          {
            SORTING_PARAMS.map((param, index) => (
                <li
                    key={index}
                    className="filter item panel-group__item">
                  <div className="radio">
                    <label htmlFor={`ordering${index}`}>
                      <input
                          type="radio"
                          name="ordering"
                          id={`ordering${index}`}
                          value={param.value}
                          checked={queryParams.find(QUERY_PARAM_ORDERING) === param.value}
                          onChange={handleChange}/>
                      {param.label}
                    </label>
                  </div>
                </li>
            ))
          }
        </ul>
      </FiltersBox>
  )
}