import { connect } from 'react-redux';
import Logout from "../index";
import {logout} from "scenes/Auth/actions/index";
import {emptyCart} from "scenes/Store/actions/index";
import {emptyAccountInformation} from "scenes/MyAccount/actions/index";
import {emptyCheckoutInformation} from "scenes/Store/scenes/Checkout/actions";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    emptyCart: () => dispatch(emptyCart()),
    emptyAccountInformation: () => dispatch(emptyAccountInformation()),
    emptyCheckoutInformation: () => dispatch(emptyCheckoutInformation()),
  };
};

const LogoutContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout);

export default LogoutContainer;