import React, {Component} from 'react';
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import {Link} from "react-router-dom";
import "./styles/Breadcrumb.scss";

class Breadcrumb extends Component {
  render(){
    const {crumbs} = this.props;

    return(
        <div className="breadcrumb">
          <p>
            {
              crumbs.map((crumb, index) =>
                  <span
                      className={classNames({
                        "breadcrumb__crumb": true,
                        "breadcrumb__crumb--active": (index + 1) === crumbs.length,
                      })}
                      key={`crumb${index}`}>
                    {
                      index > 0 &&
                      <span className="breadcrumb__separator">
                        &nbsp;>&nbsp;
                      </span>
                    }
                    <Link to={crumb.to}>
                      {crumb.title}
                    </Link>
                  </span>
              )
            }
          </p>
        </div>
    )
  }
}

Breadcrumb.propTypes = {
  crumbs: PropTypes.arrayOf(function(propValue, key, componentName, location, propFullName) {
    // crumbs prop needs to be passed as an array of objects with title and to props, like this
    // crumbs = [
    //    {title: "My Account", to: "/my-account"},
    //    {title: "My Profile", to: "/my-account/profile"},
    // ];
    let hasError = false;
    propValue.forEach(crumb => {
      if( hasError === false )
        hasError = crumb['title'] === undefined || crumb['to'] === undefined
    });
    if( hasError ){
      return new Error(
          'Invalid prop `' + propFullName + '` supplied to' +
          ' `' + componentName + '`. Validation failed.'
      );
    }
  }).isRequired
};

export default Breadcrumb
