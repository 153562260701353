import { connect } from 'react-redux';
import Order from "../index";
import {selectOrder} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrder: order => dispatch(selectOrder(order))
  };
};

const OrderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Order);

export default OrderContainer;