import { connect } from 'react-redux';
import MyProfile from "../index";
import {receiveUser} from "scenes/Auth/actions";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveUser: user => dispatch(receiveUser(user))
  };
};

const MyProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProfile);

export default MyProfileContainer;