import React, {Component} from 'react';
import PropTypes from "prop-types";
import QuantityInput from "utils/components/QuantityInput/index";
import ProductCondition from "utils/components/ProductCondition/index";
import debounce from 'lodash/debounce';
import "./styles/CartItem.scss";
import ProductPrice from "utils/components/ProductPrice/index";
import {removeFromCart, updateCartItemQuantity} from "services/store/index";
import {NEW} from "utils/constants/product-conditions";
import {CART_ITEM} from "scenes/Store/scenes/ProductDetails/components/AddToWishListButton/constants/index";
import AddToWishListButton from "scenes/Store/scenes/ProductDetails/components/AddToWishListButton/index";
import {PROMOTION_DISCOUNT, PROMOTION_FREE_PRODUCT} from "scenes/Store/scenes/Cart/constants/promotions";
import moment from "moment";
import Moment from "react-moment";

class CartItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      limitExceeded: false,
      quantity: this.props.item.quantity
    };

    this.handleQuantityInputChange = this.handleQuantityInputChange.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.showLimitExceeded = this.showLimitExceeded.bind(this);
  }

  /**
   * Deletes the item from the Cart through the api.
   * @returns {AxiosPromise}
   */
  deleteItem() {
    const promise = removeFromCart(this.props.item);
    promise.then(response => {
      this.setState({deleting: false});
      this.props.removeCartItem(this.props.item);
    });
    promise.catch(error => {
      this.setState({deleting: false});
    });
    return promise;
  }

  /**
   * Show message of limit exceeded
   */
  _changeLimitExceeded() {
    this.setState({
      limitExceeded: !this.state.limitExceeded
    });
  }

  /**
   * Show message of limit exceeded
   */
  showLimitExceeded() {
    this._changeLimitExceeded();
    setTimeout(() => this._changeLimitExceeded(), 3000);
  }

  /**
   * Handles clicks on the delete button
   */
  handleDeleteButtonClick() {
    this.setState({
      deleting: true
    }, () => {
      this.deleteItem();
    });
  }

  /**
   * Updates the quantity of the item through the API and reflects that change
   * in the Redux store.
   * Please note: Updating an item on the cart (in Redux store) will cause
   * CartTotals component to fetch totals again.
   */
  updateItemQuantity() {
    const {item} = this.props;
    const {quantity} = this.state;
    if (quantity) {
      updateCartItemQuantity(item, quantity);
      this.props.updateCartItemQuantity(item, quantity);
    }
  }

  /**
   * Debounces the update quantity function to avoid too many requests to the server.
   */
  debounceUpdateItemQuantity = debounce(this.updateItemQuantity, 500);

  /**
   * Handles changes on the <QuantityInput/> component.
   * @param {Number} quantity
   */
  handleQuantityInputChange(quantity) {
    this.setState({
      quantity: quantity
    }, () => {
      this.debounceUpdateItemQuantity();
    });
  }

  /**
   * Returns the promotion for this cart item or undefined if nothing found
   * @returns {*}
   */
  get promotion() {
    const {object_id} = this.props.item;
    const {cartTotals} = this.props.storeReducer;

    if (cartTotals.products_with_discount === undefined)
      return undefined;

    if (cartTotals.products_with_discount.length === 0)
      return undefined;

    return cartTotals.products_with_discount.find(discount => discount.product.id === object_id);
  }

  render() {
    const {item, showActions} = this.props;
    const {deleting, limitExceeded} = this.state;

    return (
        <div className="cart-item">
          <div className="cart-item__product clearfix">
            <div className="cart-item__product__image">
              <img
                  src={item.cart_image_thumbnail}
                  alt={"Product photo: " + item.product_name}/>
            </div>
            <div className="cart-item__product__details">
              <p>{item.product_name}</p>
              {
                moment(item.release_date) > moment(new Date()) &&
                <p className="product-card-info__release-date">
                  Release date:&nbsp;
                  <Moment format="MMM D, YYYY">
                    {item.release_date}
                  </Moment>
                </p>
              }
              <p>
                <strong>
                  <ProductCondition
                      condition={item.condition}/>
                </strong>
                &nbsp;
                <ProductPrice
                    condition={item.condition}
                    priceNew={item.product_price_new}
                    priceUsed={item.product_price_used}/>
                {
                  !showActions &&
                  <span className="item-quantity">x{item.quantity}</span>
                }
                {
                  item.is_pre_order &&
                  <span className="pre-order-label">PRE-ORDER</span>
                }
                {
                  this.promotion &&
                      <div>
                        <span className="item-promo text-uppercase">
                          <span className="item-promo__label label label-danger" style={{"margin": 0}}>
                            {
                              this.promotion.quantity !== this.state.quantity &&
                              <small>{this.promotion.quantity}&nbsp;</small>
                            }
                            {
                              this.promotion.promotion_type === PROMOTION_DISCOUNT ?
                                  this.promotion.promotion
                                  :
                                  "FREE"
                            }
                          </span>
                          <span className="item-promo__description">
                            {
                              this.promotion.promotion_type === PROMOTION_FREE_PRODUCT &&
                              this.promotion.promotion
                            }
                          </span>
                        </span>
                      </div>
                }
              </p>
            </div>
          </div>
          {
            showActions &&
            <div className="cart-item__actions">
              <div className="cart-item__actions__input">
                Qty:&nbsp;&nbsp;
                <QuantityInput
                    quantity={item.quantity}
                    condition={item.condition}
                    stockNew={item.product_total_stock_new}
                    stockUsed={item.product_total_stock_used}
                    isPreOrder={item.is_pre_order}
                    onQuantityChanges={this.handleQuantityInputChange}
                    showLimitExceeded={this.showLimitExceeded}/>
                {
                  limitExceeded && (
                      <div>
                        Only {item.condition === NEW ? item.product_total_stock_new : item.product_total_stock_used} available
                        in stock
                      </div>
                  )
                }
              </div>
              <div className="cart-item__actions__wrapper">
                <AddToWishListButton
                    contentType={item.content_type}
                    productId={item.object_id}
                    selectedCondition={item.condition}
                    context={CART_ITEM}
                    onAfterAddToWishList={this.handleDeleteButtonClick}/>
                <button
                    type="button"
                    className="delete-button"
                    disabled={deleting}
                    aria-label={`remove ${item.name}`}
                    onClick={this.handleDeleteButtonClick}>
                  <span className="icon icon-rubbish"/>
                </button>
              </div>
            </div>
          }
        </div>
    )
  }
}

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  showActions: PropTypes.bool.isRequired
};

CartItem.defaultProps = {
  showActions: true
};

export default CartItem
