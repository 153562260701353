import {
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_DESCRIPTION,
  QUERY_PARAM_NAME,
  QUERY_PARAM_MEDIA_TYPE,
  QUERY_PARAM_FORMAT,
  QUERY_PARAM_GENRES,
  QUERY_PARAM_GAMING_PLATFORM,
  QUERY_PARAM_ESRB_RATING,
  QUERY_PARAM_MPAA_RATING,
  QUERY_PARAM_PLATFORM,
  VIEW_TYPE,
  QUERY_PARAM_VIEW_TYPE,
  QUERY_PARAM_SKU,
  QUERY_PARAM_CAST,
  QUERY_PARAM_NEW_THIS_WEEK,
  QUERY_PARAM_MOST_VIEWED,
  QUERY_PARAM_COMING_SOON,
  QUERY_PARAM_SEARCH,
  QUERY_PARAM_ORDERING,
  QUERY_PARAM_YEAR
} from "scenes/Store/scenes/ProductsList/constants/query-params";

export const MOVIES = 'Movies';
export const GAMES = 'Games';
export const POSTERS = 'Posters';
export const TOYS = 'Toys';
export const MUSIC = 'Music';
export const ALL = 'All';
export const RECENTLY_VIEWED = 'Recently Viewed'
export const NEW_THIS_WEEK = 'New Releases';
export const NEW_THIS_WEEK_SLUG = 'new-this-week';
export const COMING_SOON = 'Coming Soon';
export const COMING_SOON_SLUG = 'coming-soon';
export const  COMMING_SOON = 'Coming soon';
export const  GIFT_CARDS = 'Gift Cards';

export const CATEGORY_PARENT_ID = 25;

export const CATEGORIES_ID_SHOW = [23, 32, 33, 34, 49]

export const QUERY_SLUG_CATEGORIES = [
  VIEW_TYPE, QUERY_PARAM_CATEGORIES
];

export const DEFAULT_QUERY_PARAMS = {
  [QUERY_PARAM_CATEGORIES]: null,
  [QUERY_PARAM_SEARCH]: null,
  [QUERY_PARAM_GENRES]: null,
  [QUERY_PARAM_DESCRIPTION]: null,
  [QUERY_PARAM_NAME]: null,
  [QUERY_PARAM_MEDIA_TYPE]: null,
  [QUERY_PARAM_FORMAT]: null,
  [QUERY_PARAM_GAMING_PLATFORM]: null,
  [QUERY_PARAM_ESRB_RATING]: null,
  [QUERY_PARAM_MPAA_RATING]: null,
  [QUERY_PARAM_PLATFORM]: null,
  [VIEW_TYPE]: null,
  [QUERY_PARAM_VIEW_TYPE]: null,
  [QUERY_PARAM_SKU]: null,
  [QUERY_PARAM_CAST]: null,
  [QUERY_PARAM_NEW_THIS_WEEK]: null,
  [QUERY_PARAM_MOST_VIEWED]: null,
  [QUERY_PARAM_COMING_SOON]: null,
  [QUERY_PARAM_YEAR]: null,
  [QUERY_PARAM_ORDERING]: null
};

export const PRODUCT_CATEGORIES_GROUPS = [
  {
    title: "Shop By",
    categories: [
      {
        name: MOVIES,
        slug: 'movies',
        reactQueryParams: {
          ...DEFAULT_QUERY_PARAMS,
          [QUERY_PARAM_CATEGORIES]: 23,
        },
        customHeaderHtml: null
      },
      {
        name: GAMES,
        slug: 'games',
        reactQueryParams: {
          ...DEFAULT_QUERY_PARAMS,
          [QUERY_PARAM_CATEGORIES]: 32,
        },
        customHeaderHtml: null
      },
      {
        name: POSTERS,
        slug: 'posters',
        reactQueryParams: {
          ...DEFAULT_QUERY_PARAMS,
          [QUERY_PARAM_CATEGORIES]: 34,
        },
        customHeaderHtml: null
      },
      {
        name: TOYS,
        slug: 'toys',
        reactQueryParams: {
          ...DEFAULT_QUERY_PARAMS,
          [QUERY_PARAM_CATEGORIES]: 33,
        },
        customHeaderHtml: null
      },
      {
        name: MUSIC,
        slug: 'music',
        reactQueryParams: {
          ...DEFAULT_QUERY_PARAMS,
          [QUERY_PARAM_CATEGORIES]: 49,
        },
        customHeaderHtml: null
      },
      {
        name: ALL,
        slug: 'all',
        to: '/store/products',
        customHeaderHtml: null
      },
    ]
  },
  {
    title: "Shop by Interest",
    categories: [
      {
        name: RECENTLY_VIEWED,
        slug: 'recently-viewed',
        to: "/store/recently-viewed",
        customHeaderHtml: null
      },
      {
        name: NEW_THIS_WEEK,
        slug: 'new-this-week',
        reactQueryParams: {
          ...DEFAULT_QUERY_PARAMS,
          [VIEW_TYPE]: QUERY_PARAM_NEW_THIS_WEEK,
        },
        customHeaderHtml: null
      },
      // {
      //   name: MOST_VIEWED,
      //   slug: 'most-viewed',
      //   reactQueryParams: {
      //     [VIEW_TYPE]: QUERY_PARAM_MOST_VIEWED
      //   },
      //   customHeaderHtml: null
      // },
      // {
      //   name: COMMING_SOON,
      //   slug: 'coming-soon',
      //   reactQueryParams: {
      //     [VIEW_TYPE]: QUERY_PARAM_COMING_SOON,
      //     [QUERY_PARAM_CATEGORIES]: null,
      //     [QUERY_PARAM_SEARCH]: null
      //   },
      //   customHeaderHtml: null
      // },
      {
        name: GIFT_CARDS,
        slug: 'gift-cards',
        to: "/gift-cards",
        customHeaderHtml: null
      }
    ]
  }
];