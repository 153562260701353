import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import "./styles/AddressForm.scss";
import AddressFieldsContainer
  from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressFields/container/index";
import {ADDRESS_FORM_NAME} from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressForm/constants";

export class AddressForm extends Component {
  constructor(props) {
    super(props);

    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  /**
   * Closes the modal on cancel button click.
   */
  handleCancelClick() {
    this.props.deselectAddress();
  }

  render() {
    const {
      isLoading, handleSubmit, success, errors,
      initialValues, changeFieldValue,
      removeOptions
    } = this.props;

    return (
        <form
            className="form address-form"
            onSubmit={handleSubmit}>
          <AddressFieldsContainer
              errors={{}}
              initialValues={initialValues}
              removeOptions={removeOptions}
              changeFieldValue={changeFieldValue}/>
          {
            (errors && errors.non_field_errors) &&
            <div style={{
              marginBottom: "15px"
            }}>
              {
                errors.non_field_errors.map((error, index) =>
                    <p
                        key={index}
                        style={{
                          color: "red",
                          fontWeight: "bold"
                        }}>
                      {error}
                    </p>
                )
              }
            </div>
          }
          {
            !removeOptions &&
            <div className="align-right">
              <div className="form-group">
                {
                  isLoading ? (
                      <input
                          type="submit"
                          value="SAVING..."
                          disabled={true}/>
                  ) : (
                      success ? (
                          <button
                              type="submit"
                              disabled={true}>
                            <i className="fa fa-check"/> ADDRESS WAS SAVED!
                          </button>
                      ) : (
                          <input
                              type="submit"
                              value="SAVE CHANGES"/>
                      )
                  )
                }
                <br/><br/>
                <a
                    onClick={this.handleCancelClick}>
                  <span className="btn btn-gray btn-block">CANCEL</span>
                </a>
              </div>
            </div>
          }
        </form>
    )
  }
}

AddressForm.defaultProps = {
  removeOptions: false
};

AddressForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired
};

const AddressReduxForm = reduxForm({
  form: ADDRESS_FORM_NAME,
  enabledReinitialize: true
})(AddressForm);

export default AddressReduxForm
