import React, {Component} from 'react';
import HomeSlider from "scenes/Home/components/HomeSlider/index";
import "./styles/Home.scss";
import {Link} from "react-router-dom";
import CategorySlider from "scenes/Home/components/CategorySlider/index";
import FeaturedProducts from "scenes/Home/components/FeaturedProducts/index";
import HomeEnjoyAd from "scenes/Home/components/HomeEnjoyAd";
import NewsletterSubscribe from "components/NewsletterSubscribe/index";
import SEO from "components/SEO/index";
import {CATEGORIES_SHOW} from "scenes/Store/scenes/FiltersList/constants/constans";
import vintageAxios from "services/api";
import {QUERY_PARAM_CATEGORIES} from "scenes/Store/scenes/ProductsList/constants/query-params";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: null
    }
  }

  componentDidMount() {
    this.fetchCategories();
  }

  /**
   * Fetch categories from the API.
   * @returns {AxiosPromise}
   */
  fetchCategories() {
    const promise = vintageAxios.get('/products/categories/');
    promise.then(response => {
      /*Categories list to show*/
      const categories = response.data.filter(item => {
        return [...CATEGORIES_SHOW, "Consoles & Hardware"].find(
            category => category === item.name
        ) ? item : undefined;
      });
      this.setState({
        categories
      });
    });
    return promise;
  }

  render() {
    const {categories} = this.state;

    return (
        <React.Fragment>
          <HomeSlider/>
          <section id="content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>
                    Join our team. <Link to="about/now-hiring" className="home-invite-link">Apply here</Link>
                  </h2>
                </div>
                <div className="clearfix"/>
                <div className="col-md-12">
                  <div id="category-slider" className="owl-carousel">
                    <CategorySlider/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FeaturedProducts/>
          <HomeEnjoyAd/>
          {
            !categories
                ?
                <LoadingCassette/>
                :
                <section id="categories">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="centered">SEARCH FOR YOUR FAVORITE PRODUCTS NOW!</h3>
                      </div>
                      <div className="clearfix"/>
                      <div className="col-md-12">
                        <div className="grid">
                          <Link
                              to={`/store/products/?${QUERY_PARAM_CATEGORIES}=${categories.find(e => e.name === 'Movies').id}`}>
                            <div className="item red">
                              <div className="icon">
                                <span className="movies"/>
                              </div>
                              <h2>MOVIES</h2>
                            </div>
                          </Link>
                          <Link
                              to={`/store/products/?${QUERY_PARAM_CATEGORIES}=${categories.find(e => e.name === 'Games').id}`}>
                            <div className="item green">
                              <div className="icon">
                                <span className="games"/>
                              </div>
                              <h2>GAMES</h2>
                            </div>
                          </Link>
                          <Link
                              to={`/store/products/?${QUERY_PARAM_CATEGORIES}=${categories.find(e => e.name === 'Toys').id}`}>
                            <div className="item pink">
                              <div className="icon">
                                <span className="toys"/>
                              </div>
                              <h2>TOYS</h2>
                            </div>
                          </Link>
                          <Link
                              to={`/store/products/?${QUERY_PARAM_CATEGORIES}=${categories.find(e => e.name === 'Posters').id}`}>
                            <div className="item navy">
                              <div className="icon">
                                <span className="posters"/>
                              </div>
                              <h2>POSTERS</h2>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
          }
          <NewsletterSubscribe/>
          <SEO url=''/>
        </React.Fragment>
    )
  }
}

export default Home;
