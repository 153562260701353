export const ENTERTAINMENTMART = 0;
export const MOVIE_TRADING_CO = 1;
export const VINTAGE_STOCK = 2;
export const V_STOCK = 3;

export const STORE_TYPES = [
  [ENTERTAINMENTMART, 'EntertainMart'],
  [MOVIE_TRADING_CO, 'Movie Trading Co.'],
  [VINTAGE_STOCK, 'Vintage Stock'],
  [V_STOCK, 'V&bull;Stock']
];

export const LOCATIONS_MILE = [
  1,
  5,
  10,
  25,
  50,
  100,
  250,
  500,
  1000,
  1500
];


// Search query applied by default
export const SEARCH_QUERY_BY_DEFAULT = '80734';
export const LOCATION_MILE_BY_DEFAULT = LOCATIONS_MILE[LOCATIONS_MILE.length - 1];