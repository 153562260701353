import React from 'react';
import "./styles/WishlistShareButton.scss";
import {Link} from "react-router-dom";

const WishListShareButton = () => {
  return (
      <div className="wishlist-share-button">
        <Link to="/share/wishlist"
              className="btn-custom wishlist-share-button__button-share">
          <span className="fa fa-share"/><span>SHARE</span>
        </Link>
      </div>
  )
};

export default WishListShareButton
