import React from 'react';
import "./styles/HomeEnjoyAd.scss";
import {Link} from "react-router-dom";
import products from "./img/Gift-card-banner@2x.png";

const HomeEnjoyAd = (props) => (
    <section id="ad">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="box">
              <span className="bg"/>
              <div className="row">
                <div className="col-md-6 col-md-offset-6">
                  <div className="text-center">
                    <img
                        src={products}
                        className="img-responsive"
                        alt="VintageStock"/>
                  </div>
                  <h3>Gift Cards Available</h3>
                  <p>Give the perfect gift of choice. Gift cards are redeemable in store only.</p>
                  <span className="line"/>
                  <p>
                    <Link to="/gift-cards">
                      <span className="btn-custom">BUY NOW!</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
);

export default HomeEnjoyAd;
