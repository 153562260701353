import React, {Component} from 'react';
import Breadcrumb from "utils/components/Breadcrumb/index";
import SEO from "components/SEO/index";
import "./styles/CareerOpportunities.scss";

class CareerOpportunities extends Component {
  render() {
    return (
        <div>
          <Breadcrumb
              crumbs={[
                {title: "About", to: "/about"},
                {title: "Career Opportunities", to: "/about/now-hiring"},
              ]}/>
          <div className="container career-opportunities">
            <div className="wrapper">
              <h1>Career Opportunities</h1>
              <p>
                Want a great job full of fun, tons of opportunities and product discounts?
                Find it here! We're frequently looking for motivated, dynamic employees in all
                areas of our company
              </p>
              <strong>3 easy ways to apply:</strong><br/>
              <ul className="career-opportunities__ways-apply">
                <li>-Download, complete and email your application to <a href="mailto:employment@vintagestock.com"><span
                    className="career-opportunities__ways-apply__email">employment@vintagestock.com.</span></a></li>
                <li>-Print, complete and deliver your application directly to the store.</li>
                <li>-Print and fax your application to our corporate office@417-782-0024.</li>
              </ul>
              <p>
                <a
                    className="btn btn-block"
                    href="https://media.vintagestock.com/media/employment-application.pdf"
                    rel="noopener noreferrer"
                    target="_blank">
                  Download Form
                </a>
              </p>
            </div>
          </div>
          <SEO url='about/now-hiring'/>
        </div>
    )
  }
}

export default CareerOpportunities
