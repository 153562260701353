import { connect } from 'react-redux';
import Checkout from "../index";

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const CheckoutContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Checkout);

export default CheckoutContainer;