import { connect } from 'react-redux';
import CartItem from "../index";
import {removeCartItem, updateCartItemQuantity} from 'scenes/Store/actions/index';

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartItemQuantity: (cartItem, quantity) => dispatch(updateCartItemQuantity(cartItem, quantity)),
    removeCartItem: cartItem => dispatch(removeCartItem(cartItem)),
  };
};

const CartItemContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartItem);

export default CartItemContainer;