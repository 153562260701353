import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import ProductContainer from "scenes/Store/scenes/ProductsList/components/Product/container";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import {
  QUERY_PARAM_RECENTLY_VIEWED, VIEW_TYPE
} from "scenes/Store/scenes/ProductsList/constants/query-params";
import SEO from "components/SEO/index";
import {PRODUCTS_LIST_AS_LIST} from "scenes/Store/scenes/ProductsList/constants/constants";
import "./styles/RecentlyViewed.scss";
import NewsletterSubscribe from "components/NewsletterSubscribe/index";

class RecentlyViewed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: null,
    };
  }

  componentDidMount() {
    this.fetchProducts();
  }

  /**
   * Fetch products from the API.
   * @returns {AxiosPromise}
   */
  fetchProducts() {
    const promise = vintageAxios.get(`/products/all/`, {
      params: {
        [VIEW_TYPE]: QUERY_PARAM_RECENTLY_VIEWED
      }
    });
    promise.then(response => {
      this.setState({
        products: response.data.results
      });
    });
    return promise;
  }

  render() {
    const {products} = this.state;

    return (
        <div>
          <section className="products">
            <div className="container">
              <h1 className="recently-viewed-title">Recently Viewed</h1>
              <div className="row">
                <div className="col-md-12">
                  {
                    products ? (
                        <div>
                          {
                            products.length === 0 ? (
                                <div className="empty-message search">
                                  <h1>No Search Results.</h1>
                                  <p>Sorry, we couldn’t find what you were looking for.</p>
                                </div>
                            ) : (
                                <div>
                                  <div className="row grid">
                                    {
                                      products.map(product =>
                                          <ProductContainer
                                              key={`product${product.id}`}
                                              viewAs={PRODUCTS_LIST_AS_LIST}
                                              product={product}/>
                                      )
                                    }
                                  </div>
                                </div>
                            )
                          }
                        </div>
                    ) : (
                        <LoadingCassette/>
                    )
                  }
                </div>
              </div>
            </div>
          </section>
          <NewsletterSubscribe/>
          <SEO url='store/recently-viewed'/>
        </div>
    )
  }
}

export default RecentlyViewed
