import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import ChangePasswordReduxForm from "scenes/MyAccount/scenes/ChangePassword/components/ChangePasswordForm/index";
import Breadcrumb from "utils/components/Breadcrumb/index";
import SEO from "components/SEO/index";

class ChangePassword extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false,
      success: false,
      errors: {}
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Submit the form and save user information.
   * @param {Object} data
   * @returns {AxiosPromise<any>}
   */
  submit(data){
    const promise = vintageAxios.post('/auth/update-password/', data);
    promise.then(response => {
      this.setState({
        loading: false,
        success: true
      });
      setTimeout(() => {
        this.setState({success: false});
      }, 1000);
    });
    promise.catch(error => {
      this.setState({
        loading: false,
        errors: error.response.data
      });
    });
    return promise;
  }

  /**
   * Handles form submit.
   * @param {Object} data
   */
  handleFormSubmit(data){
    return this.setState({
      loading: true
    }, () => {
      this.submit(data);
    });
  }

  render(){
    const {loading, errors, success} = this.state;

    return(
        <div>
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "My Password", to: "/my-account/password"},
              ]}/>
          <div className="container">
            <div className="wrapper">
              <h2>
                <span className="icon icon-padlock"/>
                <span className="text">My Password</span>
              </h2>
              <ChangePasswordReduxForm
                  isLoading={loading}
                  errors={errors}
                  success={success}
                  onSubmit={this.handleFormSubmit}/>
            </div>
          </div>
          <SEO url="my-account/password" />
        </div>
    )
  }
}

export default ChangePassword