import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import Order from "scenes/MyAccount/scenes/OrderHistory/components/Order/index";
import OrderTotals from "scenes/MyAccount/scenes/OrderDetails/components/OrderTotals/index";
import CreditCardInfo from "utils/components/CreditCardInfo/index";
import AddressInfo from "utils/components/AddressInfo/index";
import {Link} from "react-router-dom";
import Breadcrumb from "utils/components/Breadcrumb/index";
import SEO from "components/SEO/index";
import {ORDERS_DETAILS_CONTEXT} from "components/SEO/constants/contexts";
import {
  ORDER_CANCELLED,
  ORDER_COMPLETED,
  ORDER_RETURNED,
  ORDER_PARTIALLY_REFUNDED,
  ORDER_AWAITING_PAYMENT,
  ORDER_CONFIRMED,
  ORDER_PROCESSING,
  ORDER_AWAITING_PAYMENT_EXECUTION
} from "scenes/MyAccount/scenes/OrderHistory/components/Order/constants/status";

class OrderDetails extends Component {
  componentDidMount() {
    this.fetchOrder();
  }

  /**
   * Fetch orders from the redux store or the API (fallback).
   * @returns {*}
   */
  fetchOrder() {
    const {orders, selectedOrder} = this.props.myAccountReducer;
    const {match} = this.props;
    let promise;
    if (selectedOrder)
      promise = Promise.resolve(selectedOrder);
    else {
      if (orders && orders.length > 0) {
        promise = Promise.resolve({
          data: orders.filter(order => order.uuid === match.params.orderUUID)[0]
        });
      } else {
        promise = vintageAxios.get(`/management/orders/${match.params.orderUUID}/`);
      }
      promise.then(response => {
        this.props.selectOrder(response.data);
      });
    }
    return promise;
  }

  /**
   * Returns an Object with permitted action of received order (return, cancel and tracking)
   * @param order
   * @return {{return: boolean, cancel: boolean, tracking: boolean}}
   */
  static permittedActions(order) {
    return {
      return: [ORDER_COMPLETED, ORDER_PARTIALLY_REFUNDED].indexOf(order.status) > -1,
      cancel: [ORDER_AWAITING_PAYMENT, ORDER_CONFIRMED, ORDER_PROCESSING, ORDER_AWAITING_PAYMENT_EXECUTION].indexOf(order.status) > -1,
      tracking: order.shipments.length > 0
    }
  }

  render() {
    const order = this.props.myAccountReducer.selectedOrder;

    if (order === null)
      return <LoadingCassette/>;

    const permittedActions = OrderDetails.permittedActions(order);

    return (
        <div>
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "Order History", to: "/my-account/order-history"},
                {title: "Order No. " + order.id, to: "/my-account/order-history/" + order.uuid},
              ]}/>
          <div className="container">
            <div className="wrapper orders">
              <div className="order-item">
                <div className="row">
                  <div className="col-md-12">
                    <Order
                        order={order}
                        showDetailsLink={false}/>
                  </div>
                  <div className="col-md-12">
                    <hr/>
                    <div className="box">
                      <div className="payment-info">
                        <h6>Payment Method:</h6>
                        <CreditCardInfo
                            creditCard={order.credit_card_data}/>
                        <h6>Shipping Method:</h6>
                        <p>Best Way ­Standard</p>
                      </div>
                    </div>
                    <div className="shipping-info">
                      <h6>Shipping to:</h6>
                      <AddressInfo
                          address={order.shipping_address_data}/>
                    </div>
                    <div className="shipping-info">
                      <h6>Billing to:</h6>
                      <AddressInfo
                          address={order.billing_address_data}/>
                    </div>
                    <hr/>
                  </div>
                  <div className="col-md-12">
                    <div className="summary">
                      <h6>Order Summary</h6>
                      <OrderTotals
                          order={order}/>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {
                      // If at least one button is displayed
                      (order.shipments.length > 0 || (order.status !== ORDER_RETURNED && order.status !== ORDER_CANCELLED)) &&
                      <hr/>
                    }
                    <div className="options">
                      {
                        permittedActions.tracking &&
                        <Link to={`/my-account/order-history/${order.uuid}/tracking`}>
                          <span className="btn-custom tracking">TRACKING</span>
                        </Link>
                      }
                      {
                        permittedActions.return &&
                        <Link to={`/my-account/order-history/${order.uuid}/return`}>
                          <span className="btn-custom tracking">RETURN</span>
                        </Link>
                      }
                      {
                        permittedActions.cancel &&
                        <Link to={`/my-account/order-history/${order.uuid}/cancel`}>
                          <span className="btn-custom tracking">CANCEL</span>
                        </Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SEO url='my-account/order-history/:orderUUID'
               context={ORDERS_DETAILS_CONTEXT}
               information={{title: `${order.id}`}}/>
        </div>
    )
  }
}

export default OrderDetails