import { connect } from 'react-redux';
import SelectPaymentMethod from "../index";
import {selectCreditCard} from "scenes/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    checkoutReducer: state.checkoutReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectCreditCard: creditCard => dispatch(selectCreditCard(creditCard))
  };
};

const SelectPaymentMethodContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectPaymentMethod);

export default SelectPaymentMethodContainer;