import { connect } from 'react-redux';
import {login} from "scenes/Auth/actions/index";
import {receiveCart} from "scenes/Store/actions/index";
import LoginIsolated from "scenes/Auth/scenes/Login/components/LoginIsolated/index";
import {emptyAccountInformation} from "scenes/MyAccount/actions";
import {emptyCheckoutInformation} from "scenes/Store/scenes/Checkout/actions";

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: user => dispatch(login(user)),
    receiveCart: user => dispatch(receiveCart(user)),
    emptyAccountInformation: () => dispatch(emptyAccountInformation()),
    emptyCheckoutInformation: () => dispatch(emptyCheckoutInformation()),
  };
};

const LoginIsolatedContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginIsolated);

export default LoginIsolatedContainer;