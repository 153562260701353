import React, {Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link, useHistory} from "react-router-dom";
import "./styles/Header.scss";
import {getCart, removeCartSession} from "services/store";

export default function Header({hideCart, storeReducer, setMainMenuIsOpen, receiveCart}) {
  const history = useHistory();
  const {cart} = storeReducer;

  useEffect(() => {
    if (!storeReducer.cart.session)
      fetchCart()
  }, []);

  /**
   * Fetches the cart from the API.
   * @returns {Promise}
   */
  function fetchCart() {
    const promise = getCart();
    promise.then(response => {
      receiveCart(response.data);
    });
    promise.catch(() => removeCartSession())
    return promise;
  }

  /**
   * Handle click menu button
   */
  function onClickMenuButton() {
    setMainMenuIsOpen(true);
  }

  return (
      <header>
        <div className="header">
          <a id="menu-link" onClick={onClickMenuButton}>
            <span className="icon-menu"/>
          </a>
          {
            // We are position this button about toggle button
            hideCart &&
            <div className="header__back-arrow">
              <a onClick={() => history.goBack()}>
                <span className="icon icon-arrow-left-stroke"/>
              </a>
            </div>
          }
          <Link to="/">
              <img alt={"Corporative logo"} width={200} src={"https://vintagestock-stage.sfo2.digitaloceanspaces.com/media/logos/logo-vs.png"}/>
          </Link>
          {
            !hideCart &&
            <Link className="header__cart" aria-label="icon cart" to="/store/cart">
              <span className="cart">
                {
                  cart && cart.cart_items.length > 0 && (
                      <span className="cart__counter">
                      {cart.cart_items.length}
                    </span>
                  )
                }
                <span className="cart__icon icon-Cart"/>
              </span>
            </Link>
          }
        </div>
      </header>
  )
}

Header.defaultProps = {
  hideCart: false
};

Header.propTypes = {
  hideCart: PropTypes.bool.isRequired
};