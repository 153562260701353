import React from 'react';
import {Modal} from "react-bootstrap";
import StoreFinder from "utils/components/StoreFinder"
import {LoadingCassette} from "@vintagestock/vintage-common-components"
import LocationCard from "scenes/Store/scenes/ProductsList/components/AvailabilityModal/components/LocationCard"
import "./styles/AvailabilityModal.scss"

export default function AvailabilityModal({show, onHide}) {
  return (
      <Modal
          show={show}
          onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Choose your store
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StoreFinder>
            {
              (locations, isLoading) => (
                  <div className="locations-list">
                    {
                      isLoading
                          ?
                          <LoadingCassette/>
                          :
                          (!locations || locations.length === 0)
                              ?
                              <div>No results for your current search.</div>
                              :
                              locations.map(location =>
                                  <LocationCard
                                      key={location.id}
                                      location={location}/>
                              )
                    }
                  </div>
              )
            }
          </StoreFinder>
        </Modal.Body>
        <Modal.Footer>
          <button
              type="button"
              className="btn btn-blue btn-block"
              onClick={onHide}>APPLY!
          </button>
        </Modal.Footer>
      </Modal>
  )
}
