import React from 'react';
import {Link} from "react-router-dom";

const OrderReturnSuccess = (props) => {
  const {action} = props.match.params;
  return (
      <div className="wrapper return">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>Your {action} request is being reviewed</h1>
            <p>
              We will contact you shortly regarding your request.
            </p>
            <Link
                to="/store/products"
                className="btn-custom text-uppercase">
              Continue shopping
            </Link>
          </div>
        </div>
      </div>
  );
};

export default OrderReturnSuccess;