import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderInput} from "utils/forms/renderers";
import {email, required} from "utils/forms/validators";
import PropTypes from "prop-types";
import "./styles/WishlistShareForm.scss";
// import {
//   FacebookIcon,
//   TwitterIcon,
//   FacebookShareButton,
//   TwitterShareButton,
// } from 'react-share';
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";

export const WishlistShareForm = ({isLoading, handleSubmit, onAfterShare, cancel, status}) => {
  // const urlToShare = typeof window !== 'undefined'
  //     ? `${window.location.origin}/wishlist/${getWishListId()}`
  //     : null;

  return (
      <form
          className="wishlist-share-form form"
          onSubmit={handleSubmit}>
        {
          status === SUCCESSFUL_STATUS ? (
              <p>Your wishlist was successfully shared, thanks.</p>
          ) : (
              <div className="form-group">
                <label htmlFor="email">Email to:</label>
                <Field id="email"
                       name="email"
                       component={renderInput}
                       type="email"
                       className="form-control"
                       validate={[required, email]}
                       placeholder="Type your email address"/>
              </div>
          )
        }
        <div className="form-group">
          {/*<div className="wishlist-share-form-social">*/}
          {/*<label>Or Share on:</label>*/}
          {/*<div className="wishlist-share-form-social__buttons">*/}
          {/*<TwitterShareButton*/}
          {/*url={urlToShare}*/}
          {/*title={urlToShare}*/}
          {/*onShareWindowClose={onAfterShare}>*/}
          {/*<TwitterIcon*/}
          {/*size={26}*/}
          {/*round={true}/>*/}
          {/*</TwitterShareButton>*/}
          {/*<FacebookShareButton*/}
          {/*url={urlToShare}*/}
          {/*quote={urlToShare}*/}
          {/*onShareWindowClose={onAfterShare}>*/}
          {/*<FacebookIcon*/}
          {/*size={26}*/}
          {/*round={true}/>*/}
          {/*</FacebookShareButton>*/}
          {/*</div>*/}
          {/*</div>*/}
          <div className="wishlist-share-form-buttons">
            {
              status === LOADING_STATUS ? (
                  <input type="submit" value="SHARING..." disabled={true}/>
              ) : (
                  status === INITIAL_STATUS &&
                  <input type="submit" value="SHARE VIA EMAIL"/>
              )
            }
            <a
                onClick={cancel}>
          <span className="btn btn-gray btn-block">
          {
            status !== SUCCESSFUL_STATUS
                ?
                <span>CANCEL</span>
                :
                <span>GO BACK</span>
          }
          </span>
            </a>
          </div>
        </div>
      </form>
  )
};

WishlistShareForm.propTypes = {
  status: PropTypes.oneOf(
      [INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS]
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'authForgotPasswordForm'
})(WishlistShareForm);
