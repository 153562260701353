import { connect } from 'react-redux';
import CreditCardReduxForm from "../index";
import {change} from 'redux-form';
import {deselectCreditCard} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    initialValues: state.myAccountReducer.selectedCreditCard,
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: (field, value) => dispatch(
        change('accountCreditCardForm', field, value)
    ),
    changeFieldValueForAddress: (field, value) => {
      dispatch(
          change('accountCreditCardForm', `address.${field}`, value)
      );
    },
    deselectCreditCard: () => dispatch(deselectCreditCard()),
    resetAddressForm: () => dispatch(
        change('accountCreditCardForm', 'address', null)
    ),
    changeRecaptcha: (value) => dispatch(
        change('accountCreditCardForm', 'recaptcha', value)
    )
  };
};

const CreditCardReduxFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCardReduxForm);

export default CreditCardReduxFormContainer;