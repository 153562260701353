import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import AccountMenu from "scenes/MyAccount/scenes/AccountMenu/index";
import ChangePassword from "scenes/MyAccount/scenes/ChangePassword/index";
import OrderHistoryContainer from "scenes/MyAccount/scenes/OrderHistory/container/index";
import OrderDetailsContainer from "scenes/MyAccount/scenes/OrderDetails/container/index";
import AddressBookContainer from "scenes/MyAccount/scenes/AddressBook/container/index";
import CreditCardsInfoContainer from "scenes/MyAccount/scenes/CreditCardsInfo/container/index";
import WishListContainer from "scenes/MyAccount/scenes/WishList/container/index";
import OrderReturnContainer from "scenes/MyAccount/scenes/OrderReturn/container/index";
import OrderReturnSuccess from "scenes/MyAccount/scenes/OrderReturnSuccess/index";
import OrderTrackingContainer from "scenes/MyAccount/scenes/OrderTracking/container";
import MyProfileContainer from "scenes/MyAccount/scenes/MyProfile/container";
import {getParsedQueryParams} from "services/browser-history/index";
import NewsletterSubscribe from "components/NewsletterSubscribe/index";
import VerifyEmailAlert from "scenes/MyAccount/components/VerifyEmailAlert"

const MyAccount = ({authReducer, match, location}) => {
  if (!authReducer.isAuthenticated)
    return <Redirect to={getParsedQueryParams()['from']
        ? `/auth/signup${location.search}`
        : '/auth/login'}/>;

  return (
      <div>
        <section className="page-content">
          <VerifyEmailAlert/>
          <Route exact path={match.url} component={AccountMenu}/>
          <Route path={`${match.url}/profile`} component={MyProfileContainer}/>
          <Route path={`${match.url}/password`} component={ChangePassword}/>
          <Route exact path={`${match.url}/order-history`} component={OrderHistoryContainer}/>
          <Route exact path={`${match.url}/order-history/:orderUUID`} component={OrderDetailsContainer}/>
          <Switch>
            <Route exact path={`${match.url}/order-history/:orderUUID/tracking`} component={OrderTrackingContainer}/>
            <Route exact path={`${match.url}/order-history/:orderUUID/:action`} component={OrderReturnContainer}/>
          </Switch>
          <Route path={`${match.url}/order-history/:orderUUID/:action/success`} component={OrderReturnSuccess}/>
          <Route path={`${match.url}/address-book`} component={AddressBookContainer}/>
          <Route path={`${match.url}/credit-cards`} component={CreditCardsInfoContainer}/>
          <Route path={`${match.url}/wishlist`} component={WishListContainer}/>
        </section>
        <NewsletterSubscribe/>
      </div>
  );
};

export default MyAccount;