import React from 'react';
import PropTypes from 'prop-types';
import "./styles/OrderItem.scss";

const OrderItem = ({item}) => (
    <div className="order-item">
      <div className="photo">
        <img
            src={item.cart_image_thumbnail}
            alt={item.product_name}/>
      </div>
      <div className="info">
        <p>{item.product_name}</p>
        <p>
          <span>${item.price}</span>
          <span className="qty">x{item.quantity}</span>
        </p>
      </div>
    </div>
);

OrderItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default OrderItem;
