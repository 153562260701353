import { connect } from 'react-redux';
import CreditCardsInfo from "../index";
import {receiveCreditCards, selectCreditCard} from "scenes/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCreditCards: creditCards => dispatch(receiveCreditCards(creditCards)),
    selectCreditCard: creditCard => dispatch(selectCreditCard(creditCard))
  };
};

const CreditCardsInfoContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCardsInfo);

export default CreditCardsInfoContainer;