import { connect } from 'react-redux';
import CheckoutAddressForm from "../index";
import {receiveAddresses, selectAddress} from "scenes/MyAccount/actions/index";
import {selectCheckoutBillingAddress, selectCheckoutShippingAddress} from "scenes/Store/scenes/Checkout/actions/index";
import {isValid} from "redux-form";
import {ADDRESS_FORM_NAME} from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressForm/constants";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    myAccountReducer: state.myAccountReducer,
    checkoutReducer: state.checkoutReducer,
    addressFormIsValid: isValid(ADDRESS_FORM_NAME)(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveAddresses: addresses => dispatch(receiveAddresses(addresses)),
    selectAddress: address => dispatch(selectAddress(address)),
    selectCheckoutShippingAddress: addressId => dispatch(selectCheckoutShippingAddress(addressId)),
    selectCheckoutBillingAddress: addressId => dispatch(selectCheckoutBillingAddress(addressId))
  };
};

const CheckoutAddressFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutAddressForm);

export default CheckoutAddressFormContainer;