import React, {Component} from 'react';
import {GoogleMap, Marker, InfoWindow, withGoogleMap, withScriptjs} from "react-google-maps"
import pin from 'assets/img/pin.png';

class AsyncGoogleMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: this.props.locations,
      zoom: 20
    };

    this.onMarkerClick = location => this.handleMarkerClick.bind(this, location);
    this.onCloseClick = location => this.handleInfoWindowCloseClick.bind(this, location);
  }

  componentDidUpdate(prevProps, prevState) {
    // Point quantity to ubicate in the google map
    const pointCount = this.props.locations.length;

    if (pointCount > 0) {
      if (pointCount > 1) {
        const bounds = new window.google.maps.LatLngBounds();
        this.props.locations.map((location) => {
          bounds.extend(new window.google.maps.LatLng(
              location.geo_lat,
              location.geo_long
          ));
        });
        this.refs.map.fitBounds(bounds);
      } else {
        if (prevState.zoom !== this.state.zoom)
          this.setState({zoom: 13});
      }
    }
  }

  /**
   * Handles marker click to set showInfoWindow to true
   * @param {Object} clickedLocation
   */
  handleMarkerClick(clickedLocation) {
    this.props.openInfoWindow(clickedLocation);
  }

  /**
   * Handles info windows close click to set showInfoWindow to false
   * @param {Object} clickedLocation
   */
  handleInfoWindowCloseClick(clickedLocation) {
    this.props.closeInfoWindow(clickedLocation);
  }

  render() {
    const {zoom} = this.state;
    const {center, locations} = this.props;

    return (
        <GoogleMap
            ref='map'
            defaultZoom={zoom}
            center={center}>
          {
            (locations && locations.length > 0) && locations.map((location, index) => (
                <Marker
                    key={`vintageLocation${index}`}
                    icon={pin}
                    onClick={this.onMarkerClick(location)}
                    position={{lat: location.geo_lat, lng: location.geo_long}}>
                  {
                    location.showInfoWindow && (
                        <InfoWindow
                            onCloseClick={this.onCloseClick(location)}>
                          <div>
                            <h4>Vintage Stock - {location.city_name}</h4>
                          </div>
                        </InfoWindow>
                    )
                  }
                </Marker>
            ))
          }
        </GoogleMap>
    )
  }
}

export default {
  Map: withScriptjs(withGoogleMap(AsyncGoogleMap)),
  MapWithDOMInstances: withGoogleMap(AsyncGoogleMap)
}
