import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import NewsletterSubscribeReduxForm from "scenes/Home/components/NewsletterModal/components/NewsletterSubscribeForm";
import vintageAxios from "services/api";

class NewsletterModal extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false,
      errors: {}
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Makes the request to the API to subscribe the user.
   * @param {Object} data
   * @returns {AxiosPromise<any>}
   */
  subscribe(data){
    const promise = vintageAxios.post('/misc/newsletter/subscribe/', data);
    promise.then(response => {

      if (this.props.onSubmit)
        this.props.onSubmit(true);

      this.props.onHide();
      this.setState({
        loading: false
      });
    });
    promise.catch(error => {
      const {data} =  error.response;
      if (data.email && this.props.onSubmit)
        this.props.onSubmit(false);
      this.props.onHide();
      this.setState({
        errors: data,
        loading: false
      });
    });
    return promise;
  }

  /**
   * Handles the submit on the redux form component
   * @param {Object} data
   */
  handleFormSubmit(data){
    this.setState({
      loading: true
    }, () => {
      this.subscribe(data);
    });
  }

  render(){
    const {show, onHide} = this.props;
    const {loading, errors} = this.state;

    return(
        <Modal
            show={show}
            onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>
              Sign up for our newsletter
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewsletterSubscribeReduxForm
                isLoading={loading}
                errors={errors}
                onSubmit={this.handleFormSubmit}/>
          </Modal.Body>
        </Modal>
    )
  }
}

NewsletterModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

export default NewsletterModal