import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./styles/QuantityInput.scss";
import {MINUS, PLUS} from "utils/components/QuantityInput/constants/control-names";
import {NEW} from "utils/constants/product-conditions";

class QuantityInput extends Component {
  constructor(props){
    super(props);

    this.state = {
      quantity: this.props.quantity
    };

    this.handleQuantityInputChange = this.handleQuantityInputChange.bind(this);
    this.onClickControl = controlName => this.handleControlClick.bind(this, controlName);
  }

  /**
   * Updates quantity in the component's state and calls callback.
   * @param {Number} quantity
   */
  updateQuantityInState(quantity){
    this.setState({
      quantity: quantity
    }, () => {
      this.props.onQuantityChanges(quantity);
    });
  }

  /**
   * Handles direct user typing in the quantity input
   * @param {Object} event
   */
  handleQuantityInputChange(event){
    const value = event.target.value || 1;
    if(this.validateQuantity(parseInt(value, 10))) {
      this.updateQuantityInState(parseInt(value, 10));
    }
  }

  /**
   * Validates the selected quantity is available in stock.
   */
  validateQuantity(quantity){
    const {condition, isPreOrder} = this.props;
    // pre-order items should skip validation because of course these are not available
    const maxQuantity = isPreOrder ? 9999999 : condition === NEW ? this.props.stockNew : this.props.stockUsed;
    if( quantity > maxQuantity ){
      // this.props.showLimitExceeded();
      this.updateQuantityInState(maxQuantity)
      return false;
    }
    return true;
  }

  /**
   * Handles changes through the + and - controls.
   * @param {String} controlName
   */
  handleControlClick(controlName){
    let {quantity} = this.state;
    quantity = parseInt(quantity, 10);
    const newQuantity = controlName === PLUS ? quantity + 1 : quantity - 1;
    if( newQuantity > 0 ) {
      if(this.validateQuantity(newQuantity)) {
        this.updateQuantityInState(newQuantity);
      }
    }
  }

  render(){
    const {quantity} = this.state;

    return(
        <span className="quantity-input">
          <input
              type="number"
              className="quantity-input__form-control form-control"
              value={quantity}
              aria-label={"Quantity input"}
              aria-valuenow={quantity}
              onChange={this.handleQuantityInputChange}/>
          <span
              className={`quantity-input__control quantity-input__control--${PLUS}`}
              aria-label={"Increase quantity"}
              onClick={this.onClickControl(PLUS)}>
            +
          </span>
          <span
              className={`quantity-input__control quantity-input__control--${MINUS}`}
              aria-label={"Decrease quantity"}
              onClick={this.onClickControl(MINUS)}>
            -
          </span>
        </span>
    )
  }
}

QuantityInput.propTypes = {
  quantity: PropTypes.number.isRequired,
  onQuantityChanges: PropTypes.func.isRequired,
  condition: PropTypes.number.isRequired,  // TODO validate this should be one of NEW or USED constants,
  stockNew: PropTypes.number.isRequired,
  stockUsed: PropTypes.number.isRequired,
  // showLimitExceeded: PropTypes.func.isRequired
};

QuantityInput.defaultProps = {
  // TODO set this prop everywhere this component is instantiated, currently only being done in CartItem component
  isPreOrder: false
}

export default QuantityInput
