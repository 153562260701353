import React, {Component} from 'react';
import {Route} from "react-router-dom";
import SelectAddressContainer from "scenes/Store/scenes/Checkout/scenes/SelectAddress/container/index";
import SelectShippingMethodContainer from "scenes/Store/scenes/Checkout/scenes/SelectShippingMethod/container/index";
import SelectPaymentMethodContainer from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/container/index";
import "./styles/Checkout.scss";
import SummaryContainer from "scenes/Store/scenes/Checkout/scenes/Summary/container/index";
import SuccessContainer from "scenes/Store/scenes/Checkout/scenes/Success/container/index";

class Checkout extends Component {
  render(){
    const {match} = this.props;

    return(
        <div>
          <Route exact path={`${match.url}/address`} component={SelectAddressContainer}/>
          <Route exact path={`${match.url}/shipping`} component={SelectShippingMethodContainer}/>
          <Route exact path={`${match.url}/payment`} component={SelectPaymentMethodContainer}/>
          <Route exact path={`${match.url}/summary`} component={SummaryContainer}/>
          <Route exact path={`${match.url}/:orderUUID/success`} component={SuccessContainer}/>
        </div>
    )
  }
}

export default Checkout
