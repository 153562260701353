import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {addToWishList} from "services/store/wishlist";
import {Link, withRouter} from "react-router-dom";
import {isAuthenticated} from "services/auth";
import vintageHistory from "services/browser-history/index";

export class AddToWishListButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adding: false,
      added: false
    };

    this.handleAddToWishListClick = this.handleAddToWishListClick.bind(this);
  }

  /**
   * Add an item to the wish list.
   * @returns {AxiosPromise<any>}
   */
  addToWishList() {
    const {productId, contentType} = this.props;
    let promise = addToWishList({
      productId: productId,
      contentType: contentType,
      condition: this.props.selectedCondition
    });
    promise.then(response => {
      this.setState({adding: false, added: true}, () => {
        setTimeout(() => {
          this.setState({added: false}, () => {
            // Exists an callback function
            if (this.props.onAfterAddToWishList)
              this.props.onAfterAddToWishList();
          });
        }, 1500);
      });
    });
    promise.catch(error => {
      this.setState({adding: false});
    });
    return promise;
  }

  /**
   * Handles clicks on add to add to wish list button.
   */
  handleAddToWishListClick() {
    const {location} = this.props;

    if (isAuthenticated())
      return this.setState({
        adding: true
      }, () => {
        return this.addToWishList();
      });
    else
      vintageHistory.push({
        pathname: "/auth/login",
        search: `?wishlist=1&from=${location.pathname}`
      });
  }

  render() {
    const {adding, added} = this.state;
    const {location, context, onlyIcon} = this.props;

    if (context) {
      if (!isAuthenticated())
        return (
            <Link
                to={`/auth/login?wishlist=1&from=${location.pathname}`}>
              <span className="icon icon-wishlist-book"/>
              <span>Wishlist</span>
            </Link>
        );

      return (
          <a
              onClick={this.handleAddToWishListClick}>
            <span className="icon icon-wishlist-book"/>
            {
              adding ? (
                  <span>
                  <i className="fa fa-spinner fa-spin"/>
                    &nbsp;
                    <span>{onlyIcon ? "" : "Moving..."}</span>
                </span>
              ) : (
                  added ?
                      <span>
                      <i className="fa fa-check"/>
                        {onlyIcon ? "" : "Moved"}
                    </span>
                      :
                      <span>
                        &nbsp;
                        {onlyIcon ? "" : "Wishlist"}
                    </span>
              )
            }
          </a>
      );
    }

    if (!isAuthenticated())
      return (
          <button
              type="button"
              className="btn-custom b"
              onClick={this.handleAddToWishListClick}>
            <span>
              <span className="icon icon-wishlist-book"/>
              {onlyIcon ? "" : "ADD TO WISH LIST"}
            </span>
          </button>
      );

    return (
        <button
            type="button"
            className="btn-custom b"
            disabled={adding}
            onClick={this.handleAddToWishListClick}>
          {
            adding ? (
                <span>
                  <i className="fa fa-spinner fa-spin"/>
                  &nbsp;
                  <span>{onlyIcon ? "" : "ADDING..."}</span>
                </span>
            ) : (
                added ?
                    <span>
                      <i className="fa fa-check"/>
                      {onlyIcon ? "" : "ADDED"}
                    </span>
                    :
                    <span>
                      <span className="icon icon-wishlist-book"/>
                      &nbsp;
                      {onlyIcon ? "" : "ADD TO WISH LIST"}
                    </span>
            )
          }
        </button>
    )
  }
}

AddToWishListButton.defaultProps = {
  onlyIcon: false
};

AddToWishListButton.propTypes = {
  productId: PropTypes.number.isRequired,
  contentType: PropTypes.number.isRequired,
  selectedCondition: PropTypes.number.isRequired,
  onAfterAddToWishList: PropTypes.func,
  context: PropTypes.string,
  onlyIcon: PropTypes.bool.isRequired
};

export default withRouter(AddToWishListButton)