import React from 'react';
import "./styles/PayPalInfo.scss";
import "./styles/PayPalInfo.scss";

const PayPalInfo = () => (
    <p className="paypal-info">
      <span className="icon paypal"/>
    </p>
);


export default PayPalInfo;
