import { connect } from 'react-redux';
import WishListItem from "../index";
import {removeWishListItem} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeWishListItem: wishListItem => dispatch(removeWishListItem(wishListItem))
  };
};

const WishListItemContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WishListItem);

export default WishListItemContainer;