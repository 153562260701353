import React, {Component} from 'react';
import PropTypes from 'prop-types';
import vintageAxios from "services/api";
import classNames from "classnames/bind";
import {applyDiscountToCart, cleanDiscountFromCart} from "services/store";
import "./styles/CartCoupon.scss";

class CartCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coupon: '',
      validating: false,
      errorMessage: null,
      deleting: false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
  }

  /**
   * Validates the discount code with the API.
   * @returns {AxiosPromise<any>}
   */
  verifyCouponCode() {
    const {coupon} = this.state;
    const {cartTotals} = this.props.storeReducer;
    const promise = vintageAxios.get(`/management/discount-codes/${coupon}/`);
    promise.then(response => {
      const {data} = response;
      this.setState({
        validating: false
      }, () => {
        if (parseFloat(cartTotals.total) < data.minimum_total)
          this.setState({errorMessage: `The minimum total of your cart to use this coupon is $${data.minimum_total}`});
        else if (parseFloat(cartTotals.total) > data.maximum_total)
          this.setState({errorMessage: `The maximum total of your cart to use this coupon is $${data.maximum_total}`});
        else {
          applyDiscountToCart(response.data);
          this.props.receiveDiscountCode(response.data);
        }
      });
    });
    promise.catch(error => {
      this.setState({
        validating: false,
        errorMessage: 'Invalid discount code.'
      });
    });
    return promise;
  }

  /**
   * Handles submit event on the <form> component.
   * @param event
   */
  handleFormSubmit(event) {
    event.preventDefault();
    if (this.state.coupon.length > 0)
      this.setState({
        validating: true,
        // Reset errors with every submit
        errorMessage: null
      }, () => {
        this.verifyCouponCode();
      });
  }

  /**
   * Handles changes on the coupon input
   * @param event
   */
  handleInputChange(event) {
    const value = event.target.value;
    this.setState({coupon: value});
  }

  /**
   *
   */
  handleDeleteButtonClick() {
    this.setState({
      deleting: true
    }, () => {
      const promise = cleanDiscountFromCart();
      promise.then(response => {
        this.setState({deleting: false});
        this.props.receiveDiscountCode(null);
      });
    });
  }

  render() {
    const {couponBoxStyle} = this.props;
    const {coupon, validating, errorMessage, deleting} = this.state;
    const {cart} = this.props.storeReducer;

    return (
        <div>
          {
            cart.discount_code_data ? (
                <div className={classNames({
                  "wrapper coupon-details": true,
                  "coupon-details--large": couponBoxStyle === "large",
                  "coupon-details--compact": couponBoxStyle === "compact"
                })}>
                  <div className="valid-coupon">
                    <div className="row">
                      <div className="col-xs-8">
                        <span className="coupon-name">
                          <i className="icon-circle-check"/>&nbsp;
                          Coupon <strong>{cart.discount_code_data.code}</strong>
                        </span>
                      </div>
                      <div className="col-xs-4 text-right">
                        <button
                            type="button"
                            disabled={deleting}
                            className="coupon-delete-button"
                            onClick={this.handleDeleteButtonClick}>
                          {
                            deleting
                                ?
                                <i className="fa fa-spinner fa-spin"/>
                                :
                                <i className="icon-rubbish"/>
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
            ) : (
                <div className={classNames({
                  "coupon-form-container": true,
                  "coupon-form-container--large": couponBoxStyle === "large",
                  "coupon-form-container--compact": couponBoxStyle === "compact"
                })}>
                  <h2 className="coupon-form-container__title">Have a Coupon?</h2>
                  <form
                      className="form"
                      onSubmit={this.handleFormSubmit}>
                    <div className="clearfix">
                      <div className={classNames({
                        "form-group": true,
                        "has-error": errorMessage !== null
                      })}>
                        <label className="sr-only" htmlFor="coupon">Coupon code</label>
                        <input
                            type="text"
                            className="form-control"
                            id="coupon"
                            value={coupon}
                            onChange={this.handleInputChange}
                            placeholder="Enter your coupon here"/>
                        {
                          errorMessage &&
                          <i className="icon-alert"/>
                        }
                        {
                          (errorMessage && couponBoxStyle === "large") &&
                          <span className="help-block">
                            {errorMessage}
                          </span>
                        }
                      </div>
                      <div className="form-group text-right">
                        <input
                            type="submit"
                            disabled={validating || !coupon}
                            value={validating ? "APPLY..." : "APPLY"}/>
                      </div>
                    </div>
                    {
                      (errorMessage && couponBoxStyle === "compact") &&
                      <span className="help-block">
                        {errorMessage}
                      </span>
                    }
                  </form>
                </div>
            )
          }
        </div>
    )
  }
}

CartCoupon.propTypes = {
  couponBoxStyle: PropTypes.oneOf('large', 'compact')
};

export default CartCoupon
