import React from 'react';
import classNames from "classnames/bind";
import {IMAGE_BY_PRICE} from "scenes/GiftCards/components/GiftCardsItem/constants/constants";
import AddToCartButtonContainer from "scenes/Store/scenes/ProductDetails/components/AddToCartButton/container/index";
import PropTypes from 'prop-types';
import {
  GIFT_CARDS
} from "scenes/Store/scenes/ProductDetails/components/AddToCartButton/constants/contexts";

const GiftCardsItem = ({price, productId}) => (
    <div className="gift-cards-item">
      <div className="gift-cards-item__header">
        <span className="gift-cards-item__header__holder"/>
        <span className="gift-cards-item__header__logo"/>
      </div>
      <div className="gift-cards-item__content">
        <p>CHOOSE FROM OVER</p>
        <h1>150,000</h1>
        <p>PRODUCTS FOR YOU!</p>
      </div>
      <div className={classNames({
        "gift-cards-item__price": true,
        [IMAGE_BY_PRICE[price]]: true
      })}>
        <h1>${price}</h1>
        <label>GIFT CARD</label>
      </div>
      <p className="purchase">
        Not valid for website purchases. Only purchases at physical locations.
      </p>
      <div className="gift-cards-item__add-to-cart">
        <AddToCartButtonContainer
            contentTypeId={parseInt(process.env.REACT_APP_GIFT_CARD_CONTENT_TYPE, 10)}
            productId={productId}
            quantity={1}
            context={GIFT_CARDS}
            selectedCondition={0}/>
      </div>
    </div>
);

GiftCardsItem.propTypes = {
  productId: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired
};

export default GiftCardsItem;