import { connect } from 'react-redux';
import SelectShippingMethod from "../index";
import {receiveCartTotals} from "scenes/Store/actions/index";

const mapStateToProps = (state) => {
  return {
    checkoutReducer: state.checkoutReducer,
    cartTotals: state.storeReducer.cartTotals
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCartTotals: totals => dispatch(receiveCartTotals(totals))
  };
};

const SelectShippingMethodContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectShippingMethod);

export default SelectShippingMethodContainer;