import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {renderCheckbox, renderInput, renderPhoneInput} from "utils/forms/renderers";
import {email, onlyText, required} from "utils/forms/validators";
import Select from "react-select";
import vintageAxios from "services/api/index";
import {
  SHIPPING_FIELD
} from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressFields/constants/index";
import {renderSelect} from "utils/forms/renderers";

export class AddressFields extends Component {
  constructor(props) {
    super(props);

    const {initialValues} = this.props;

    this.state = {
      selectedCity: '',
      cityPlaceholder: initialValues && initialValues.id
          ?
          `${initialValues.city_name}, ${initialValues.region_name}, ${initialValues.country_name}`
          :
          'Select state, then input city here…',
      regions: [],
      selectedRegion: null
    };

    this.onChange = this.onChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.getCities = this.getCities.bind(this);
  }

  componentDidMount() {
    this.fetchRegions();
  }

  /**
   * Removes phone number format
   * @param phoneNumber
   */
  static parsePhoneNumberFormat(phoneNumber) {
    return phoneNumber.replace(/[ ()-]/g, "");
  }

  /**
   * Fetches locations from the API in order to render the select
   */
  fetchRegions() {
    const {initialValues} = this.props;
    const promise = vintageAxios.get('/misc/region-list/');
    promise.then(response => {
      let selectedRegion = null;
      if (initialValues) {
        selectedRegion = response.data.find(item => item.code === initialValues.region_name);
        this.props.changeFieldValue('region', selectedRegion ? selectedRegion.id: null);
      }

      this.setState({
        regions: response.data,
        selectedRegion: selectedRegion ? selectedRegion.id : null
      });

    });
    return promise;
  }

  onChange(city) {
    this.setState({
      selectedCity: city
    });
    this.props.changeFieldValue('city', city ? city.id: null);
  }

  handleRegionChange(event) {
    this.setState({
      selectedRegion: event.target.value,
      selectedCity: ""
    });
    this.props.changeFieldValue('city', null);
    if (this.refs.cityField)
      this.refs.cityField.loadOptions("")
  }

  getCities(input) {
    const {selectedRegion} = this.state;

    if (!input || !selectedRegion) {
      return Promise.resolve({options: []});
    }

    const queryParams = {
      'search': input,
      'region': selectedRegion
    };
    return vintageAxios.get('/misc/city-list/', {
      params: queryParams
    })
        .then((response) => {
          return {options: response.data}
        });
  }

  render() {
    const {errors, removeOptions, initialValues} = this.props;
    const {isAuthenticated} = this.props.authReducer;
    const {regions, selectedRegion} = this.state;

    return (
        <div className="form address-form">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="first_name">First Name <span className="required">*</span></label>
                <Field id="first_name"
                       name="first_name"
                       component={renderInput}
                       type="text"
                       className="form-control"
                       placeholder="Type your first name"
                       validate={[required, onlyText]}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="last_name">Last Name <span className="required">*</span></label>
                <Field id="last_name"
                       name="last_name"
                       component={renderInput}
                       type="text"
                       className="form-control"
                       placeholder="Type your last name"
                       validate={[required, onlyText]}/>
              </div>
            </div>
            <div className="clearfix"/>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="address_1">Address Line 1 <span className="required">*</span></label>
                <Field id="address_1"
                       name="address_1"
                       component={renderInput}
                       type="text"
                       className="form-control"
                       placeholder="Type your address line 1"
                       validate={[required]}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="address_2">Address Line 2</label>
                <Field id="address_2"
                       name="address_2"
                       component={renderInput}
                       type="text"
                       placeholder="Type your address line 2"
                       className="form-control"/>
              </div>
            </div>
            <div className="clearfix"/>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="region">State <span className="required">*</span></label>
                <Field
                    component={renderSelect}
                    className="form-control"
                    validate={[required]}
                    onChange={this.handleRegionChange}
                    name="region"
                    disabled={regions.length === 0}>
                  <option value="">{regions.length === 0 ? 'Loading states...' : 'Select a state'}</option>
                  {
                    regions.map(region =>
                        <option
                            key={`region${region.id}`}
                            value={region.id}>
                          {region.name}
                        </option>
                    )
                  }
                </Field>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="city">City <span className="required">*</span></label>
                <Select.Async
                    ref="cityField"
                    multi={false}
                    name="city_autocomplete"
                    value={this.state.selectedCity}
                    onChange={this.onChange}
                    aria-label="Select city"
                    valueKey="id"
                    style={{border: '2px solid #D3D9DB', color: '#4E5A61'}}
                    labelKey="autocomplete_text"
                    inputProps={{autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off'}}
                    placeholder={this.state.cityPlaceholder}
                    loadOptions={this.getCities}
                    disabled={!selectedRegion}/>
                <Field
                    id="city"
                    name="city"
                    component={renderInput}
                    type="number"
                    className="form-control hidden"
                    validate={[required]}/>
              </div>
            </div>
            <div className="clearfix"/>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="zip_code">Zip Code <span className="required">*</span></label>
                <Field id="zip_code"
                       name="zip_code"
                       component={renderInput}
                       type="number"
                       className="form-control"
                       placeholder="Type your zip code"
                       validate={[required]}/>
                {errors.non_field_errors && errors.non_field_errors.map((msj, index) => (
                    <span className="help-block" key={`addressFormZipcodeError${index}`}>{msj}</span>
                ))}
              </div>
            </div>
            {
              !isAuthenticated &&
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">Email <span className="required">*</span></label>
                  <Field id="email"
                         name="email"
                         component={renderInput}
                         type="email"
                         className="form-control"
                         validate={[required, email]}
                         placeholder="Type your email address"/>
                  {errors.email && errors.email.map((msj, index) => (
                      <span className="help-block" key={`addressFormEmailError${index}`}>{msj}</span>
                  ))}
                </div>
              </div>
            }
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="phone">Phone <span className="required">*</span></label>
                <Field id="phone"
                  name="phone"
                  component={renderPhoneInput}
                  className="form-control"
                  validate={[required]}
                  placeholder="Enter your phone"
                  parse={value => AddressFields.parsePhoneNumberFormat(value)}/>
                {errors.phone && errors.phone.map((msj, index) => (
                    <span className="help-block" key={`addressFormEmailError${index}`}>{msj}</span>
                ))}
              </div>
            </div>
          </div>
          {
            !removeOptions &&
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <div className="custom-checkbox">
                    <Field
                        component={renderCheckbox}
                        id={SHIPPING_FIELD}
                        name={SHIPPING_FIELD}
                        defaultChecked={!!(initialValues && initialValues[SHIPPING_FIELD])}/>
                  </div>
                  <label
                      className="address-form__default"
                      htmlFor={SHIPPING_FIELD}>Use as default for shipping</label>
                </div>
              </div>
            </div>
          }
        </div>
    )
  }
}

AddressFields.defaultProps = {
  removeOptions: false
};

AddressFields.propTypes = {
  errors: PropTypes.object,
  removeOptions: PropTypes.bool
};

export default AddressFields;