import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import {Link} from "react-router-dom";
import OrderContainer from "scenes/MyAccount/scenes/OrderHistory/components/Order/container/index";
import {getParsedQueryParams, updateQueryParams} from "services/browser-history";
import Breadcrumb from "utils/components/Breadcrumb/index";
import SEO from "components/SEO/index";

class OrderHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: getParsedQueryParams()['year'] || '',
    };

    this.handleYearSelectChange = this.handleYearSelectChange.bind(this);
  }

  componentDidMount() {
    this.fetchOrders();  // fetch always, no matter if already in redux store
    // TODO what about updating store when user finishes checkout process?
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search)  // Query params updated
      this.fetchOrders();
  }

  /**
   * Get orders from API and store them in the redux state.
   * @returns {AxiosPromise<any>}
   */
  fetchOrders() {
    this.props.clearOrders();
    const promise = vintageAxios.get('/management/orders/', {
      params: getParsedQueryParams()
    });
    promise.then(response => {
      this.props.receiveOrders(response.data);
    });
    return promise;
  }

  /**
   * Generate a list with the last 10 years from now
   * @returns {Array} years
   */
  get years() {
    const currentYear = new Date().getFullYear();
    const array = [currentYear];
    let counter = 1;
    while (counter <= 10) {
      array.push(currentYear - counter);
      counter += 1;
    }
    return array;
  }

  /**
   * Handle changes in the year <select>
   * @param {Object} event
   */
  handleYearSelectChange(event) {
    const value = event.target.value;
    this.setState({year: value});
    // updateQueryParams({
    //   year: value
    // });
  }


  render() {
    const {orders} = this.props.myAccountReducer;
    const {year} = this.state;

    return (
        <div>
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "Order History", to: "/my-account/order-history"},
              ]}/>
          <div className="container">
            <div className="wrapper orders">
              <div className="section-title">
                <h2>
                  <span className="icon icon-shopping-bag"/>
                  <span className="text">Order History</span>
                </h2>
                <div className="filters">
                  <form className="form-inline">
                    <div className="form-group">
                      <select
                          className="form-control"
                          aria-label="Date filter"
                          value={year}
                          onChange={this.handleYearSelectChange}>
                        <option value="">All Orders</option>
                        {
                          this.years.map(year =>
                              <option
                                  key={`year${year}`}
                                  value={year}>
                                {year}
                              </option>
                          )
                        }
                      </select>
                    </div>
                  </form>
                </div>
              </div>
              {
                orders === null ? (
                    <LoadingCassette/>
                ) : (
                    orders.length === 0 ? (
                        <div className="empty-message">
                          <h1>Your Order History is Empty.</h1>
                          <p>Looks like you haven’t made any order yet.</p>
                          <div className="footer">
                            <div className="bg orders"/>
                            <p>
                              <Link to="/store/products">
                                <span className="btn-custom">GO TO STORE</span>
                              </Link>
                            </p>
                          </div>
                        </div>
                    ) : (
                        orders.map(order =>
                            <OrderContainer
                                key={`order${order.id}`}
                                order={order}/>
                        )
                    )
                )
              }
            </div>
          </div>
          <SEO url="my-account/order-history"/>
        </div>
    )
  }
}

export default OrderHistory