import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import "./styles/ProductListView.scss";
import NotifyMeWhenAvailableButton from "utils/components/NotifyMeWhenAvailableButton/index";
import {
  ALL_ICONS,
  DEFAULT_ICON
} from "scenes/Store/scenes/ProductsList/components/Product/components/ProductImage/contants/icons";
import AddToCartButtonContainer from "scenes/Store/scenes/ProductDetails/components/AddToCartButton/container/index";
import {ProductContext, ProductIcon, FindInStoreBtn} from "@vintagestock/vintage-common-components";
import Moment from "react-moment";
import moment from "moment";
import WishlistHeartBtnContainer from "scenes/Store/components/WishlistHeartBtn/container";
import {RADIO_ONLINE_NEW, RADIO_ONLINE_USED} from "scenes/Store/components/PriceTable/constants/radios"
import PriceTable from "scenes/Store/components/PriceTable"
import ProductAvailableIn from "scenes/Store/scenes/ProductsList/components/Product/components/ProductAvailableIn"

export default function ProductListView({productUrl, onClick}) {
  const {product, condition, quantity, activeRadio} = useContext(ProductContext);

  let icon = product.icon;
  if (ALL_ICONS.indexOf(icon) === -1)
    icon = DEFAULT_ICON;

  return (
      <div className="product">
        <div className="product__product-type">
          <ProductIcon icon={product.icon}/>
        </div>
        <div className="product--list-view">
          <div className="div-wrapper">
            <div className="photo">
              <Link to={`/store/products/${product.slug}`}
                    onClick={onClick}>
                <img
                    src={product.details_image_thumbnail}
                    alt={product.name} className="img-responsive"/>
              </Link>
              <div className="photo__wishlist-btn">
                <WishlistHeartBtnContainer/>
              </div>
            </div>
            <div className="content product-info">
              <Link to={`/store/products/${product.slug}`}
                    onClick={onClick}>
                <p className="product-info__name">{product.name}</p>
              </Link>
              {
                moment(product.release_date) > moment(new Date()) &&
                <p className="product-info__release-date">
                  Release:&nbsp;
                  <Moment format="MMM D, YYYY">
                    {product.release_date}
                  </Moment>
                </p>
              }
              {
                product.artist &&
                <p className="product-info__artist">
                  {product.artist}
                </p>
              }

              <PriceTable/>
              {
                product.in_stock || product.pickup_available
                    ?
                    <div style={{textAlign: "left"}}>
                      {
                        (activeRadio === RADIO_ONLINE_NEW || activeRadio === RADIO_ONLINE_USED)
                            ?
                            <AddToCartButtonContainer
                                contentTypeId={product.content_type}
                                productId={product.id}
                                quantity={quantity}
                                productData={{
                                  sku: product.sku,
                                  name: product.name
                                }}
                                className="btn"
                                selectedCondition={condition}
                                hideIcon={product.is_pre_order}
                                customLabel={product.is_pre_order ? "PRE-ORDER" : "Add to cart"}
                                size="small"/>
                            :
                            /* I've temporarily put the same class as the add to cart button because stylesheets of this project sucks */
                            <>
                              <ProductAvailableIn stores={product.stores}/>
                              <FindInStoreBtn
                                  className="btn"/>
                            </>
                      }
                    </div>
                    :
                    <React.Fragment>
                      {
                        product.is_pre_order &&
                        <NotifyMeWhenAvailableButton
                            condition={condition}
                            stockNew={product.total_stock_new}
                            stockUsed={product.total_stock_used}
                            productId={product.id}
                            onlyIcon/>
                      }
                    </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
  );
};
