import { connect } from 'react-redux';
import CartTotals from "../index";
import {receiveCartTotals} from "scenes/Store/actions/index";
import {selectCheckoutShippingAddress} from "scenes/Store/scenes/Checkout/actions/index";
import {receiveAddresses, selectAddress} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer,
    checkoutReducer: state.checkoutReducer,
    myAccountReducer: state.myAccountReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveAddresses: addresses => dispatch(receiveAddresses(addresses)),
    selectAddress: address => dispatch(selectAddress(address)),
    selectCheckoutShippingAddress: addressId => dispatch(selectCheckoutShippingAddress(addressId)),
    receiveCartTotals: totals => dispatch(receiveCartTotals(totals))
  };
};

const CartTotalsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartTotals);

export default CartTotalsContainer;