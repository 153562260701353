import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {renderInput} from "utils/forms/renderers";
import {required} from "utils/forms/validators";

export const ChangePasswordForm = ({isLoading, success, errors, handleSubmit}) => {
  const fields = [
    {
      label: 'Current Password',
      name: 'current_password',
      autoComplete: 'current-password',
      placeholder: 'Type your current password'
    },
    {
      label: 'New Password',
      name: 'new_password',
      autoComplete: 'new-password',
      placeholder: 'Type your new password'
    },
    {
      label: 'Confirm Password',
      name: 're_new_password',
      placeholder: 'Type your confirm password'
    },
  ];

  return (
      <form className="form" onSubmit={handleSubmit}>
        {
          fields.map((field, index) => (
              <div className="form-group" key={`changePasswordFormField${index}`}>
                <label htmlFor={field.name}>{field.label} <span className="required">*</span></label>
                <Field id={field.name}
                       name={field.name}
                       component={renderInput}
                       type="password"
                       className="form-control"
                       placeholder={field.placeholder}
                       validate={[required]}
                       autoComplete={field?.autoComplete}/>
                {errors[field.name] && errors[field.name].map((msj, index) => (
                    <span className="help-block" key={`changePasswordFormField-${field.name}-error-${index}`}>{msj}</span>
                ))}
                {errors.non_field_errors && index === (fields.length - 1) && errors.non_field_errors.map((msj, index) => (
                    <span className="help-block" key={`changePasswordFormNonFieldError${index}`}>{msj}</span>
                ))}
              </div>
          ))
        }
        <div className="bottom">
          <div className="form-group">
            {
              isLoading ? (
                  <input type="submit" value="CHANGING..." disabled={true}/>
              ) : (
                  success ? (
                      <button type="submit" disabled={true}>
                        <i className="fa fa-check"/> PASSWORD WAS CHANGED!
                      </button>
                  ) : (
                      <input type="submit" value="CHANGE PASSWORD"/>
                  )
              )
            }
          </div>
        </div>
      </form>
  );
};

ChangePasswordForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const ChangePasswordReduxForm = reduxForm({
  form: 'authChangePasswordForm'
})(ChangePasswordForm);

export default ChangePasswordReduxForm