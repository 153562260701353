import React from 'react';
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = ({afterCheckedRecaptcha, onExpiredRecaptcha}) => {
  return (
      <div>
        <label htmlFor="g-recaptcha-response" className={"sr-only"}>Recaptcha Textarea</label>
        <label htmlFor="recaptcha" className={"sr-only"}>Input recaptcha</label>
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={afterCheckedRecaptcha}
            aria-hidden="true"
            onExpired={onExpiredRecaptcha}/>
      </div>
  )
};

Recaptcha.propTypes = {
  afterCheckedRecaptcha: PropTypes.func.isRequired, // Function executed when successful recaptcha
  onExpiredRecaptcha: PropTypes.func.isRequired, // Function executed when expire recaptcha
};

export default Recaptcha;