import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import paypal from 'paypal-checkout';
import {getToken, isAuthenticated} from "services/auth/index";
import PropTypes from "prop-types";
import "./styles/PaypalButton.scss";
import {
  BUTTON_PAYPAL_CUSTOM
} from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/CheckoutCreditCardForm/components/PayPalButton/constants/constants";
import classNames from "classnames/bind";
import {getCartSession, removeCartSession} from "services/store/index";
import {getBaseURL} from "services/api/index";

export class PaypalButton extends Component {
  constructor(props) {
    super(props);
    window.React = React;
    window.ReactDOM = ReactDOM;
    this.state = {
      orderId: null
    };

    this.payment = this.payment.bind(this);
    this.onAuthorize = this.onAuthorize.bind(this);
  }

  /**
   * Creation of payment.
   * @return {Promise} promise
   */
  payment() {
    const {billing_address, shipping_address} = this.props;
    const CREATE_PAYMENT_URL = `${getBaseURL()}management/shopping-carts/paypal/create-payment/${getCartSession()}/checkout/`;

    return paypal.request.post(CREATE_PAYMENT_URL, {
      'billing_address': billing_address,
      'shipping_address': shipping_address
    }, {
      headers: {
        'Authorization': isAuthenticated() ? `Token ${getToken()}` : null
      }
    }).then(data => {
      this.setState({
        orderId: data.order.id
      });
      return data.payment_id;
    }).catch(err => {
      console.log(err)
    });
  }

  /**
   * Execution of transaction.
   * @param {Object} data
   * @return {Promise} promise
   */
  onAuthorize(data) {
    const {orderId} = this.state;
    const {completedOrder} = this.props;
    const EXECUTE_PAYMENT_URL = `${getBaseURL()}management/shopping-carts/paypal/execute-payment/${getCartSession()}/checkout/`;

    const promise = paypal.request.post(EXECUTE_PAYMENT_URL, {
      payment_id: data.orderID,
      payer_id: data.payerID,
      order_id: orderId
    }, {
      headers: {
        'Authorization': isAuthenticated() ? `Token ${getToken()}` : null
      }
    }).then(data => {
      removeCartSession();
      completedOrder(data.order.uuid);
      // The payment is complete!
      // You can now show a confirmation message to the customer
    });

    return promise;
  }

  render() {
    const {disabled} = this.props;
    const PayPalButton = paypal.Button.driver('react', {React, ReactDOM});

    return (
        <div className="paypal-container">
          <div className={classNames({
            "overlay": disabled
          })}/>
          <PayPalButton
              style={BUTTON_PAYPAL_CUSTOM}
              env={process.env.REACT_APP_PAYPAL_ENV}
              commit={true} // Optional: show a 'Pay Now' button in the checkout flow
              onAuthorize={this.onAuthorize}
              payment={this.payment}/>
        </div>
    );
  }
}

PaypalButton.propTypes = {
  billing_address: PropTypes.number,
  shipping_address: PropTypes.number,
  completedOrder: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};
