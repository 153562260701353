import React from 'react';
import PropTypes from 'prop-types';
import ProductCondition from "utils/components/ProductCondition/index";
import {CANCELLED, RETURNED} from "scenes/MyAccount/constants/status";
import {NEW, USED} from "utils/constants/product-conditions";
import "./styles/ItemToBeReturned.scss";

const ItemToBeReturned = ({item, type}) => {
  return(
      <div className="info">
        <div className="photo">
          <img src={item.list_image_thumbnail}
               alt={item.product_name}/>
        </div>
        <div className="content">
          {
            (type === CANCELLED || type === RETURNED) && (
                <span>
                  {`${type}`}
                </span>
            )
          }
          <p>{item.item_name}</p>
          <p>
                <span className="cond">
                  <ProductCondition
                      condition={item.condition}/>
                </span>
            &nbsp;
            <span>${item.price}</span>
            <span className="qty">Qty: {item.quantity}</span>
          </p>
        </div>
      </div>
  )
}

ItemToBeReturned.propTypes = {
  item: PropTypes.shape({
    profile_picture: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    item_name: PropTypes.string.isRequired,
    condition: PropTypes.oneOf([NEW, USED]).isRequired
  }),
  type: PropTypes.oneOf([RETURNED, CANCELLED])
}

export default ItemToBeReturned;
