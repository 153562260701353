import { connect } from 'react-redux';
import AdvancedSearch from "../index";
import {receiveAdvancedSearch} from "scenes/Store/actions/index";

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveAdvancedSearch: advancedSearch => dispatch(receiveAdvancedSearch(advancedSearch))
  };
};

const AdvancedSearchContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvancedSearch);

export default AdvancedSearchContainer;