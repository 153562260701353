import createBrowserHistory from 'history/createBrowserHistory';
import queryString from 'query-string';

const vintageHistory = createBrowserHistory();

// Taken from https://stackoverflow.com/a/38340730/923323
const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => (obj[key] === null || obj[key] === undefined || obj[key] === '') && delete obj[key]);
  return obj;
};

/**
 * Retrieves parsed query params in current location
 * @return {Object} queryParams
 */
export const getParsedQueryParams = () => {
  return queryString.parse(vintageHistory.location.search);
};

/**
 * Update query params in current path.
 * @param {Object} queryParams
 */
export const updateQueryParams = (queryParams, redirectUrl = null) => {
  const currentParams = getParsedQueryParams();
  const newParams = Object.assign(currentParams, queryParams);
  const cleanedParams = removeEmpty(newParams);
  const paramsAsString = queryString.stringify(cleanedParams);
  vintageHistory.push({
    pathname: redirectUrl ? redirectUrl : vintageHistory.location.pathname,
    search: `?${paramsAsString}`
  });
};

/**
 * Clear the query params of url that don't matched with parameters
 * @param {Array} parameters
 * @return {Object} queryParams
 */
export const clearQueryParamFromUrl = (parameters) => {
  let queryParams = getParsedQueryParams();
  Object.keys(queryParams).forEach(queryParam => {
    if(!parameters.find(parameter => parameter === queryParam))
      delete queryParams[queryParam];
  });

  return queryParams;
};

export default vintageHistory;