import * as types from '../actions/types';
import {DEFAULT_BY_BILLING_AND_SHIPPING} from "scenes/MyAccount/scenes/AddressBook/components/Address/constants/index";

export const initialState = {
  orders: null,
  selectedOrder: null,
  addresses: null,
  selectedAddress: null,
  creditCards: null,
  wishListItems: null,
  selectedCreditCard: null
};

export const myAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ORDERS_RECEIVE:
      return {
        ...state,
        orders: action.orders
      };
    case types.ORDER_SELECT:
      return {
        ...state,
        selectedOrder: action.order
      };
    case types.ORDERS_CLEAR:
      return {
        ...state,
        orders: null
      };
    case types.ORDER_ITEM_TOGGLE_RETURN_FLAG:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          order_items: state.selectedOrder.order_items.map(item => {
            if (item.id === action.item.id)
              item.willBeReturned = !item.willBeReturned;
            return item;
          })
        }
      };
    case types.ORDER_ITEM_UPDATE_RETURN_REASON:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          order_items: state.selectedOrder.order_items.map(item => {
            if (item.id === action.item.id)
              item.returnReason = action.reason;
            return item;
          })
        }
      };
    case types.ORDER_ITEMS_TO_BE_RETURNED_VALIDATE:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          order_items: state.selectedOrder.order_items.map(item => {
            item.hasReturnError = item.willBeReturned === true && !item.returnReason;
            return item;
          })
        }
      };
    case types.ADDRESSES_RECEIVE:
      return {
        ...state,
        addresses: action.addresses
      };
    case types.ADDRESS_SELECT:
      return {
        ...state,
        selectedAddress: action.address
      };
    case types.ADDRESS_DESELECT:
      return {
        ...state,
        selectedAddress: null
      };
    case types.ADDRESS_ADD_OR_UPDATE:
      let addressExists = false;
      const addresses = state.addresses.map(address => {
        if (action.address.id === address.id) {
          addressExists = true;
          return action.address;
        }
        return address;
      });
      return {
        ...state,
        addresses: addressExists ? addresses : [
          ...addresses,
          action.address
        ]
      };
    case types.ADDRESS_REMOVE:
      return {
        ...state,
        addresses: state.addresses.filter(address => address.id !== action.address.id)
      };
    case types.CREDIT_CARDS_RECEIVE:
      return {
        ...state,
        creditCards: action.creditCards
      };
    case types.CREDIT_CARD_SELECT:
      return {
        ...state,
        selectedCreditCard: action.creditCard
      };
    case types.EMPTY_ACCOUNT_INFORMATION:
      return {
        ...state,
        creditCards: null,
        addresses: null,
        selectedCreditCard: null,
        selectedAddress: null
      };
    case types.CREDIT_CARD_DESELECT:
      return {
        ...state,
        selectedCreditCard: null
      };
    case types.CREDIT_CARD_ADD_OR_UPDATE:
      let cardExists = false;
      const creditCards = state.creditCards.map(creditCard => {
        if (action.creditCard.id === creditCard.id) {
          cardExists = true;
          return action.creditCard;
        }
        return creditCard;
      });
      return {
        ...state,
        creditCards: cardExists ? creditCards : [
          ...creditCards,
          action.creditCard
        ]
      };
    case types.CREDIT_CARD_REMOVE:
      return {
        ...state,
        creditCards: state.creditCards.filter(creditCard => creditCard.id !== action.creditCard.id)
      };
    case types.WISH_LIST_ITEMS_RECEIVE:
      return {
        ...state,
        wishListItems: action.wishListItems
      };
    case types.WISH_LIST_ITEM_REMOVE:
      return {
        ...state,
        wishListItems: state.wishListItems.filter(wishListItem => wishListItem.id !== action.wishListItem.id)
      };
    case types.CREDIT_CARD_SET_AS_DEFAULT:
      return {
        ...state,
        creditCards: state.creditCards.map(creditCard => {
          creditCard.default = creditCard.id === action.creditCard.id;
          return creditCard;
        })
      };
    case types.ADDRESS_BOOK_SET_AS_DEFAULT:
      return {
        ...state,
        addresses: state.addresses.map(addressBook => {
          if (addressBook.id === action.addressBook.id) {
            addressBook.default = true;
            addressBook.address_type = action.addressBook.address_type;
          } else {
            if (addressBook.default && (
                    action.addressBook.address_type === DEFAULT_BY_BILLING_AND_SHIPPING
                    || action.addressBook.address_type === addressBook.address_type
                    || (action.addressBook.address_type !== DEFAULT_BY_BILLING_AND_SHIPPING
                    && addressBook.address_type === DEFAULT_BY_BILLING_AND_SHIPPING)))
              addressBook.default = false;
          }

          return {
            ...addressBook
          };
        })
      };
    case types.ORDER_ITEMS_VALIDATE_RETURN_REASON:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          items: {
            ...state.selectedOrder.items,
            normal: state.selectedOrder.items.normal.map(item => {
              const itemToBeReturnedIndex = action.itemsToBeReturned.findIndex(itemToBeReturned => {
                return item.order_item_data.id === itemToBeReturned.item.id
              });
              if (itemToBeReturnedIndex > -1) {
                item.order_item_data.retunReason = action.itemsToBeReturned[itemToBeReturnedIndex].returnReason;
                item.order_item_data.hasReturnError = action.itemsToBeReturned[itemToBeReturnedIndex].hasReturnError;
              }
              return item
            })
          }
        }
      };
    default:
      return state;
  }
};
