import {connect} from "react-redux";
import WishlistHeartBtn from "../index";
import {addWishListItem, removeWishListItem} from "scenes/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addWishListItem: wishListItem => dispatch(addWishListItem(wishListItem)),
    removeWishListItem: wishListItem => dispatch(removeWishListItem(wishListItem))
  };
};

const WishlistHeartBtnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WishlistHeartBtn);

export default WishlistHeartBtnContainer;