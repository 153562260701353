import React, {Component} from 'react';
import PropTypes from "prop-types";
import vintageAxios from "services/api/index";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import {Link} from "react-router-dom";
import queryString from 'querystring';
import {VIEW_TYPE} from "scenes/Store/scenes/ProductsList/constants/query-params";

class HomeProducts extends Component {
  constructor() {
    super();

    this.state = {
      products: null,
      page_size: 4
    };

    this.handleProductClick = product => this._handleProductClick.bind(this, product);
  }

  /**
   * Stores product selection in Redux state.
   */
  _handleProductClick (product){
    this.props.selectProduct(product);
  };

  /**
   * Get products from the API depending on the section
   * @returns {AxiosPromise}
   */
  fetchProducts(){
    const params = {
      page_size: this.state.page_size,
      [VIEW_TYPE]: this.props.section.categoryParam[VIEW_TYPE]
    };
    const promise = vintageAxios.get(this.props.section.url, {
      params: params
    });
    promise.then(response => {
      this.setState({
        products: response.data.results
      });
    });
    return promise;
  }

  componentDidMount(){
    this.fetchProducts()
  }

  render() {
    const {section} = this.props;
    const {products} = this.state;

    return(
        <div>
          <div className="featured-products__header">
            <h2>
              <span className="text-uppercase">
                {section.title}
              </span>
              <Link
                  to={`/store/products?${queryString.stringify(section.categoryParam)}`}>
                <p>View all</p>
              </Link>
            </h2>
          </div>
          <div className="featured-products__body">
            <ul className="products-list">
              {
                products === null ? (
                    <LoadingCassette/>
                ) : (
                    products.map(product =>
                        <li className="product" key={`HomeProduct${product.id}`}>
                          <Link
                              to={`/store/products/${product.slug}`}
                              onClick={this.handleProductClick(product)}>
                            <div className="product__wrapper">
                              <div className="product__photo">
                                <img src={product.list_image_thumbnail} alt={product.name}/>
                              </div>
                              <div className="product__info">
                                <h2>{product.name}</h2>
                                <div className="product__info__price">
                                  {
                                    product.price_new > 0 &&
                                    <div className="left">
                                      <span className="text">
                                        NEW
                                      </span>
                                      <span className="value">
                                        ${product.price_new}
                                      </span>
                                    </div>
                                  }
                                  {
                                    product.price_used > 0 &&
                                    <div className="right">
                                      <span className="text">
                                        USED
                                      </span>
                                      <span className="value">
                                        ${product.price_used}
                                      </span>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </Link>
                        </li>
                    )
                )
              }
            </ul>
          </div>
        </div>
    )
  }
}

HomeProducts.propTypes = {
  section: PropTypes.object.isRequired
};

export default HomeProducts;