import {connect} from 'react-redux';
import AddCreditCardModal from "../index";
import {
  addOrUpdateAddress,
  addOrUpdateCreditCard, deselectCreditCard, receiveAddresses,
  setCreditCardAsDefault
} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deselectCreditCard: () => dispatch(deselectCreditCard()),
    addOrUpdateCreditCard: creditCard => dispatch(addOrUpdateCreditCard(creditCard)),
    setCreditCardAsDefault: creditCard => dispatch(setCreditCardAsDefault(creditCard)),
    receiveAddresses: addresses => dispatch(receiveAddresses(addresses)),
    addOrUpdateAddress: address => dispatch(addOrUpdateAddress(address))
  };
};

const AddCreditCardModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCreditCardModal);

export default AddCreditCardModalContainer;