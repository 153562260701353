import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderInput, renderSelect, renderTextarea} from "utils/forms/renderers";
import {email, required} from "utils/forms/validators";
import PropTypes from "prop-types";
import Select from "react-select";
import vintageAxios from "services/api";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";
import "./styles/ContactForm.scss"

export class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCity: '',
      cityPlaceholder: 'Type and select your city...',
      locations: [],
      showLocationInput: false
    };

    this.handleCityInputChange = this.handleCityInputChange.bind(this);
    this.handleMessageTypeChange = this.handleMessageTypeChange.bind(this);
  }

  componentDidMount() {
    this.fetchLocations();
  }

  /**
   * Fetches locations from the API in order to render the select
   */
  fetchLocations() {
    const promise = vintageAxios.get('/misc/locations/');
    promise.then(response => {
      this.setState({
        locations: response.data
      });
    });
    return promise;
  }

  /**
   * Handles changes on the city autocomplete input element
   * @param {String} city
   */
  handleCityInputChange(city) {
    this.setState({
      selectedCity: city
    });
    this.props.changeFieldValue('city', city.id);
  }

  handleMessageTypeChange(event) {
    this.setState({
      showLocationInput: event.target.value === 'store'
    })
  }

  /**
   * Get cities from the API, returns an empty promise if no input.
   * @param {String} input
   * @returns {*}
   */
  getCities(input) {
    if (!input) {
      return Promise.resolve({options: []});
    }

    const queryParams = {'search': input};
    return vintageAxios.get('/misc/city-list/', {
      params: queryParams
    })
        .then((response) => {
          return {options: response.data}
        });
  }

  render() {
    const {errors, isLoading, handleSubmit} = this.props;
    const {locations, showLocationInput} = this.state;

    return (
        <form id="contact-form" className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name <span className="required">*</span></label>
            <Field id="name"
                   name="name"
                   component={renderInput}
                   type="text"
                   className="form-control"
                   validate={[required]}
                   placeholder="Type your name"
                   autocomplete='name'/>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email <span className="required">*</span></label>
            <Field id="email"
                   name="email"
                   component={renderInput}
                   type="email"
                   className="form-control"
                   validate={[required, email]}
                   placeholder="Type your email address"
                   autocomplete='email'/>
            {errors.email && errors.email.map((msj, index) => (
                <span className="help-block" key={`vintageContactFormEmailError${index}`}>{msj}</span>
            ))}
          </div>
          <div className="form-group">
            <label htmlFor="phone_number">Phone Number <span className="required">*</span></label>
            <Field id="phone_number"
                   name="phone_number"
                   component={renderInput}
                   type="text"
                   className="form-control"
                   validate={[required]}
                   placeholder="Type your phone number"
                   autocomplete='phone'/>
          </div>
          <div className="form-group">
            <label htmlFor="city">City <span className="required">*</span></label>
            <Select.Async
                multi={false}
                name="city_autocomplete"
                id={"city"}
                value={this.state.selectedCity}
                onChange={this.handleCityInputChange}
                valueKey="id"
                labelKey="autocomplete_text"
                inputProps={{autoComplete: 'address-level2', autoCorrect: 'off', spellCheck: 'off'}}
                placeholder={this.state.cityPlaceholder}
                loadOptions={this.getCities}
                style={{border: "2px solid #D3D9DB", color: "#4E5A61"}}/>
            <Field id="city"
                   name="city"
                   component={renderInput}
                   type="number"
                   className="form-control hidden"
                   validate={[required]}/>
          </div>
          <div className="form-group">
            <label htmlFor="message_type">Message Type <span className="required">*</span></label>
            <Field
                name="message_type"
                component={renderSelect}
                onChange={this.handleMessageTypeChange}
                className="form-control"
                validate={[required]}>
              <option value="">Select an option</option>
              <option value="purchase">Questions about a Website Purchase</option>
              <option value="store">Store Comment/Question</option>
              <option value="general">General Comment/Question</option>
            </Field>
          </div>
          {
            showLocationInput &&
            <div className="form-group">
              <label htmlFor="store">Location <span className="required">*</span></label>
              <Field
                  name="store"
                  component={renderSelect}
                  className="form-control"
                  validate={[required]}
                  disabled={locations.length === 0}>
                <option value="">{locations.length === 0 ? 'Loading locations...' : 'Select a location'}</option>
                {
                  locations.map(location =>
                      <option
                          key={location.id}
                          value={location.id}>
                        {location.name}
                      </option>
                  )
                }
              </Field>
            </div>
          }
          <div className="form-group">
            <label htmlFor="message">Comment</label>
            <Field id="message"
                   name="message"
                   component={renderTextarea}
                   type="number"
                   className="form-control"
                   validate={[required]}/>
          </div>
          {/*<Recaptcha
              afterCheckedRecaptcha={this.props.afterCheckedRecaptcha}
              onExpiredRecaptcha={this.props.onExpiredRecaptcha}/>*/}
          <br/>
          <div className="form-group">
            {
              isLoading ? (
                  <input
                      type="submit"
                      value="SENDING..."
                      disabled={true}/>
              ) : (
                  <input
                      type="submit"
                      value="SEND"
                      disabled={!this.props.checkedRecaptcha}/>
              )
            }
          </div>
        </form>
    )
  }
}

ContactForm.propTypes = {
  errors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const ContactReduxForm = reduxForm({
  form: 'vintageContactForm'
})(ContactForm);


export default withRecaptcha(ContactReduxForm)