import React, {PureComponent} from 'react';

class NotFound extends PureComponent {
    render() {
        return (
            <div>
                <br/>
                <section className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="wrapper empty-message not-found">
                                    <h1>Page not found!</h1>
                                    <p>The page you were looking for doesn&apos;t exist or has been moved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default NotFound;