import { connect } from 'react-redux';
import Success from "../index";
import {emptyCart} from "scenes/Store/actions/index";

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    emptyCart: () => dispatch(emptyCart())
  };
};

const SuccessContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Success);

export default SuccessContainer;