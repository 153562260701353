import React, {useCallback, useEffect, useState} from "react";
import vintageAxios from "services/api";
import useQueryParams from "hooks/useQueryParams"
import {QUERY_PARAM_CATEGORIES} from "scenes/Store/scenes/ProductsList/constants/query-params"
import {useHistory} from "react-router"
import { findIndex } from "lodash";

export default function CategoriesSelect({onChange}) {
  const queryParams = useQueryParams()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [selected, setSelected] = useState(null)
  const memoizedFetchCategories = useCallback(fetchCategories, [])
  const memoizedOnChange = useCallback(onChange, [])

  /**
   * Fetch categories the first time
   */
  useEffect(() => {
    if (loading && categories.length === 0)
      memoizedFetchCategories()
  }, [loading, categories, memoizedFetchCategories])

  /**
   * Call the provided callback when select changes
   */
  useEffect(() => {
    if (selected) {
      const category = JSON.parse(selected)
      memoizedOnChange(category)

      if (queryParams.find(QUERY_PARAM_CATEGORIES)) {
        queryParams.update({
          [QUERY_PARAM_CATEGORIES]: category.id
        });
        history.push({
          pathname: "/store/products",
          search: queryParams.asSearchString
        })
      }
    }
  }, [selected, memoizedOnChange])

  /**
   * Fetch categories from the API.
   */
  function fetchCategories() {
    setLoading(true)
    const promise = vintageAxios.get('/products/categories/')
    promise.then(({data}) => {
      const categories = data
          .filter(item => item.parent === null || item.parent.name === "Products")
          .sort((a, b) => a.product_type - b.product_type)
          .map(category => {
            if (category.name === "Products")
              category.name = "All"
            return category
          })
      setCategories(categories)
      const currentCategoryId = Number(queryParams.find('categories'));
      const currentCategorySelected = categories.find((category)=> category.id === currentCategoryId) ?? categories[0];
      setSelected(JSON.stringify(currentCategorySelected)) 
      setLoading(false)
    })
  }

  /**
   * Handles change on the select
   * @param event {Object}
   */
  function handleChange(event) {
    setSelected(event.target.value)
  }
  

  return (
      <select
          name="category"
          className="form-control"
          disabled={loading}
          onChange={handleChange}
          aria-label="categories select"
          value={selected}>
        {
          categories.map(category => (
              <option
                  key={category.id}
                  className="search-bar__select-wrapper__option"
                  value={JSON.stringify(category)}>
                {category.name}
              </option>
          ))
        }
      </select>
  )
}