import { connect } from 'react-redux';
import MyAccount from "../index";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const MyAccountContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyAccount);

export default MyAccountContainer;