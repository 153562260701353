import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import {useHistory} from "react-router";

export default function Suggestion({suggestion, onAfterSuggestionClick}) {
  const history = useHistory()

  /**
   * Handles click on the suggestion
   * @param event
   */
  const handleSuggestionClick = (event) => {
    if (suggestion.id) {  // Suggestions with null id are headers
      onAfterSuggestionClick()
      history.push({
        pathname: `/store/products/${suggestion.slug}`
      });
    }
  };

  return (
      <li
          className={classNames({
            "suggestion": true,
            "suggestion--header": suggestion.id === null
          })}
          onClick={handleSuggestionClick}>
        {suggestion.name}
      </li>
  )
}

Suggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  onAfterSuggestionClick: PropTypes.func.isRequired
}