import React, {useState} from "react";
import {Panel, PanelGroup} from "react-bootstrap"
import {NEW, USED} from "utils/constants/product-conditions"
import {useHistory, useLocation} from "react-router"
import QueryParams from "utils/QueryParams"
import {QUERY_PARAM_PRODUCT_CONDITION} from "scenes/Store/scenes/ProductsList/constants/query-params"
import FiltersBox from "scenes/Store/scenes/FiltersListV2/components/FiltersBox"

export default function ConditionsFilter() {
  const location = useLocation()
  const history = useHistory()
  const queryParams = new QueryParams(location.search)
  const conditionParam = queryParams.find(QUERY_PARAM_PRODUCT_CONDITION)
  const [selectedCondition, setSelectedCondition] = useState(conditionParam ? parseInt(conditionParam) : null)

  const conditions = [
    {
      name: "All",
      slug: ""
    },
    {
      name: "New",
      slug: NEW
    },
    {
      name: "Used",
      slug: USED
    }
  ]

  function handleChange(condition) {
    setSelectedCondition(condition.slug)
    queryParams.update({
      [QUERY_PARAM_PRODUCT_CONDITION]: condition.slug.toString()
    })
    history.push({
      pathname: location.pathname,
      search: queryParams.asSearchString
    })
  }

  return (
      <FiltersBox
          title="New or Used">
        {
          conditions.map(condition => (
              <div
                  key={condition.slug}>
                <div>
                  <input
                      type="radio"
                      id={condition.name}
                      onChange={() => handleChange(condition)}
                      name="productStatus"
                      value={condition.slug}
                      checked={selectedCondition === condition.slug}/>
                  <label htmlFor={condition.name}>
                    <span className="text">&nbsp;&nbsp;{condition.name}</span>
                  </label>
                </div>
              </div>
          ))
        }
      </FiltersBox>
  )
}