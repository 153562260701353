import { connect } from 'react-redux';
import ReturnItemsForm from "../index";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer,
    returnItemsForm: state.form.returnItemsForm
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const ReturnItemsFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReturnItemsForm);

export default ReturnItemsFormContainer;