import {NEW, USED} from "utils/constants/product-conditions";

/**
 * For some reason as the time happened we ended up with the API receiving condition
 * param as a string but retrieving it as an integer.
 * This function pretends to be an interface to always treat condition as an integer,
 * as it was supposed to be forever.
 * @param {String|Number} condition
 */
export function parseCondition(condition) {
  let parsedCondition;

  if (!isNaN(condition))
    condition = parseInt(condition, 10);
  else
    condition = condition.toLowerCase();

  switch (condition) {
    case NEW:
      parsedCondition = NEW;
      break;
    case USED:
      parsedCondition = USED;
      break;
    case "new":
      parsedCondition = NEW;
      break;
    case "used":
      parsedCondition = USED;
      break;
    default:
      parsedCondition = null;
      break;
  }

  return parsedCondition;
}
