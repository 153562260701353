import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import {NEW, USED} from "utils/constants/product-conditions";
import "./styles/ProductPrice.scss";

const ProductPrice = ({condition, priceNew, priceUsed}) => (
    <span className={classNames({
      "product-price": true,
      "product-price--new": condition === NEW,
      "product-price--used": condition === USED,
    })}>
      ${condition === NEW ? priceNew : priceUsed}
    </span>
);

ProductPrice.propTypes = {
  condition: PropTypes.number.isRequired,
  priceNew: PropTypes.string.isRequired,
  priceUsed: PropTypes.string.isRequired
};

export default ProductPrice;
