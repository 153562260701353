const ASK1 = "<p>We love buying your stuff! DVDs, blu-rays, CDs, " +
    "LPs, video games, video game systems & accessories, comics, sports and game cards, collectable toys and " +
    "memorabilia: new or vintage, recent or classic, visit us to get cash for your stuff. Contact the " +
    "<a href=\"https://www.vintagestock.com/locations/\">store nearest you </a>for more information including " +
    "pricing and item stock.</p>";

const ASK3 = "<p>We do buy books at our Joplin, MO stores, the V·Stock locations in St. Louis, and most of the " +
    "EntertainMART locations. To contact them, check " +
    "our <a href=\"https://www.vintagestock.com/locations/\">locations page</a>.</p>";

const ASK4 = "<p>We are very glad you joined the Preferred Customer Newsletter! You should receive a confirmation email " +
    "within 30 days of signing up. If you don't start receiving a monthly mailing, you may have entered an invalid " +
    "email address, or our newsletter may have been rejected by your email settings. " +
    "<a href=\"https://www.vintagestock.com/contacts/\">Contact us </a>for more help.</p>";

const ASK5 = "<p>We strive to pay you the highest possible amount for your items, with a whopping 50% more in store " +
    "credit. Unfortunately, we cannot give quotes until we see the items. You must call your local Vintage Stock or " +
    "Movie Trading Co. for buying information. Visit a <a href=\"https://www.vintagestock.com/locations/\">store near you </a>" +
    "to get paid for your stuff! </p>";

const ASK6 = "<p>We love <a href=\"https://www.vintagestock.com/store/products?categories=32\">games!</a> You'll find classics and new " +
    "releases from one side of the store to the other. We carry every video game system from the vintage Atari and " +
    "Nintendo consoles to the latest Switch, PS4, & Xbox One, plus handheld systems like the Nintendo 3DS. We have " +
    "tons of great games for all of these systems. </p>";

const ASK7 = "<p>We sell new <a href=\"https://www.vintagestock.com/store/products?categories=27\">computer games</a> " +
    "in our online store, but due to copyright laws and pirating, we don’t carry any computer games in our " +
    "brick-and-mortar stores. </p>";

const ASK8 = `<p>Bring your stuff into your local ${process.env.REACT_APP_SITE_NAME}. They will take a look at it and\n` +
    "give you a Cash and Trade value. Typically, the Trade value is 50% more than the Cash value\n" +
    "(for example, a Cash value of $1.00 would be a Trade value of $1.50).</p>";

const ASK9 = "<p>Lost gift card or check? No problem. We can replace any gift card by canceling the original and\n" +
    "issuing a new one. You must have the original issuing receipt to obtain a new card. We can replace\n" +
    "any check issued as payout minus a stop payment and reissue fee. We are not responsible for lost or\n" +
    "stolen checks. You must have your original issuing receipt to obtain a new check.</p>";

const ASK10 = "<p>Want a great job full of fun, tons of opportunities, and product discounts? " +
    "Check out our <a href=\"https://www.vintagestock.com/about/now-hiring\">Career Opportunities page</a> " +
    "for more info.</p>";

const ASK11 = "<p>Are you excited about movies, video games, music, comics, cards or giving great customer service?\n" +
    "You should work for us! You must be at least 16 to work in our stores. We are looking for great people\n" +
    "to join our team.</p>";

const ASK12 = "<p id='return-policy'>We want you to love your item and will guarantee that it will work as described. We have extended\n" +
    "our return policy to include items with a receipt within 30 days of purchase. Defective items will be\n" +
    "exchanged for replacement. If no reasonable exchange can be made, a refund may be issued. Please\n" +
    "<a href=\"/about/return-policy\">click here</a> to see our full return policy.</p>";

const ASK13 = "<p>Yes, we do carry new comics in most of our stores. They typically go on sale on Wednesday each week. " +
    "We do offer subscriptions, to make sure the titles you want to read are ordered and held for you. Subscribers " +
    "even get a discount on new comics and ALL back issues! If you would like to be a subscriber and take advantage " +
    "of the discounts and other specials, contact your local store.</p>";

const ASK14 = "<p>“Cooler than Cash” cards make a great gift! We do offer " +
    "<a href=\"https://www.vintagestock.com/gift-cards\">gift cards</a> that can be used for purchases at our " +
    "physical locations. At this time, they cannot be used on our website. Gift cards can also be purchased in any " +
    "amount at any of our stores.</p>";

const ASK15 = "<p>Sign up and we'll let you know about special events, sales, new store openings, and more via email.\n" +
    "You'll receive coupons, sale exclusives, event announcements, and more! We do not disclose information\n" +
    "to any third party sites. We send out a weekly movie announcement and a monthly update or coupon.\n" +
    "<a href=\"https://www.vintagestock.com/auth/signup\">Try it today and start saving!</a></p>";

const ASK16 = "<p>Pick up that new release when you want it, or relive the fun of that retro video game! At all of our " +
    "stores, you can rent almost any movie, TV show, or video game in stock. And, if you decide to keep the movie or " +
    "game you rented, we will deduct your rental fee from the purchase price.</p>";

const ASK17 = `<p>Movie Trading Co. is the Dallas-Fort Worth division of ${process.env.REACT_APP_SITE_NAME}. We mainly focus on movies, ` +
    "music and video games. We also offer rentals for movies and video games. If you live in the DFW Metroplex, " +
    "you can find your local Movie Trading Company on our <a href=\"https://www.vintagestock.com/locations/\">Locations page </a>.</p>";

const ASK18 = "<p>Didn’t find the answer you were looking for? " +
    "<a href=\"https://www.vintagestock.com/locations/\">Ask us here</a>, and we’ll be happy to answer your question " +
    "as quickly as we can.</p>";


export const FREQUENTLY_ASKED_QUESTIONS = [
  {
    header: `What items does ${process.env.REACT_APP_SITE_NAME} buy and sell?`,
    nodeHtml: ASK1
  },
  {
    header: "Do you still buy books?",
    nodeHtml: ASK3
  },
  {
    header: "Why haven't I received email after signing up online for the newsletter?",
    nodeHtml: ASK4
  },
  {
    header: "How much can I get for my stuff?",
    nodeHtml: ASK5
  },
  {
    header: "What video games do you carry?",
    nodeHtml: ASK6
  },
  {
    header: "Do you buy or sell computer games?",
    nodeHtml: ASK7
  },
  {
    header: "I want to trade in my stuff for something new. How does that work?",
    nodeHtml: ASK8
  },
  {
    header: "What do I do if I have lost my Cooler than Cash Card or selling payout check?",
    nodeHtml: ASK9
  },
  {
    header: `I want to work at ${process.env.REACT_APP_SITE_NAME}. How do I get a job?`,
    nodeHtml: ASK10
  },
  {
    header: `Is there an age requirement to work at ${process.env.REACT_APP_SITE_NAME}?`,
    nodeHtml: ASK11
  },
  {
    header: "What is the return policy?",
    nodeHtml: ASK12,
    anchor: 'return-policy'
  },
  {
    header: "Do you guys carry new comics? When do they usually hit the shelf? Do you have a subscriber program?",
    nodeHtml: ASK13
  },
  {
    header: "Do you have gift cards available?",
    nodeHtml: ASK14
  },
  {
    header: "What's the newsletter about?",
    nodeHtml: ASK15
  },
  {
    header: `Does ${process.env.REACT_APP_SITE_NAME} offer rentals?`,
    nodeHtml: ASK16
  },
  {
    header: "What's Movie Trading Company?",
    nodeHtml: ASK17
  },
  {
    header: "Additional Questions?",
    nodeHtml: ASK18
  }
];
