import { reducer as formReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import {storeReducer} from "scenes/Store/reducer/index";
import {authReducer} from "scenes/Auth/reducer/index";
import {myAccountReducer} from "scenes/MyAccount/reducer/index";
import {routerMiddleware, routerReducer} from "react-router-redux";
import vintageHistory from "services/browser-history/index";
import {checkoutReducer} from "scenes/Store/scenes/Checkout/reducer/index";
import {mainMenuReducer} from "components/MainMenu/reducer/index";

const combinedReducers = combineReducers({
  form: formReducer,
  routerReducer,
  authReducer,
  storeReducer,
  myAccountReducer,
  checkoutReducer,
  mainMenuReducer
});

const reduxRouterMiddleware = routerMiddleware(vintageHistory);

const reduxStore = createStore(
    combinedReducers,
    applyMiddleware(
        thunkMiddleware,
        reduxRouterMiddleware
    )
);

export default reduxStore