import React, {useEffect, useState} from "react";
import {Panel, PanelGroup} from "react-bootstrap"
import QueryParams from "utils/QueryParams"
import {useHistory, useLocation} from "react-router"
import debounce from "lodash/debounce"
import {
  PRICE_NEW_GTE,
  PRICE_NEW_LTE, PRICE_USED_GTE, PRICE_USED_LTE,
  QUERY_PARAM_PRODUCT_CONDITION
} from "scenes/Store/scenes/ProductsList/constants/query-params"
import {conditions} from '@vintagestock/vintage-common-components'
import FiltersBox from "scenes/Store/scenes/FiltersListV2/components/FiltersBox"

function executeCallback(callback) {
  callback()
}

const debouncedExecuteCallback = debounce(executeCallback, 500)

export default function PriceFilter() {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new QueryParams(location.search)
  const [min, setMin] = useState(queryParams.find(PRICE_NEW_GTE) || queryParams.find(PRICE_USED_GTE) || null)
  const [max, setMax] = useState(queryParams.find(PRICE_NEW_LTE) || queryParams.find(PRICE_USED_LTE) || null)

  useEffect(() => {
    debouncedExecuteCallback(updateQueryParams)
  }, [min, max])

  useEffect(() => {
    updateQueryParams()
  }, [location.search])

  function updateQueryParams() {
    let newQueryParams = {};
    const conditionParam = queryParams.find(QUERY_PARAM_PRODUCT_CONDITION)
    const condition = conditionParam ? parseInt(conditionParam) : null

    switch (condition) {
      case conditions.NEW:
        newQueryParams[PRICE_NEW_GTE] = min;
        newQueryParams[PRICE_USED_GTE] = null;
        newQueryParams[PRICE_NEW_LTE] = max;
        newQueryParams[PRICE_USED_LTE] = null;
        break;
      case conditions.USED:
        newQueryParams[PRICE_NEW_GTE] = null;
        newQueryParams[PRICE_USED_GTE] = min;
        newQueryParams[PRICE_NEW_LTE] = null;
        newQueryParams[PRICE_USED_LTE] = max;
        break;
      default:
        newQueryParams[PRICE_NEW_GTE] = min;
        newQueryParams[PRICE_USED_GTE] = min;
        newQueryParams[PRICE_NEW_LTE] = max;
        newQueryParams[PRICE_USED_LTE] = max;
        break;
    }

    queryParams.update(newQueryParams);
    history.push({
      pathname: location.pathname,
      search: queryParams.asSearchString
    })
  }

  function handleInputChange(event) {
    const {value, name} = event.target
    if (name === "price_min")
      setMin(value)
    else
      setMax(value)
  }

  return (
      <FiltersBox
          title="Price">
        <div className="row">
          <div className="col-xs-6">
            <div className="form-group">
              <input
                  type="number"
                  name="price_min"
                  placeholder="Min"
                  className="form-control"
                  value={min}
                  onChange={handleInputChange}/>
            </div>
          </div>
          <div className="col-xs-6">
            <div className="form-group">
              <input
                  type="number"
                  name="price_max"
                  placeholder="Max"
                  className="form-control"
                  value={max}
                  onChange={handleInputChange}/>
            </div>
          </div>
        </div>
      </FiltersBox>
  )
}