import { connect } from 'react-redux';
import ProductDetails from "../index";
import {selectProduct, deselectProduct} from "scenes/Store/actions/index";

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectProduct: product => dispatch(selectProduct(product)),
    deselectProduct: () => dispatch(deselectProduct())
  };
};

const ProductDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails);

export default ProductDetailsContainer;