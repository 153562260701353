import { connect } from 'react-redux';
import AddToWishListButton from "../index";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const AddToWishListButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddToWishListButton);

export default AddToWishListButtonContainer;