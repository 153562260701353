import {connect} from "react-redux";
import MainMenuBlocker from "../index";
import {setMainMenuIsOpen} from "components/MainMenu/actions/index";

const mapStateToProps = (state) => {
  return {
    mainMenuReducer: state.mainMenuReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainMenuIsOpen: mainMenuIsOpen => dispatch(setMainMenuIsOpen(mainMenuIsOpen))
  };
};

const MainMenuBlockerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainMenuBlocker);

export default MainMenuBlockerContainer;