import React from 'react';
import PropTypes from "prop-types";
import AddressInfo from "utils/components/AddressInfo/index";
import {SHIPPING} from "scenes/Store/scenes/Checkout/scenes/SelectAddress/components/CheckoutAddressForm/components/CheckoutAddress/constants/address-types";

const CheckoutAddress = ({address, addressType, selectCheckoutShippingAddress, selectCheckoutBillingAddress, selectAddress, checkoutReducer}) => {
  const handleRadioChange = event => {
    if (addressType === SHIPPING)
      selectCheckoutShippingAddress(address.id);
    else
      selectCheckoutBillingAddress(address.id);
  };

  const handleEditClick = event => {
    selectAddress(address);
  };

  return (
      <div className="checkout-radio radio">
        <label
            className="checkout-radio__label"
            htmlFor={`address${address.id}`}>
          <input
              type="radio"
              name="address"
              id={`address${address.id}`}
              value={address.id}
              onChange={handleRadioChange}
              checked={checkoutReducer.selectedShippingAddressId === address.id}/>
          <AddressInfo
              address={address}/>
        </label>
        <p
            className="checkout-radio__actions">
          <button
              type="button"
              onClick={handleEditClick}>
            Edit
          </button>
        </p>
      </div>
  );
};

CheckoutAddress.propTypes = {
  address: PropTypes.object.isRequired,
  addressType: PropTypes.string.isRequired
};

export default CheckoutAddress;