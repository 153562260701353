import React, {PureComponent} from 'react';
import Breadcrumb from "utils/components/Breadcrumb/index";
import "./styles/TermsOfUse.scss";
import {Link} from "react-router-dom"

class TermsOfUse extends PureComponent {
  render() {
    return (
        <div className="terms-of-use">
          <Breadcrumb
              crumbs={[
                {title: "About", to: "/about"},
                {title: "Terms of Use", to: "/about/terms-of-use"},
              ]}/>
          <div className="container">
            <div className="wrapper">
              <h1>
                Website Terms of Use
              </h1>
              <p>
                Last Modified: June 5, 2019
              </p>
              <h2>
                Acceptance
              </h2>
              <p>
                These Terms of Use (“Terms of Use”) are entered into by and between you,
                the user of this website (“you” or “your”) and Vintage Stock, Inc. (“VSI”,
                “we” or “us”) and govern your access to and use of VSI’s websites,
                including without limitation, www.vintagestock.com and
                www.movietradingcompany.com (each, a “Website”) and any accounts,
                applications, content, products, functionality, and services offered by VSI
                on or through a Website (collectively referred to as the “Services”).
              </p>
              <p>
                Please read these Terms of Use carefully. By accessing and using a Website
                or Services, you accept and agree to be bound and abide by these Terms of
                Use and our privacy policy for our Websites found at
                <a href="https://vintagestock.com/about/privacy-policy">
                  https://vintagestock.com/about/privacy-policy
                </a>
                (the “Privacy Policy”). If you do not agree to these Terms of Use or the
                Privacy Policy, you may not use any Website or Services. These Terms of Use
                and our Privacy Policy constitute the sole and entire agreement between you
                and VSI with respect to a Website and Services and supersede all prior and
                contemporaneous understandings, agreements, representations, and
                warranties, both written and oral, with respect to a Website and Services.
                As used in these Terms of Use, the words "including" and "include" mean
                "including, but not limited to."
              </p>
              <p>
                In reference to the Children’s Online Privacy Protection Act (“COPPA”),
                this Website and the collection of data through the use of a Website are
                not offered nor available to users who are 13 years of age or younger. By
                using any Website, you represent and warrant that you are not below the age
                of 13. In addition, if you are under the age of 18, you shall not make any
                purchases on or through the Websites, use any of the interactive or public
                comment features of the Websites, or provide any information about yourself
                to us, including your name, address, telephone number, photos, videos,
                email address, any screen name or user name you may use, or any other
                personal information, without the express consent and participation of a
                guardian or parent. If you do not meet these requirements, you may not
                access, or use the Websites. Please see our Privacy Policy for more
                information on our Websites and COPPA.
              </p>
              <p>
                We may revise and update these Terms of Use and the Privacy Policy from
                time to time in our sole discretion. All changes are effective immediately
                when we post them and apply to all access to and use of any Website
                thereafter. Your continued use of a Website following the posting of such
                revisions means that you accept and agree to the changes. You are expected
                to check this page each time you access a Website so you are aware of any
                changes, as they are binding on you.
              </p>
              <p>
                VSI is a Missouri corporation based in the United States. We provide the
                Websites and Services for use only by persons located in the United States.
                We make no claims that the Services or any Website content is accessible or
                appropriate outside of the United States. Access to the Services and
                Websites may not be legal by certain persons or in certain countries. If
                you access the Services or Websites from outside the United States, you do
                so on your own initiative and are responsible for compliance with local
                laws.
              </p>
              <h2>
                Third Party Website and Content
              </h2>
              <p>
                A Website may be made available or accessed in connection with third party
                services and content, and third party services may be utilized in
                connection with a Website (including payment solutions) provided by third
                parties (“Third Party Providers”) that VSI does not control (including the
                collection of payment information). You acknowledge that different terms of
                use and privacy policies may apply to your use of such Third Party
                Providers’ services, websites, and content. VSI does not endorse such Third
                Party Providers’ services, websites, and content and in no event shall VSI
                be responsible or liable for any products or services of such Third Party
                Providers.
              </p>
              <p>
                If a Website contains links to other sites and resources provided by third
                parties, these links are provided for your convenience only. This includes
                links contained in advertisements, including banner advertisements and
                sponsored links. We have no control over the content of those sites or
                resources, and accept no responsibility for them or for any loss or damage
                that may arise from your use of them. If you decide to access any of the
                third party websites linked to this Website, you do so entirely at your own
                risk and subject to the terms and conditions of use for such websites.
              </p>
              <h2>
                Use of Website and User Accounts
              </h2>
              <p>
                Subject to your compliance with these Terms of Use, VSI grants you
                permission to access and use a Website solely for your own personal use as
                set forth in these Terms of Use and consistent with the intended features
                and functionality of a Website. All rights not expressly granted to you in
                these Terms of Use are reserved by VSI. You may not download, print, or
                store any of the content on a Website (“Site Content”) without the advance
                written permission of VSI or other copyright holder, as applicable, of such
                Site Content. VSI reserves and does not waive any and all intellectual
                property rights of VSI and/or its licensors and other rights holders in and
                to the Site Content. For clarity, Site Content does not include User
                Content (defined below) that you post on a Website.
              </p>
              <p>
                In order to use certain features of a Website, such as ordering products or
                posting comments, you may be required to register for an online account
                (“User Account”). You are responsible for maintaining the confidentiality
                of the user name and password for your User Account, and you acknowledge
                and agree that you are responsible for all activities performed using your
                User Account. You may not register for a User Account if you are under the
                age of 18 unless you do so with the consent and assistance of a parent or
                guardian. Your User Account is personal to you and may not be transferred
                to or used by any other person or entity. You consent to all actions we
                take with respect to your information, consistent with our Privacy Policy.
                You also agree to ensure that you exit from your User Account at the end of
                each session. We will not be liable to you for any unauthorized access to
                or use of your user name or password or any other breach of security.
              </p>
              <p>
                Your use of a Website shall not cause nuisance, annoyance, inconvenience,
                or property damage, whether to VSI, its customers, a Third Party Provider,
                or any other party. You agree to comply with all applicable laws when using
                a Website, and you may only use a Website for lawful purposes. In certain
                instances you may be asked to provide proof of identity to access or use a
                Website, and you agree that you may be denied access to or use of a Website
                if you refuse to provide acceptable proof of identity. We have the right to
                disable any user name, password, or other identifier, whether chosen by you
                or provided by us, at any time in our sole discretion for any or no reason,
                including if, in our sole judgment , you have violated any provision of
                these Terms of Use.
              </p>
              <h2>
                Promotional Codes, Gift Cards, Products
              </h2>
              <p>
                VSI may, in VSI’s sole discretion, create promotional codes that may be
                redeemed for products or other features or benefits related to a Website
                and/or VSI’s products and goods, subject to any additional terms that VSI
                establishes on a per promotional code basis ("Promo Codes"). You agree that
                Promo Codes: (i) must be used for the intended audience and purpose, and in
                a lawful manner; (ii) may not be duplicated, sold, or transferred in any
                manner, or made available to the general public (whether posted to a public
                form or otherwise), unless expressly permitted by VSI; (iii) may be
                disabled by VSI at any time for any reason without liability to VSI; (iv)
                may only be used pursuant to the specific terms that VSI establishes for
                such Promo Code; (v) are not valid for cash; and (vi) may expire prior to
                your use. VSI reserves the right to withhold or deduct credits or other
                features or benefits obtained through the use of Promo Codes by you or any
                other user in the event that VSI determines or believes that the use or
                redemption of the Promo Code was in error, fraudulent, illegal, or in
                violation of the applicable Promo Code terms or these Terms of Use. VSI’s
                “Cooler than Cash” cards may not be used in connection with any Promo Code.
              </p>
              <p>
                VSI may, in VSI’s sole discretion, issue or create gift cards or store
                value cards, including our “Cooler than Cash” cards (collectively, “Value
                Cards”) for use in VSI’s stores. In store, you may be able to load
                denominations of your choice on such Value Cards or in exchange for certain
                value of goods and products. Value Cards cannot be returned or redeemed for
                cash, except where required by law, and must be presented at the time of
                purchase. VSI’s “Cooler than Cash” cards may only be used in-store and may
                not be used for online purchases. Neither the Value Card nor its value can
                be replaced or restored if the Value Card is used without your permission,
                stolen, or altered. If a Value Card is lost or destroyed, VSI may, in its
                sole discretion, cancel the lost or destroyed Value Card and issue a
                replacement Value Card to you; provided, that, you have the original Value
                Card’s issuing receipt. Value Cards may expire prior to your use.
              </p>
              <p>
                VSI is not responsible for lost or stolen checks. We may, in our sole
                discretion, replace a check issued as payout, subject to a stop payment and
                reissue fee; provided, that, you have the original issuing receipt for your
                purchase.
              </p>
              <p>
                VSI sells videos and other products both in stores and online through a
                Website. Specific videos, videogames, CDs, and other products shown on this
                Website are not guaranteed to be in stock at any particular location.
                Please contact a VSI store directly for specific product availability. If
                you are not satisfied that your item is as described, please review our
                return policy, located here <a
                  href="https://vintagestock.com/about/return-policy">https://vintagestock.com/about/return-policy</a> (the
                “Return
                Policy”) for instructions regarding the return of the item you purchased.
                You agree that if you are not satisfied with any purchase, VSI’s refund of
                your purchase price in exchange for the return of such item to VSI, as
                stated in the Return Policy, is your sole and exclusive remedy from VSI.
              </p>
              <p>
                Occasionally, an item will be listed for sale on a Website that has already
                been sold. This may be caused by system or human delays in updating our
                databases after the item is sold. If you order an item that is not actually
                available (even if a Website says otherwise), your order will be canceled
                and your payment will be refunded, and you understand and agree that VSI
                shall have no obligation to provide you with the item. VSI attempts to be
                as accurate as possible. However, VSI does not warrant that product
                descriptions or other content of a Website is accurate, complete, reliable,
                current, or error-free. If a product offered by VSI itself is not as
                described, your sole remedy is to return it in unused condition.
              </p>
              <p>
                Although rare, it is possible that an item shipped via any shipping method
                could be lost in transit. If an item is not received within forty-five (45)
                days after the shipment date, please notify VSI customer service at
                417-206-0021. Claims for lost orders must be submitted to customer service
                no later than sixty (60) days from the date your item was shipped – after
                that date, VSI cannot be reasonably confident in its ability to track the
                shipment and you will forego the possibility of a refund or replacement.
                Please note that if your User Account information is incorrect or
                inaccurate, VSI will be unable to process your purchase and your purchased
                items may not be delivered, and VSI shall not be liable to you for any such
                incomplete, lost, or undelivered item.
              </p>
              <h2>
                Charges; Financial Data
              </h2>
              <p>
                You understand that use of a Website may result in charges to you for the
                products or goods you receive as well as charges for service fees incurred
                by VSI in providing a Website and its products and goods ("Charges").
                Charges may be inclusive of applicable taxes, where required by law.
                Charges paid are final and non-refundable, unless otherwise determined by
                VSI. All Charges are due immediately and payment will be facilitated by VSI
                and its agents using the means of payment associated with your User
                Account. If your User Account payment method is expired, invalid or
                otherwise not able to be charged, you agree that VSI and its agents may use
                a secondary payment method in your User Account, if available.
              </p>
              <h2>SMS Terms and Conditions</h2>
              <p>Vintage Stock SMS Terms and Conditions</p>
              <ol>
                <li>By texting to a Vintage Stock short code or by submitting your phone number in a web form, you consent to receive one or more automated texts at the phone number from which you texted or at the phone number you entered in the form.</li>
                <li>We will not be liable for any delays in the receipt of any SMS messages as delivery is subject to effective transmission from your mobile service operator. SMS MESSAGE SERVICES ARE PROVIDED ON AN “AS IS” BASIS, AND WE MAKE NO WARRANTY, EXPRESS OR IMPLIED, AND ALL WARRANTIES, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR ANY PARTICULAR PURPOSE, ARE HEREBY EXPRESSLY DISCLAIMED.</li>
                <li>Data obtained from you in connection with this SMS service may include your cell phone number, your carrier’s name, and the date, time and content of your messages, as well as other information that you provide. We may use this information to contact you and to provide the services you request from us.</li>
                <li>By subscribing, you consent to receive information about in store sales, or coupon offers for use online and in store. Up to 2 messages per month.</li>
                <li>By subscribing or otherwise using the service, you acknowledge and agree that we will have the right to change and/or terminate the service at any time, with or without cause and/or advance notice.</li>
                <li>To cancel your SMS subscriptions, text STOP to 88284 in reply to a text message you receive. You may receive a subsequent message confirming your opt-out request.</li>
                <li>For additional help, text HELP to 88284 in reply to a text message you receive.</li>
                <li>Message and Data Rates May Apply</li>
                <li>Text VINTAGESTOCK to 88284 to receive information about in store sales or coupon offers for use online and in store. from Vintage Stock (SMS Message).</li>
                {/*<li>Text SEEVINTAGESTOCK to 88284 to receive information about in store sales or coupon offers for use online and in store from Vintage Stock (MMS Message).</li>*/}
                <li>The mobile carriers are not liable for delayed or undelivered messages.</li>
                <li>United States Participating Carriers Include AT&T, T-Mobile®, Verizon Wireless, Sprint, Boost, U.S. Cellular®, MetroPCS®, InterOp, Cellcom, C Spire Wireless, Cricket, Virgin Mobile and others.</li>
              </ol>
              <p>
                Our privacy policy is at: <Link to="/about/privacy-policy">/about/privacy-policy</Link>
                <br/>
                Customer Support: <a href="mailto:support@vintagestock.com">support@vintagestock.com</a>
                <br/>
                Customer Support: <a href="tel:14172060021">417-206-0021</a>
              </p>
              <h2>
                Collection and Use of Your Information
              </h2>
              <p>
                You acknowledge that when you use a Website, VSI may use automatic means
                (including, for example, cookies and web beacons) to collect information
                about your IP addresses and about your use of a Website. All information we
                collect through or in connection with a Website is subject to our Privacy
                Policy. By using or providing information to or through a Website, you
                consent to all actions taken by us with respect to your information in
                compliance with the Privacy Policy.
              </p>
              <h2>
                User Provided Content
              </h2>
              <p>
                A Website may contain message and feedback boards, ratings, personal
                profiles, forums, user portfolios, product reviews, address books, and
                other interactive features (collectively, the "Interactive Website") that
                allow users to post, submit, publish, display, or transmit to other users
                or other persons (hereinafter "post") content or materials (collectively,
                "User Contributions") on or through a Website. All User Contributions must
                comply with the Content Standards set out in these Terms of Use.
              </p>
              <p>
                Any User Contribution you post through a Website will be considered
                non-confidential and non-proprietary. By providing any User Contribution to
                a Website, you grant us and our affiliates and service providers and each
                of their and our licensees, successors, and assigns the right to use,
                reproduce, modify, perform, display, distribute, and otherwise disclose to
                third parties any such material for any purpose according to your User
                Account settings.
              </p>
              <p>
                You represent and warrant that (a) you own or control all rights in and to
                the User Contributions and have the right to grant the license granted
                above to us and our affiliates and service providers, and each of their and
                our licensees, successors, and assigns; and (b) all of your User
                Contributions do and will comply with these Terms of Use. You understand
                and acknowledge that you are solely responsible for any User Contributions
                you submit or contribute, and you, not VSI, have full responsibility for
                such content, including its legality, reliability, accuracy, and
                appropriateness. We are not responsible, or liable to any third party, for
                the content or accuracy of any User Contributions posted by you or any
                other user of a Website.
              </p>
              <p>
                We have the right to (a) remove or refuse to post any User Contributions
                for any or no reason in our sole discretion; (b) take any action with
                respect to any User Contribution that we deem necessary or appropriate in
                our sole discretion, including if we believe that such User Contribution
                violates these Terms of Use, including the Content Standards, infringes any
                intellectual property right or other right of any person or entity,
                threatens the personal safety of users of a Website or the public, or could
                create liability for VSI; (c) disclose your identity or other information
                about you to any third party who claims that material posted by you
                violates their rights, including their intellectual property rights or
                their right to privacy; (d) take appropriate legal action, including
                without limitation, referral to law enforcement for any illegal or
                unauthorized use of a Website; and (e) terminate or suspend your access to
                all or part of a Website for any reason, including any violation of these
                Terms of Use.
              </p>
              <p>
                Without limiting the foregoing, we have the right to fully cooperate with
                any law enforcement authorities or court order requesting or directing us
                to disclose the identity or other information of anyone posting any
                materials through a Website. YOU WAIVE AND HOLD HARMLESS VSI AND ITS
                AFFILIATES, OTHER LICENSORS, AND OTHER SERVICE PROVIDERS FROM ANY CLAIMS
                RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR
                AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A
                CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
                AUTHORITIES.
              </p>
              <p>
                We cannot review all material before it is posted through a Website and
                cannot ensure prompt removal of objectionable material after it has been
                posted. Accordingly, we assume no liability for any action or inaction
                regarding transmissions, communications, or content provided by any user or
                third party. We have no liability or responsibility to anyone for
                performance or nonperformance of the activities described in this section.
              </p>
              <h2>
                Content Standards
              </h2>
              <p>
                These content standards (“Content Standards”) apply to any and all User
                Contributions and use of the Interactive Website. User Contributions must
                in their entirety comply with all applicable federal, state, local, and
                international laws and regulations. Without limiting the foregoing, User
                Contributions must not (a) contain any material which is defamatory,
                obscene, indecent, abusive, offensive, harassing, violent, hateful,
                inflammatory, or otherwise objectionable; (b) promote sexually explicit or
                pornographic material, violence, or discrimination based on race, sex,
                religion, nationality, disability, sexual orientation, or age; (c) infringe
                any patent, trademark, trade secret, copyright, or other intellectual
                property or other rights of any other person; (d) violate the legal rights
                (including the rights of publicity and privacy) of others or contain any
                material that could give rise to any civil or criminal liability under
                applicable laws or regulations or that otherwise may be in conflict with
                these Terms of Use and our Privacy Policy; (e) be likely to deceive any
                person; (f) promote any illegal activity or advocate, promote, or assist
                any unlawful act; (g) Impersonate any person or misrepresent your identity
                or affiliation with any person or organization; or (h) give the impression
                that they emanate from or are endorsed by us or any other person or entity,
                if this is not the case.
              </p>
              <h2>
                Trademarks
              </h2>
              <p>
                VSI’s trademarks and trade dress may not be used in connection with any
                product or service that is not VSI’s, in any manner that is likely to cause
                confusion among customers, or in any manner that disparages or discredits
                VSI. All other trademarks not owned by VSI that appear in a Website or
                Service are the property of their respective owners, who may or may not be
                affiliated with, connected to, or sponsored by VSI. All VSI trademarks and
                trade dress are the sole and exclusive property of VSI and no license is
                granted to you through your use of a Website or Services or pursuant to
                these Terms of Use to the same.
              </p>
              <h2>
                Disclaimers; Limitations of Liability; Indemnity
              </h2>
              <p>
                You acknowledge and agree that we do not guarantee or warrant that files
                available for downloading from the internet or a Website will be free of
                viruses or other destructive code. You are responsible for implementing
                sufficient procedures and checkpoints to satisfy your particular
                requirements for anti-virus protection and accuracy of data input and
                output, and for maintaining a means external to our Website for any
                reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR
                DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
                COMPUTER PROGRAMS, MOBILE DEVICE, DATA, OR OTHER PROPRIETARY MATERIAL DUE
                TO YOUR USE OF A WEBSITE AND SERVICES OR ANY ITEMS OBTAINED THROUGH A
                WEBSITE AND SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON THEM,
                OR ON ANY WEBSITE LINKED TO THEM.
              </p>
              <p>
                YOUR USE OF A WEBSITE AND SERVICES, THEIR CONTENT, AND OTHER ITEMS OBTAINED
                THROUGH A WEBSITE AND SERVICES (INCLUDING YOUR USERNAME, PASSWORD, PAYMENT
                INFORMATION, AND OTHER PERSONAL INFORMATION) IS AT YOUR OWN RISK. THE
                WEBSITE, SERVICES, THEIR CONTENT, AND ANY OTHER ITEMS OBTAINED THROUGH A
                WEBSITE AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
                WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER VSI
                NOR ANY PERSON ASSOCIATED WITH VSI MAKES ANY WARRANTY OR REPRESENTATION
                WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
                OR AVAILABILITY OF A WEBSITE OR SERVICES. WITHOUT LIMITING THE FOREGOING,
                NEITHER VSI NOR ANYONE ASSOCIATED WITH VSI REPRESENTS OR WARRANTS THAT A
                WEBSITE, SERVICES, THEIR CONTENT, OR ANY ITEMS OBTAINED THROUGH A WEBSITE
                AND SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
                DEFECTS WILL BE CORRECTED, THAT OUR WEBSITES OR THE SERVER THAT MAKES THEM
                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT A
                WEBSITE, SERVICES, OR ANY ITEMS OBTAINED THROUGH THE SAME WILL OTHERWISE
                MEET YOUR NEEDS OR EXPECTATIONS.
              </p>
              <p>
                VSI DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                STATUTORY OR OTHERWISE, INCLUDING ANY (a) WARRANTY OF MERCHANTABILITY; (b)
                WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; (c) WARRANTY OF TITLE; OR (d)
                WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD
                PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF
                PERFORMANCE, USAGE OF TRADE, OR OTHERWISE. THE FOREGOING DOES NOT AFFECT
                ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
              <p>
                TO THE FULL EXTENT PERMITTED BY LAW, IN NO EVENT SHALL VSI, ITS LICENSORS,
                OTHER SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE
                LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR
                IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBISTE, SERVICES,
                ANY WEBSITES LINKED TO THEM, ANY CONTENT ON THE SERVICES OR SUCH OTHER
                WEBSITES, OR ANY ITEMS OBTAINED THROUGH A WEBSITE, SERVICES, OR SUCH OTHER
                WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
                INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
                PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
                GOODWILL, LOSS OF DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. WITHOUT LIMITING THE
                FOREGOING AND TO THE FULL EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO
                EVENT SHALL VSI’S LIABILITY TO YOU RELATED TO ANY WEBSITE OR THE SERVICES
                EXCEED THE GREATER OF (A) THE AMOUNT YOU HAVE PAID VSI FOR THE PRODUCT OR
                SERVICE AT ISSUE, IF THE MATTER IN QUESTION REGARDS A PRODUCT OR SERVICE
                PURCHASED FROM VSI, SUBJECT TO THE TERMS OF VSI’S RETURN POLICY, OR (B)
                $100.00. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
              <p>
                You agree to defend, indemnify, and hold harmless VSI, its affiliates,
                licensors, and other service providers, and its and their respective
                officers, directors, employees, contractors, agents, licensors, suppliers,
                successors, and assigns from and against any claims, liabilities, damages,
                judgments, awards, losses, costs, expenses, or fees (including reasonable
                attorneys' fees) arising out of or relating to your violation of these
                Terms of Use, your use of a Website and Services or purchase or goods or
                services through the Websites or Services, including, but not limited to,
                your User Contributions, your User Account, or your use of any information
                obtained from a Website.
              </p>
              <h2>
                Governing Law and Jurisdiction
              </h2>
              <p>
                All matters relating to a Website, Services, and these Terms of Use and any
                dispute or claim arising therefrom or related thereto (in each case,
                including non-contractual disputes or claims), shall be governed by and
                construed in accordance with the internal laws of the State of Missouri
                without giving effect to any choice or conflict of law provision or rule
                (whether of the State of Missouri or any other jurisdiction). The U.N.
                Convention of Contracts for the International Sale of Goods is expressly
                excluded from the interpretation of these Terms of Use.
              </p>
              <p>
                Any legal suit, action, or proceeding arising out of, or related to, these
                Terms of Use or a Website or Services shall be instituted exclusively in
                the federal courts of the United States of the Western District of Missouri
                or the state courts of the State of Missouri located in Newton County,
                Missouri, although we retain the right to bring any suit, action, or
                proceeding against you for breach of these Terms of Use in your country of
                residence or any other relevant country and also retain the right to submit
                all claims to binding arbitration located in Joplin, Missouri. You waive
                any and all objections to the exercise of jurisdiction and venue over you
                by such courts or arbitration panels.
              </p>
              <p>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
                THESE TERMS OF USE OR A WEBSITE AND SERVICES MUST BE COMMENCED WITHIN ONE
                (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION
                OR CLAIM IS PERMANENTLY BARRED.
              </p>
              <h2>
                Waiver and Severability
              </h2>
              <p>
                No waiver of by VSI of any term or condition set forth in these Terms of
                Use shall be deemed a further or continuing waiver of such term or
                condition or a waiver of any other term or condition, and any failure of
                VSI to assert a right or provision under these Terms of Use shall not
                constitute a waiver of such right or provision. If any provision of these
                Terms of Use is held by a court or other tribunal of competent jurisdiction
                to be invalid, illegal, or unenforceable for any reason, such provision
                shall be eliminated or limited to the minimum extent such that the
                remaining provisions of the Terms of Use will continue in full force and
                effect.
              </p>
              <h2>
                Claims of Copyright Infringement
              </h2>
              <p>
                If you believe that any User Contributions violate your copyright, please
                contact us using the contact information on the last page of this Terms of
                Use.
              </p>
              <h2>
                Cumulative Remedies
              </h2>
              <p>
                All rights and remedies provided hereunder for VSI are cumulative and not
                exclusive, and the exercise or nonexercised by VSI of any right or remedy
                does not preclude the exercise of any other rights or remedies that may now
                or subsequently be available to VSI at law, in equity, by statute, in any
                other agreement between the parties or otherwise.
              </p>
              <h2>
                General
              </h2>
              <p>
                You may not assign your rights or delegate your obligations under these
                Terms of Use without VSI’s prior written approval. VSI may assign its
                rights or delegate its obligations under these Terms of Use without your
                consent to: (i) a subsidiary or affiliate; (ii) an acquirer of VSI’s
                equity, business, or assets; or (iii) a successor by merger. Any purported
                assignment in violation of this section shall be void. No joint venture,
                partnership, employment, or agency relationship exists between you, VSI, or
                any of VSI’s officers, directors, employees, agents, licensors, or other
                service providers as a result of these Terms of Use or your use of a
                Website.
              </p>
              <h2>
                Your Comments and Concerns
              </h2>
              <p>
                All notices, feedback, comments, requests for technical support, and other
                communications relating to a Website should be directed to VSI at:
              </p>
              <p>
                Vintage Stock, Inc.
              </p>
              <p>
                202 East 32nd Street
                <br/>
                Joplin, Missouri 64804
                <br/>
                417-623-1550
                <br/>
                <a href="mailto:comments@vintagestock.com">comments@vintagestock.com</a>
              </p>
              <div>
                <br/>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default TermsOfUse
