import React from 'react';
import {Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import vintageHistory from "services/browser-history/index";
import "./styles/SubscribedModal.scss"

const SubscribedModal = ({show, onHide, success}) => {
  const onContactFormClick = () => {
    vintageHistory.push({
      pathname: "/contact"
    });
    onHide()
  };

  return (
      <Modal
          className="subscribed-modal"
          show={show}
          onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <br/>
            {
              success ?
                  <span className="icon-circle-check"/>
                  :
                  <span className="icon-mail"/>
            }
            <br/>
            {success ? 'Thanks for your subscription' : 'You are already subscribed!'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            success ?
                <p>Thank you for signing up for our special savings offers! If you do not receive a confirmation
                  email please <a onClick={onContactFormClick}>Contact Us</a></p>
                :
                <p>
                  Oops! This email address already used.
                </p>
          }
        </Modal.Body>
        <Modal.Footer>
          <button
              type="button"
              className="btn btn-gray btn-block"
              onClick={onHide}>CLOSE
          </button>
        </Modal.Footer>
      </Modal>
  )
};

SubscribedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  success: PropTypes.bool
};

export default SubscribedModal;
