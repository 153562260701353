import React, {Component} from 'react';
import {COMING_SOON, THIS_WEEK} from "./constants/sections";
import HomeProductsContainer from "scenes/Home/components/FeaturedProducts/components/HomeProducts/container/index";

class FeaturedProducts extends Component {
  constructor(props){
    super(props);

    this.state = {
      sections: [
        // {
        //   id: 1,
        //   data: MOST_VIEWED
        // },
        {
          id: 2,
          data: THIS_WEEK
        },
        // {
        //   id: 3,
        //   data: COMING_SOON
        // }
      ]
    };
  }

  render(){
    const {sections} = this.state;
    return (
        <section id="featured-products">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div id="products-slider" className="owl-carousel">
                  <div className="featured-products">
                    {
                      sections.map(section => (
                          <div
                            key={`section${section.id}`}>
                            <HomeProductsContainer
                              section={section.data}/>
                          </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
  }
}

export default FeaturedProducts;