import React, {useEffect, useState} from 'react';
import _ from "lodash";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import vintageAxios from "services/api/index";
import QuantityInput from "utils/components/QuantityInput/index";
import AddToCartButtonContainer from "scenes/Store/scenes/ProductDetails/components/AddToCartButton/container/index";
import ProductTabs from "scenes/Store/scenes/ProductDetails/components/ProductTabs/index";
import AddToWishListButtonContainer
  from "scenes/Store/scenes/ProductDetails/components/AddToWishListButton/container/index";
import "./styles/ProductDetails.scss";
import Breadcrumb from "utils/components/Breadcrumb/index";
import {NEW} from "utils/constants/product-conditions";
import {PRODUCTS_DETAILS_CONTEXT} from "components/SEO/constants/contexts";
import SEO from "components/SEO/index";
import NotifyMeWhenAvailableButton from "utils/components/NotifyMeWhenAvailableButton/index";
import ProductImage from "scenes/Store/scenes/ProductsList/components/Product/components/ProductImage/index";
import TrustElements from "utils/components/TrustElements";
import {ProductContext, conditions} from "@vintagestock/vintage-common-components";
import moment from "moment";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import ProductIcon
  from "scenes/Store/scenes/ProductsList/components/Product/components/ProductImage/components/ProductIcon";
import PriceTable from "scenes/Store/components/PriceTable"
import {
  RADIO_OFFLINE_NEW,
  RADIO_OFFLINE_USED,
  RADIO_ONLINE_NEW,
  RADIO_ONLINE_USED
} from "scenes/Store/components/PriceTable/constants/radios"
import {QUERY_PARAM_PICKUP_AVAILABLE} from "scenes/Store/scenes/ProductsList/constants/query-params"
import QueryParams from "utils/QueryParams"
import FindInStoreBtn from "scenes/Store/components/FindInStoreBtn"

export default function ProductDetails({match, location, selectProduct}) {
  const [product, setProduct] = useState(null)
  const [quantity, setQuantity] = useState(1);
  const [condition, setCondition] = useState(null);
  const [activeRadio, setActiveRadio] = useState(null);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const queryParams = new QueryParams(location.search)

  useEffect(() => {
    setCondition((activeRadio === RADIO_ONLINE_USED || activeRadio === RADIO_OFFLINE_USED) ? conditions?.USED : conditions?.NEW)
  }, [activeRadio])

  useEffect(() => {
    if (!product)
      fetchProduct();
  });

  useEffect(() => {
    if (!product || product?.slug !== match?.params?.productSlug)
      fetchProduct();
  });

  /**
   * Show message of limit exceeded
   */
  function changeLimitExceeded() {
    setLimitExceeded(!limitExceeded);
  }

  /**
   * Show message of limit exceeded
   */
  function showLimitExceeded() {
    changeLimitExceeded();
    setTimeout(() => changeLimitExceeded(), 3000);
  }

  /**
   * Updates component state to change condition.
   * @param {String} condition
   */
  function handleConditionChange(condition) {
    setCondition(condition);
  }

  /**
   * Updates product quantity component's state.
   * @param {Number} quantity
   */
  function onQuantityChanges(quantity) {
    setQuantity(quantity);
  }

  /**
   * Handles clicks on the platform icons to update the product in redux cache so it shows up faster for better UX
   */
  function handlePlatformClick(product) {
    const productWithPlatforms = product;
    product.products_by_platforms = productsByPlatform;  // this will avoid platform section to flicker until it loads from the API
    selectProduct(productWithPlatforms);
    // setUpdatedFromApi(false);  // will force a fetch
  }

  /**
   * Handles click on platform options
   * @param event {Object}
   */
  function handlePlatformBtnClick(event) {
    // to reset this component because the url changes but the product keeps being the same until the api fetches the new one
    selectProduct(null);
  }

  /**
   * Fetch product from the server.
   * @returns {AxiosPromise<any>}
   */
  function fetchProduct() {
    const {params} = match;
    if(params){
      const promise = vintageAxios.get(`/products/all/${params?.productSlug}/`);
    promise.then(response => {
      const product = response.data;
      const activeRadioValue = product.total_stock_used > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined
          ?
          RADIO_ONLINE_USED
          :
          product.is_pre_order || (product.total_stock_new > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined)
              ?
              RADIO_ONLINE_NEW
              :
              product.pickup_available_used
                  ?
                  RADIO_OFFLINE_USED
                  :
                  RADIO_OFFLINE_NEW
      setActiveRadio(activeRadioValue)
      setCondition((activeRadioValue === RADIO_ONLINE_USED || activeRadioValue === RADIO_OFFLINE_USED) ? conditions.USED : conditions.NEW)
      setProduct(product)
    });
    return promise;
    }
  }

  let productsByPlatform = [];
  if (product && product.products_by_platforms && productsByPlatform.length === 0) {
    productsByPlatform = product.products_by_platforms;
    productsByPlatform.push(product);
    productsByPlatform = _.uniqBy(productsByPlatform, product => product.icon);
    productsByPlatform = _.sortBy(productsByPlatform, product => product.icon);
    productsByPlatform = _.filter(productsByPlatform, product => product.icon.indexOf("icon-game") === -1);
  }

  if (!product)
    return <LoadingCassette/>;

  return (
      <div>
        <section className="product-details">
          <Breadcrumb
              crumbs={[
                {title: "Products", to: "/store/products"},
                {title: product.name, to: "/store/products/" + product.slug},
              ]}/>
          <div className="container">
            <div className="row wrapper">
              <ProductImage
                  productName={product.name}
                  productIcon={product.icon}
                  productImageUrl={product.details_image_thumbnail}
                  withProductPhoto={false}/>
              <div className="col-md-12 top">
                <div className="info">
                  <h6>{product.name}</h6>
                  {
                    (activeRadio === RADIO_OFFLINE_NEW || activeRadio === RADIO_OFFLINE_USED) &&
                    <div className="call-us-to-reserve">
                      Call us to reserve and <strong>pick up</strong> in store or <strong>ship</strong> to
                      your door. <strong>*pay over the phone maximum is $100</strong>
                    </div>
                  }
                  {
                    moment(product.release_date) > moment(new Date()) &&
                    <p className="info__release-date">
                      Release:&nbsp;
                      <Moment format="MMM D, YYYY">
                        {product.release_date}
                      </Moment>
                    </p>
                  }
                  <div className="photo">
                    <img
                        src={product.details_image_thumbnail}
                        alt={product.name}/>
                  </div>
                  <p>{product.overview}</p>
                  <ProductContext.Provider
                      value={{
                        product,
                        condition,
                        setCondition,
                        activeRadio,
                        setActiveRadio
                      }}>
                    <PriceTable/>
                    {/*{
                      (product.products_by_platforms && product.products_by_platforms.length > 0) &&
                      <div className="products-by-platform">
                        <p className="section-title">
                          <strong>Platform:</strong>
                        </p>
                        {
                          productsByPlatform.length > 0 && productsByPlatform.map((p, index) => {
                            return (
                                <Link
                                    key={index}
                                    to={`/store/products/${p.slug}`}
                                    className={product.slug === p.slug ? "active" : undefined}
                                    onClick={handlePlatformBtnClick}>
                                  <ProductIcon
                                      icon={p.icon}
                                      withoutWrapper/>
                                </Link>
                            )
                          })
                        }
                      </div>
                    }*/}
                    {
                      product.is_pre_order &&
                      <div className="product-shipping-date">
                        <p className="section-title"><strong>Shipping:</strong></p>
                        <p>
                          Processing time: <strong><i>After the release date</i></strong> - Ships in 24
                          hours<span className="separator">&nbsp;&nbsp;|&nbsp;&nbsp;</span>Shipping time: (5-8
                          business days)
                        </p>
                      </div>
                    }
                    <div className="form-group product-actions-buttons">
                      {
                        // Show Add to cart button
                        (
                            product.in_stock ||
                            (product.pickup_available_new || product.pickup_available_used) ||
                            product.is_pre_order
                        )
                            ?
                            <React.Fragment>
                              {
                                (activeRadio === RADIO_ONLINE_NEW || activeRadio === RADIO_ONLINE_USED)
                                    ?
                                    <form className="form-inline">
                                      {
                                        product.in_stock &&
                                        <div className="form-group">
                                          <label htmlFor="qty_input">Qty:</label>
                                          <QuantityInput
                                              quantity={quantity}
                                              condition={condition}
                                              stockNew={product.total_stock_new}
                                              stockUsed={product.total_stock_used}
                                              onQuantityChanges={onQuantityChanges}
                                              showLimitExceeded={showLimitExceeded}/>
                                          {
                                            limitExceeded && (
                                                <div>
                                                  Only {condition === NEW ? product.total_stock_new : product.total_stock_used}
                                                  available
                                                  in stock
                                                </div>
                                            )
                                          }
                                        </div>
                                      }
                                      {
                                        //Show Add to cart button
                                        product.in_stock && (
                                            <div>
                                              <AddToCartButtonContainer
                                                  className="btn btn-block"
                                                  contentTypeId={product.content_type}
                                                  productId={product.id}
                                                  quantity={quantity}
                                                  selectedCondition={condition}/>
                                              <br/>
                                              <AddToWishListButtonContainer
                                                  contentType={product.content_type}
                                                  productId={product.id}
                                                  selectedCondition={condition}/>
                                            </div>
                                        )
                                      }
                                    </form>
                                    :
                                    <React.Fragment>
                                      <FindInStoreBtn
                                          className="btn btn-block"/>
                                    </React.Fragment>
                              }
                            </React.Fragment>
                            :
                            <NotifyMeWhenAvailableButton
                                condition={condition}
                                productId={product.id}/>
                      }
                    </div>
                  </ProductContext.Provider>
                  <br/>
                  {
                    (
                        (product.products_by_platforms && product.products_by_platforms.length > 0) &&
                        (product.in_stock || (product.pickup_available_new || product.pickup_available_used))
                    ) &&
                    <div className="products-by-platform">
                      <p>
                        <strong>Platform:</strong>
                      </p>
                      {
                        productsByPlatform.length > 0 && productsByPlatform.map((p, index) => {
                          return (
                              <Link
                                  key={index}
                                  to={`/store/products/${p.slug}`}
                                  aria-label={product.platform.name}
                                  onClick={() => handlePlatformClick(p)}
                                  className={product.slug === p.slug ? "active" : undefined}>
                                <ProductIcon
                                    icon={p.icon}
                                    withoutWrapper/>
                              </Link>
                          )
                        })
                      }
                    </div>
                  }
                  {
                    product.is_pre_order &&
                    <div className="product-shipping-date">
                      <p><strong>Shipping:</strong></p>
                      <p>
                        Processing time: <strong><i>After the release date</i></strong> - Ships in 24
                        hours<span className="separator">&nbsp;&nbsp;|&nbsp;&nbsp;</span>Shipping time: (5-8
                        business days)
                      </p>
                    </div>
                  }
                  <TrustElements/>
                </div>
                <div className="bottom">
                  <ProductTabs
                      product={product}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SEO url='store/products/productSlug'
             context={PRODUCTS_DETAILS_CONTEXT}
             ogImage={product.details_image_thumbnail}
             ogImageWidth={322}
             ogImageHeight={455}
             information={{title: product.name}}/>
      </div>
  )
}