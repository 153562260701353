export const ORDERS_RECEIVE = 'ORDERS_RECEIVE';
export const ORDER_SELECT = 'ORDER_SELECT';
export const ORDERS_CLEAR = 'ORDERS_CLEAR';
export const ORDER_ITEM_TOGGLE_RETURN_FLAG = 'ORDER_ITEM_TOGGLE_RETURN_FLAG';
export const ORDER_ITEM_UPDATE_RETURN_REASON = 'ORDER_ITEM_UPDATE_RETURN_REASON';
export const ORDER_ITEMS_TO_BE_RETURNED_VALIDATE = 'ORDER_ITEMS_TO_BE_RETURNED_VALIDATE';
export const ADDRESSES_RECEIVE = 'ADDRESSES_RECEIVE';
export const ADDRESS_SELECT = 'ADDRESS_SELECT';
export const ADDRESS_DESELECT = 'ADDRESS_DESELECT';
export const ADDRESS_ADD_OR_UPDATE = 'ADDRESS_ADD_OR_UPDATE';
export const ADDRESS_REMOVE = 'ADDRESS_REMOVE';
export const CREDIT_CARDS_RECEIVE = 'CREDIT_CARDS_RECEIVE';
export const CREDIT_CARD_SELECT = 'CREDIT_CARD_SELECT';
export const CREDIT_CARD_DESELECT = 'CREDIT_CARD_DESELECT';
export const CREDIT_CARD_ADD_OR_UPDATE = 'CREDIT_CARD_ADD_OR_UPDATE';
export const CREDIT_CARD_REMOVE = 'CREDIT_CARD_REMOVE';
export const WISH_LIST_ITEMS_RECEIVE = 'WISH_LIST_ITEMS_RECEIVE';
export const WISH_LIST_ITEM_ADD = 'WISH_LIST_ITEM_ADD';
export const WISH_LIST_ITEM_REMOVE = 'WISH_LIST_ITEM_REMOVE';
export const ORDER_ITEMS_VALIDATE_RETURN_REASON = 'ORDER_ITEMS_VALIDATE_RETURN_REASON';
export const CREDIT_CARD_SET_AS_DEFAULT = 'CREDIT_CARD_SET_AS_DEFAULT';
export const ADDRESS_BOOK_SET_AS_DEFAULT = 'ADDRESS_BOOK_SET_AS_DEFAULT';
export const EMPTY_ACCOUNT_INFORMATION = 'EMPTY_ACCOUNT_INFORMATION';

