import React, {Component} from 'react';
import classNames from 'classnames/bind';
import slugify from "utils/snippets/slugify";
import vintageAxios from "services/api";
import {
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_IN_TRANSIT,
  SHIPMENT_STATUS_SHIPPED,
  SHIPMENT_STATUSES
} from "scenes/MyAccount/scenes/OrderTracking/constants/shipment-statuses";
import Moment from "react-moment";
import LoadingCassette from "utils/components/misc/LoadingCassette";
import "./styles/OrderTracking.scss";
import Breadcrumb from "utils/components/Breadcrumb/index";
import {Redirect} from "react-router-dom";

class OrderTracking extends Component {
  /**
   * Fetch orders from the redux store or the API (fallback).
   * @returns {*}
   */
  fetchOrder(){
    const {selectedOrder} = this.props.myAccountReducer;
    const {match} = this.props;
    let promise;
    if (selectedOrder)
      promise = Promise.resolve({
        data: selectedOrder
      });
    else {
      promise = vintageAxios.get(`/management/orders/${match.params.orderUUID}/`);
      promise.then(response => {
        this.props.selectOrder(response.data);
      });
    }
    return promise;
  }

  /**
   * Get display value given a status.
   * @param {Number} status
   * @returns {String} status
   */
  static getStatusDisplay(status){
    for( let [key, value] of SHIPMENT_STATUSES )
      if( key === status ) return value;
  }

  componentDidMount(){
    this.fetchOrder();
  }

  render(){
    const order = this.props.myAccountReducer.selectedOrder;

    if( !order )
      return <LoadingCassette/>;

    if (order.shipments.length <= 0)
      return <Redirect to={`/my-account/order-history/${order.uuid}`}/>;

    const shipment = order.shipments.length > 0 ? order.shipments[0] : null;
    const shipmentSteps = [
      {
        name: "Shipped",
        current: shipment.status === SHIPMENT_STATUS_SHIPPED
      },
      {
        name: "In Transit",
        current: shipment.status === SHIPMENT_STATUS_IN_TRANSIT
      },
      {
        name: "Delivered",
        current: shipment.status === SHIPMENT_STATUS_DELIVERED
      },
    ];

    return(
        <div>
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "Order History", to: "/my-account/order-history"},
                {title: "Order No. " + order.id, to: "/my-account/order-history/" + order.uuid},
                {title: "Tracking", to: "/my-account/order-history/" + order.uuid + "/tracking"},
              ]}/>
          <div className="container">
            <div className="tracking">
              <h4 className="title">Packet tracking</h4>
              <div className="row">
                <div className="col-md-12">
                  <div className="details">
                    <p>Order No.<span className="value">{order.uuid}</span></p>
                  </div>
                  <div className="details right">
                    <p>Date:<span id="order-number" className="value">{`${new Date(order.created_at).toDateString()}`}</span></p>
                  </div>
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="col-md-12">
                  <div
                      className={`status ${slugify(OrderTracking.getStatusDisplay(shipment.status))}`}>
                    <span className="circle"/>
                    <span className="text">
                      {OrderTracking.getStatusDisplay(shipment.status)}
                    </span>
                  </div>
                  <p>
                    Your Package was delivered on
                    &nbsp;
                    <Moment format="MMMM D, YYYY h:mm a">
                      {shipment.created_at}
                    </Moment>
                  </p>
                  <div className="row status-line">
                    {
                      shipmentSteps.map((step, index) =>
                          <div
                              key={`shipmentStep${index}`}
                              className="col-xs-4 column">
                            {
                              ((shipmentSteps[1].current && (index === 0 || index === 1)) ||
                                  (shipmentSteps[2].current)) &&
                              <span className={classNames({
                                "line": true,
                                "line--middle-with": shipmentSteps[1].current && index === 1
                              })}/>
                            }
                            <div className="point">
                              <span className="circle">
                                {
                                  step.current &&
                                  <span className="icon icon-check"/>
                                }
                              </span>
                              <span className="text">{step.name}</span>
                            </div>
                          </div>
                      )
                    }
                  </div>
                  {
                    (shipment.url && shipment.url !== "") &&
                    <div>
                      <hr/>
                      <h6>Tracking Detail</h6>
                      <p>Please <a href={shipment.url} target="_blank">click here</a> to track your packet.</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default OrderTracking
