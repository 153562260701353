import React, {PureComponent} from 'react';
import image from "./img/about-img.jpg";
import Breadcrumb from "utils/components/Breadcrumb/index";
import SEO from "components/SEO/index";
import "./styles/AboutUs.scss";

class AboutUs extends PureComponent {
  render() {
    return (
        <div className="about-us">
          <Breadcrumb
              crumbs={[
                {title: "About", to: "/about"},
                {title: "About Us", to: "/about/us"},
              ]}/>
          <div className="container">
            <div className="wrapper">
              <h1>About Us</h1>
              <p>
                <img
                    src={image}
                    className="img-responsive"
                    alt="VintageStock"/>
              </p>
              <p>{process.env.REACT_APP_SITE_NAME} is your <span className="underline">entertainment superstore!</span>
              </p>
              <p>From the newest hit movies, music, and video games to the rarest comics, toys, and memorabilia, Vintage
                Stock is the only store to have it all! Everything you want, all in one store.</p>
              <h2>What do we buy and sell?</h2>
              <p>
                <strong>Online & In Stores:</strong><br/><br/>
                <strong>Movies:</strong> Blu-ray, DVDs, 4K UHD<br/>
                <strong>Video Games:</strong> Both retro & new games.<br/>
                <strong>Collectibles:</strong> new Toys and Posters<br/>
              </p>
              <p>
                <strong>In Stores only:</strong><br/><br/>
                <strong>Music:</strong> CDs & LPs<br/>
                <strong>Video Games:</strong> Retro & new consoles, accessories, & guides.<br/>
                <strong>Collectibles:</strong> comic books, graphic novels, used toys, board games, & memorabilia<br/>
                <strong>Cards:</strong> sports cards & collectible game cards, like Magic the Gathering, Pokemon, &
                Yu-Gi-Oh!<br/>
                <strong>Books:</strong> We carry books in our Joplin, MO, V·Stock, and EntertainMART locations, both classic
                and
                new best-sellers.<br/>
              </p>
              <h2>We also offer other great services to help our customers</h2>
              <p>
                <strong>Rentals:</strong> Movies & Video Games can be rented, with the option to
                buy after you try!<br/>
                <strong>Special Orders:</strong> Every location has access to the massive inventory of the
                entire company, and will ship directly to you.<br/>
                <strong>Disc Repair:</strong> Got a scratched disc that skips or won’t play at all? Vintage
                Stock can repair most damaged DVDs or CDs<br/>
                <strong>Knowledgeable staff:</strong> Our sales associates have a wide range of knowledge
                about the products we carry!
              </p>
              <h2>Management Team</h2>
              <p>The Vintage Stock and Movie Trading Company executive team has extensive experience and vision to
                ensure
                that
                our company maintains its growth potential and leadership in pre-owned diversified product lines. That
                is
                the
                concept behind Vintage Stock: everything you want in one store.</p>
            </div>
          </div>
          <SEO url='about/us'/>
        </div>
    )
  }
}

export default AboutUs
