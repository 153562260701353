import {connect} from 'react-redux';
import AddressReduxForm from "../index";
import {change} from 'redux-form';
import {deselectAddress} from "scenes/MyAccount/actions/index";
import {
  DEFAULT_BY_BILLING, DEFAULT_BY_BILLING_AND_SHIPPING,
  DEFAULT_BY_SHIPPING
} from "scenes/MyAccount/scenes/AddressBook/components/Address/constants/index";

const mapStateToProps = (state) => {
  return {
    initialValues: (state => {
      const {selectedAddress} = state.myAccountReducer;

      if (!selectedAddress)
        return null;

      return selectedAddress
          ? {
            ...selectedAddress,
            byDefaultAsBilling: selectedAddress.default && (
                selectedAddress.address_type === DEFAULT_BY_BILLING
                || selectedAddress.address_type === DEFAULT_BY_BILLING_AND_SHIPPING
            ),
            byDefaultAsShipping: selectedAddress.default && (
                selectedAddress.address_type === DEFAULT_BY_SHIPPING
                || selectedAddress.address_type === DEFAULT_BY_BILLING_AND_SHIPPING
            )
          }
          : null
    })(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: (field, value) => dispatch(
        change('accountAddressForm', field, value)
    ),
    deselectAddress: () => dispatch(deselectAddress()),

  };
};

const AddressReduxFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressReduxForm);

export default AddressReduxFormContainer;