import * as types from '../actions/types';
import {getProductsViewAs} from "services/store";
import {QUERY_PARAM_PAGE} from "scenes/Store/scenes/ProductsList/constants/query-params";
import {getParsedQueryParams} from "services/browser-history";

export const initialState = {
  selectedProduct: null,
  cart: {
    cart_items: []
  },
  advancedSearch: {},
  filter: null,
  applyFilters: null,
  selectedCategory: null,
  isFormEmpty: true,
  cartTotals: {},
  viewAs: getProductsViewAs(),
  page: parseInt(getParsedQueryParams()[QUERY_PARAM_PAGE], 10) || 1,
  searchByCategory: null,
  categories: null
};

export const storeReducer = (state=initialState, action) => {
  switch(action.type){
    case types.PRODUCT_SELECT:
      return {
        ...state,
        selectedProduct: action.product
      };
    case types.PRODUCT_DESELECT:
      return {
        ...state,
        selectedProduct: null
      };
    case types.CART_RECEIVE:
      return {
        ...state,
        cart: action.cart
      };
    case types.RECEIVE_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    case types.CART_ITEM_UPDATE_QUANTITY:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: state.cart.cart_items.map(item => {
            if( action.cartItem === item )
              item.quantity = action.quantity;
            return item;
          })
        }
      };
    case types.CART_ITEM_REMOVE:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: state.cart.cart_items.filter(item => item !== action.cartItem)
        }
      };
    case types.CART_TOTALS_RECEIVE:
      return {
        ...state,
        cartTotals: action.totals
      };
    case types.CART_EMPTY:
      return {
        ...state,
        cart: initialState.cart
      };
    case types.CART_SINGLE_ITEM_RECEIVE:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_items: state.cart.cart_items === [] ? [action.item] : [
            // Keep all items excluding action.item
            ...state.cart.cart_items.filter(cartItem => cartItem.id !== action.item.id),
            // Append action.item (with updated quantity)
            action.item
          ]
        }
      };
    case types.DISCOUNT_CODE_RECEIVE:
      return {
        ...state,
        cart: {
          ...state.cart,
          discount_code_data: action.discountCodeData
        }
      };
    case types.PRODUCTS_VIEW_AS_TOGGLE:
      return {
        ...state,
        viewAs: action.viewAs
      };
    case types.PRODUCTS_LIST_UPDATE_PAGE:
      return {
        ...state,
        page: action.page
      };
    case types.FILTER_SELECT:
      return {
        ...state,
        filter: action.filter
      };
    case types.FILTER_SELECT_UPDATE:
      return {
        ...state,
        advancedSearch: {
            ...state.advancedSearch,
          [action.filterName]: action.options
        }
      };
    case types.ADVANCED_SEARCH_SELECT:
      return {
        ...state,
        advancedSearch: action.advancedSearch
      };
    case types.APPLY_FILTERS_SELECT:
      return {
        ...state,
        applyFilters: action.applyFilters
      };
    case types.SELECTED_CATEGORY_RECEIVE:
      return {
        ...state,
        selectedCategory: action.selectedCategory
      };
    case types.SELECTED_PLATFORMS_RECEIVE:
      return {
        ...state,
        applyFilters: {
            ...state.applyFilters,
          platforms: action.platforms
        }
      };
    case types.PRODUCTS_SEARCH_BY_CATEGORY:
      return {
        ...state,
        searchByCategory: action.category
      };
    case types.RESET_ADVANCED_SEARCH:
      return {
        ...state,
        advancedSearch: {}
      };
    case types.EMPTY_ADVANCED_SEARCH:
      return {
        ...state,
        isFormEmpty: action.payload
      };
    case types.UPDATE_ADVANCED_SEARCH:
      return {
        ...state,
        advancedSearch: action.payload
      };
  default:
      return state;
  }
};