import React from 'react';
import NumberFormat from "react-number-format";

export const renderInput = ({ input, type, autoComplete = "off", placeholder, className, id,  meta: { touched, error, warning } }) => (
    <div className="form-field">
      <input {...input}  placeholder={placeholder} type={type} className={className} id={id}
             autoComplete={autoComplete ?? "off"}/>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderSelect = ({ input, className, multiple, children, meta: { touched, error, warning } }) => (
    <div className="form-field">
      <select {...input} multiple={multiple} id={input.name} className={className}>
        {children}
      </select>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderTextarea = ({ input, className, id, meta: { touched, error, warning } }) => (
    <div className="form-field">
      <textarea {...input} className={className} id={id}/>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderCheckbox = ({ input, id, defaultChecked, checked, className, meta: { touched, error, warning } }) => (
    <div className="form-field">
      <input id={id} {...input} defaultChecked={defaultChecked} checked={checked} type="checkbox" className={className}/>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderRadio = ({ input, id, checked, disabled, readOnly, className, defaultChecked, meta: { touched, error, warning } }) => (
    <div className="form-field">
      <input
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          type="radio"
          checked={checked}
          defaultChecked={defaultChecked}
          className={className}
          {...input}/>
      {
        touched && (
            (error && <span className="help-block">{error}</span>)
            ||
            (warning && <span className="help-block">{warning}</span>)
        )
      }
    </div>
);

export const renderPhoneInput = ({ input, id, autoFocus, placeholder, autoComplete, className, meta: { touched, error, warning } }) => (
  <div className="form-field">
    <NumberFormat
      id={id}
      format="(###) ###-####"
      displayType="input"
      className={className || "form-control"}
      placeholder={placeholder}
      type="tel"
      {...input}
    />
    {
      touched && (
        (error && <span className="help-block">{error}</span>)
        ||
        (warning && <span className="help-block">{warning}</span>)
      )
    }
  </div>
);

export const renderCreditCardInput = ({ input, id, autoFocus, placeholder, autoComplete, className, meta: { touched, error, warning}}) => (
  <div className="form-field">
    <NumberFormat
      id={id}
      format="#### #### #### ####"
      displayType="input"
      className={className || "form-control"}
      placeholder={placeholder}
      type="tel"
      {...input}
    />
    {
      touched && (
        (error && <span className="help-block">{error}</span>)
        ||
        (warning && <span className="help-block">{warning}</span>)
      )
    }
  </div>
);