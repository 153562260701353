import { connect } from 'react-redux';
import MyProfileReduxForm from "../index";

const mapStateToProps = (state) => {
  return {
    initialValues: state.authReducer.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const MyProfileReduxFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProfileReduxForm);

export default MyProfileReduxFormContainer;