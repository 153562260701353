export const DEFAULT_ICON = 'icon-default';
export const XBOX_360_ICON = 'icon-xbox-360';
export const XBOX_KINECT_ICON = 'icon-xbox-kinect';
export const XBOX_ICON = 'icon-xbox';
export const XBOX_ONE_ICON = 'icon-xbox-one';
export const PSVITA_ICON = 'icon-psvita';
export const PSP_ICON = 'icon-psp';
export const PLAYSTATION_ICON = 'icon-playstation';
export const PLAYSTATION_2_ICON = 'icon-playstation-2';
export const PLAYSTATION_3_ICON = 'icon-playstation-3';
export const PLAYSTATION_4_ICON = 'icon-playstation-4';
export const PC_ICON = "icon-pc";
export const WII_ICON = "icon-wii";
export const WII_U_ICON = "icon-wii-u";
export const GAMEBOY_ADVANCE_ICON = "icon-gameboy-advance";
export const NINTENDO_DS_ICON = "icon-nintendo-ds";
export const NINTENDO_3DS_ICON = "icon-nintendo-3ds";
export const NINTENDO_SWITCH_ICON = "icon-nintendo-switch";
export const GAME_ICON = 'icon-game';
export const TOY_ICON = 'icon-toy';
export const POSTER_ICON = 'icon-poster';
export const DVD_ICON = 'icon-dvd';
export const BLURAY_ICON = 'icon-bluray';
export const MUSIC_CD_ICON = 'icon-cd';
export const VINYL_ICON = 'icon-vinyl';
export const CASSETTE_ICON =  'icon-cassette';

export const ALL_ICONS = [
  XBOX_360_ICON,
  XBOX_KINECT_ICON,
  XBOX_ICON,
  XBOX_ONE_ICON,
  PSVITA_ICON,
  PSP_ICON,
  PLAYSTATION_ICON,
  PLAYSTATION_2_ICON,
  PLAYSTATION_3_ICON,
  PLAYSTATION_4_ICON,
  PC_ICON,
  WII_ICON,
  WII_U_ICON,
  GAMEBOY_ADVANCE_ICON,
  NINTENDO_DS_ICON,
  NINTENDO_3DS_ICON,
  NINTENDO_SWITCH_ICON,
  GAME_ICON,
  TOY_ICON,
  POSTER_ICON,
  DVD_ICON,
  BLURAY_ICON,
  MUSIC_CD_ICON,
  VINYL_ICON,
  CASSETTE_ICON
];