import React, {useContext} from "react";
import "./styles/PriceTable.scss"
import {ProductContext} from "@vintagestock/vintage-common-components"
import {
  RADIO_OFFLINE_NEW,
  RADIO_OFFLINE_USED,
  RADIO_ONLINE_NEW,
  RADIO_ONLINE_USED
} from "scenes/Store/components/PriceTable/constants/radios"

function Radio(props) {
  const {product} = useContext(ProductContext)
    let ariaLabelButton = "";

    const location = [RADIO_ONLINE_NEW, RADIO_ONLINE_USED].includes(props.value) ? "in store" : "online";
    const status = (props.value === RADIO_ONLINE_NEW || props.value === RADIO_OFFLINE_NEW) ? "New" : "Used";

  return (
      <input
          type="radio"
          name={`price-radio-${product.id}`}
          aria-label={`Select ${product.name} ${status} ${location}`}
          {...props}/>
  )
}

export default function PriceTable() {
  const {product, activeRadio, setActiveRadio} = useContext(ProductContext)

  function handleChange(event) {
    setActiveRadio(event.target.value)
  }

  return (
      <table className="table price-table">
        <thead>
        <tr>
          <td/>
          <td>
            <span>USED</span>
            {
              (product.total_stock_used > 0 || product.pickup_available_used)
                  ?
                  <span className="blue">${product.price_used}</span>
                  :
                  <span className="gray">N/A</span>
            }
          </td>
          <td>
            <span>NEW</span>
            {
              (product.is_pre_order || product.total_stock_new > 0 || product.pickup_available_new)
                  ?
                  <span className="blue">${product.price_new}</span>
                  :
                  <span className="gray">N/A</span>
            }
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>ONLINE</td>
          <td>
            <Radio
                value={RADIO_ONLINE_USED}
                checked={activeRadio === RADIO_ONLINE_USED}
                disabled={product.total_stock_used === 0}
                onChange={handleChange}/>
          </td>
          <td>
            <Radio
                value={RADIO_ONLINE_NEW}
                checked={activeRadio === RADIO_ONLINE_NEW}
                disabled={!product.is_pre_order && product.total_stock_new === 0}
                onChange={handleChange}/>
          </td>
        </tr>
        <tr>
          <td>IN STORE</td>
          <td>
            <Radio
                value={RADIO_OFFLINE_USED}
                checked={activeRadio === RADIO_OFFLINE_USED}
                disabled={product.pickup_available_used === false}
                onChange={handleChange}/>
          </td>
          <td>
            <Radio
                value={RADIO_OFFLINE_NEW}
                checked={activeRadio === RADIO_OFFLINE_NEW}
                disabled={product.pickup_available_new === false}
                onChange={handleChange}/>
          </td>
        </tr>
        </tbody>
      </table>
  )
}