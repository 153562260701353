import React from 'react';
import {email, required} from "utils/forms/validators";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";
import {renderInput} from "utils/forms/renderers";
import {Field, reduxForm} from "redux-form";
import Recaptcha from "components/Recaptcha/index";
import PropTypes from "prop-types";
import withRecaptcha from "utils/components/withRecaptcha/index";

export const NotifyMeWhenAvailableForm = ({status, handleSubmit, errors, checkedRecaptcha, afterCheckedRecaptcha, onExpiredRecaptcha}) => {
  return (
      <form
          className="form notify-me-when-available-form"
          onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email <span className="required">*</span></label>
          <Field id="email"
                 name="email"
                 component={renderInput}
                 type="email"
                 className="form-control"
                 validate={[required, email]}
                 placeholder="Type your email address"/>
          {errors && errors.map((error, index) => (
              <span
                  key={`notifyMeWhenAvailableFormError${index}`}
                  className="help-block">
                  {error}
                </span>
          ))}
        </div>
        {/*<Recaptcha
            afterCheckedRecaptcha={afterCheckedRecaptcha}
            onExpiredRecaptcha={onExpiredRecaptcha}/>*/}
        <br/>
        <div className="form-group">
          <button
              type="submit"
              disabled={
                status === LOADING_STATUS
                || status === SUCCESSFUL_STATUS
                || !checkedRecaptcha}>
            {
              status === LOADING_STATUS ? (
                  <span>SENDING...</span>
              ) : (
                  status === SUCCESSFUL_STATUS ? (
                      <span>
                          <i className="fa fa-check"/>SENT
                        </span>
                  ) : (
                      <span>SEND</span>
                  )
              )
            }
          </button>
        </div>
      </form>
  );
};

NotifyMeWhenAvailableForm.propTypes = {
  status: PropTypes.oneOf(
      [INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS]
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.array
};

export default withRecaptcha(reduxForm({
  form: 'notifyMeWhenAvailableForm'
})(NotifyMeWhenAvailableForm));