import * as types from '../actions/types';
import {isAuthenticated, getUser} from "services/auth";

export const initialState = {
  user: getUser(),
  isAuthenticated: isAuthenticated()
};

export const authReducer = (state = initialState, action) => {
  switch (action.type){
    case types.LOGIN:
      return {
        ...state,
        user: action.user,
        isAuthenticated: true
      };
    case types.LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false
      };
    case types.USER_RECEIVE:
      return {
        ...state,
        user: action.user
      };
    default:
      return state;
  }
};