import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import {Modal} from "react-bootstrap";
import CreditCardReduxFormContainer from "scenes/MyAccount/scenes/CreditCardsInfo/components/AddCreditCardModal/components/CreditCardForm/container/index";
import {
  getStoredAddresses
} from "scenes/Store/scenes/Checkout/services/storage/index";
import CreditCard from "scenes/MyAccount/scenes/CreditCardsInfo/components/CreditCard/index";
import {isAuthenticated} from "services/auth/index";
import Address from "scenes/MyAccount/scenes/AddressBook/components/Address";

class AddCreditCardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: false,
      errors: {},
      addressErrors: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchAddresses();
  }

  /**
   * Close this modal after a second.
   */
  closeModal() {
    setTimeout(() => {
      this.props.deselectCreditCard();
      this.setState({success: false});  // reset it
    }, 1000);
  }

  /**
   * Creates or edits an address.
   * @param {Object} data
   * @returns {AxiosPromise<any>}
   */
  saveAddress(data) {
    let promise;
    promise = Address.createOrUpdateAddress(data.address);

    promise.then(response => {
      this.setState({
        addressErrors: {}, // clear error of address form fields,
      });

      let creditCard = Object.assign({}, data);
      delete creditCard["address"]; // The billing address is created

      creditCard.billing_address = response.data.id;
      this.saveCreditCard(creditCard);
    });

    promise.catch(error => {
      this.setState({
        loading: false,
        addressErrors: error.response.data
      });
    });
    return promise;
  }

  /**
   * Creates or edits an creditCard.
   * @param {Object} data
   * @returns {AxiosPromise<any>}
   */
  saveCreditCard(data) {
    let promise;
    promise = CreditCard.createOrUpdateCreditCard(data);

    promise.then(response => {
      this.setState({
        loading: false,
        success: true
      });
      this.closeModal();
      if (typeof this.props.onAfterCreated === 'function')
        this.props.onAfterCreated();
    });

    promise.catch(error => {
      this.setState({
        loading: false,
        success: false,
        errors: error.response.data
      });
    });

    return promise;
  }

  /**
   * Handles form submit
   * @param {Object} data
   */
  handleSubmit(data) {
    this.setState({
      loading: true
    }, () => {
      let creditCard = Object.assign({}, data);
      delete creditCard["address"];
      // The billing address is created. Is added when modal is open
      if (data["address"])
        this.saveAddress(data);
      else
        this.saveCreditCard(creditCard);
    });
  }

  /**
   * Checks if addresses are already in the store and returns a promise to fetch
   * them from the API if not.
   * @returns {AxiosPromise<any>}
   */
  get addressesPromise() {
    const {addresses} = this.props.myAccountReducer;
    // Addresses already fetched, no need to re fetch them
    if (addresses && addresses.length > 0)
      return Promise.resolve({data: []});

    // Simulate promise returning empty array when user is not authenticated
    const promise = isAuthenticated() ?
        vintageAxios.get('/management/customers/addresses/')
        :
        Promise.resolve({data: getStoredAddresses()});
    return promise;
  }

  /**
   * Fetch addresses from the API.
   * @returns {AxiosPromise<any>}
   */
  fetchAddresses() {
    const promise = this.addressesPromise;
    promise.then(response => {
      if (!this.props.myAccountReducer.addresses)
        this.props.receiveAddresses(response.data);
    });
    return promise;
  }

  render() {
    const {myAccountReducer, deselectCreditCard} = this.props;
    const {loading, errors, success, addressErrors} = this.state;

    return (
        <Modal
            show={myAccountReducer.selectedCreditCard !== null}
            onHide={deselectCreditCard}>
          <Modal.Header closeButton>
            <Modal.Title>
              {(myAccountReducer.selectedCreditCard && myAccountReducer.selectedCreditCard.id) ? 'Edit Credit Card' : 'Add Credit Card'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreditCardReduxFormContainer
                onSubmit={this.handleSubmit}
                isLoading={loading}
                success={success}
                errors={errors}
                addressErrors={addressErrors}
                onCancelButtonClick={deselectCreditCard}/>
          </Modal.Body>
        </Modal>
    )
  }
}

export default AddCreditCardModal;