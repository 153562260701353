import axios from 'axios';
import {LOCAL_STORAGE_TOKEN_KEY} from "services/auth/constants";
import * as queryString from "query-string";

export const getBaseURL = () => {
  return process.env.REACT_APP_API_URL;
};

const config = {
  baseURL: getBaseURL(),
  timeout: 200000,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: function(params) {
    return queryString.stringify(params)
  }
};

const vintageAxios = axios.create(config);

vintageAxios.interceptors.request.use((config) => {
  let token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  if( token ){
    config.headers = {
      ...config.headers,
      'Authorization': `Token ${token}`
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

let resources = [
  {
    name: 'addresses',
    url: '/management/customers/addresses/',
    allowedMethods: ['get', 'post', 'delete']
  }
];

const getUrlByName = (name) => {
  let found = resources.find((resource) => {
    return resource.name === name;
  });
  // TODO validate the case when the provided name is not in the resources array
  return found.url;
};

export function apiList(resourceName, params){
  let url = getUrlByName(resourceName);
  return vintageAxios.get(url, {
    params: params
  })
}

export default vintageAxios;