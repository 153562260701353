export const PRODUCT_SELECT = 'PRODUCT_SELECT';
export const PRODUCT_DESELECT = 'PRODUCT_DESELECT';
export const CART_RECEIVE = 'CART_RECEIVE';
export const CART_ITEM_UPDATE_QUANTITY = 'CART_ITEM_UPDATE_QUANTITY';
export const CART_ITEM_REMOVE = 'CART_ITEM_REMOVE';
export const CART_TOTALS_RECEIVE = 'CART_TOTALS_RECEIVE';
export const CART_EMPTY = 'CART_EMPTY';
export const CART_SINGLE_ITEM_RECEIVE = 'CART_SINGLE_ITEM_RECEIVE';
export const DISCOUNT_CODE_RECEIVE = 'DISCOUNT_CODE_RECEIVE';
export const PRODUCTS_VIEW_AS_TOGGLE = 'PRODUCTS_VIEW_AS_TOGGLE';
export const PRODUCTS_LIST_UPDATE_PAGE = 'PRODUCTS_LIST_UPDATE_PAGE';
export const FILTER_SELECT = 'FILTER_SELECT';
export const FILTER_SELECT_UPDATE = 'FILTER_SELECT_UPDATE';
export const ADVANCED_SEARCH_SELECT = 'ADVANCED_SEARCH_SELECT';
export const APPLY_FILTERS_SELECT = 'APPLY_FILTERS_SELECT';
export const SELECTED_CATEGORY_RECEIVE = 'SELECTED_CATEGORY_RECEIVE';
export const SELECTED_PLATFORMS_RECEIVE = 'SELECTED_PLATFORMS_RECEIVE';
export const PRODUCTS_SEARCH_BY_CATEGORY = 'PRODUCTS_SEARCH_BY_CATEGORY';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const RESET_ADVANCED_SEARCH = 'RESET_ADVANCED_SEARCH';
export const EMPTY_ADVANCED_SEARCH = 'EMPTY_ADVANCED_SEARCH';
export const UPDATE_ADVANCED_SEARCH = 'UPDATE_ADVANCED_SEARCH';