import React from 'react';
import WishListContainer from "scenes/MyAccount/scenes/WishList/container/index";
import NewsletterSubscribe from "components/NewsletterSubscribe/index";

const WishlistShare = () => {
  return (
      <div>
        <section className="page-content">
          <WishListContainer/>
        </section>
        <NewsletterSubscribe/>
      </div>
  );
};

export default WishlistShare;