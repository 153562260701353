import { connect } from 'react-redux';
import Product from "../index";
import {selectProduct} from "scenes/Store/actions";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectProduct: product => dispatch(selectProduct(product))
  };
};

const ProductContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Product);

export default ProductContainer;