import * as types from "./types";

export const selectProduct = (product) => ({
  type: types.PRODUCT_SELECT,
  product
});

export const deselectProduct = () => ({
  type: types.PRODUCT_DESELECT
});

export const receiveCart = cart => ({
  type: types.CART_RECEIVE,
  cart
});

export const receiveCategories = categories => ({
  type: types.RECEIVE_CATEGORIES,
  categories
});

export const updateCartItemQuantity = (cartItem, quantity) => ({
  type: types.CART_ITEM_UPDATE_QUANTITY,
  cartItem,
  quantity
});

export const removeCartItem = cartItem => ({
  type: types.CART_ITEM_REMOVE,
  cartItem
});

export const receiveCartTotals = totals => ({
  type: types.CART_TOTALS_RECEIVE,
  totals
});

export const emptyCart = () => ({
  type: types.CART_EMPTY
});

export const receiveCartSingleItem = (item) => ({
  type: types.CART_SINGLE_ITEM_RECEIVE,
  item
});

export const receiveDiscountCode = discountCodeData => ({
  type: types.DISCOUNT_CODE_RECEIVE,
  discountCodeData
});

export const toggleProductsViewAs = viewAs => ({
  type: types.PRODUCTS_VIEW_AS_TOGGLE,
  viewAs
});

export const updatePageInProductsList = page => ({
  type: types.PRODUCTS_LIST_UPDATE_PAGE,
  page
});

export const selectFilter = filter => ({
  type: types.FILTER_SELECT,
  filter
});

export const updateSelectFilter = (filterName, options) => ({
  type: types.FILTER_SELECT_UPDATE,
  filterName,
  options
});

export const receiveAdvancedSearch = (advancedSearch) => ({
  type: types.ADVANCED_SEARCH_SELECT,
  advancedSearch
});

export const receiveApplyFilters = applyFilters => ({
  type: types.APPLY_FILTERS_SELECT,
  applyFilters
});

export const receiveSelectedCategory = selectedCategory => ({
  type: types.SELECTED_CATEGORY_RECEIVE,
  selectedCategory
});

export const receiveSelectedPlatforms = platforms => ({
  type: types.SELECTED_PLATFORMS_RECEIVE,
  platforms
});

export const receiveSearchByCategory = category => ({
  type: types.PRODUCTS_SEARCH_BY_CATEGORY,
  category
});

export const resetAdvancedSearch = () => ({
  type: types.RESET_ADVANCED_SEARCH,
});

export const updateIsFormEmpty = values => ({
  type: types.EMPTY_ADVANCED_SEARCH,
  payload: values
});

export const updateAdvancedSearch = values => ({
  type: types.UPDATE_ADVANCED_SEARCH,
  payload: values
});