import {
  QUERY_PARAM_ESRB_RATING,
  QUERY_PARAM_MPAA_RATING
} from "scenes/Store/scenes/ProductsList/constants/query-params";

export let FILTERS = [
  {
    name: QUERY_PARAM_ESRB_RATING,
    label: "ESRB Rating",
    slug: "esrb-rating",
    options: [
      {
        value: "",
        label: "Select a esrb rating"
      },
      {
        value: "n_a",
        label: "N/A"
      },
      {
        value: "e",
        label: "E"
      },
      {
        value: "t",
        label: "T"
      },
      {
        value: "m",
        label: "M"
      }
    ]
  },
  {
    name: QUERY_PARAM_MPAA_RATING,
    label: "MPAA Rating",
    slug: "mpaa-rating",
    options: [
      {
        value: "",
        label: "Select a mpaa rating"
      },
      {
        value: "n_a",
        label: "N/A"
      },
      {
        value: "e",
        label: "E"
      },
      {
        value: "t",
        label: "T"
      },
      {
        value: "m",
        label: "M"
      }
    ]
  },
  {
    name: "format",
    label: "Format",
    slug: "format",
    options: [
      {
        value: "",
        label: "Select a movie format"
      },
      {
        value: "0",
        label: "Widescreen"
      },
      {
        value: "1",
        label: "Fullscreen"
      },
      {
        value: "2",
        label: "Special Edition"
      }
    ]
  }
];