import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import useQueryParams from "hooks/useQueryParams"
import "./styles/SearchBasedAvailability.scss"
import {LoadingCassette} from "@vintagestock/vintage-common-components"
import useLocations from "scenes/Store/hooks/useLocations"
import StoreInfoCard from "scenes/Store/components/StoreAvailabilityModal/components/StoreInfoCard"
import {QUERY_PARAM_STORE} from "scenes/Store/scenes/ProductsList/constants/query-params"
import StoreAccordion
  from "scenes/Store/components/StoreAvailabilityModal/components/SearchBasedAvailability/components/StoreAccordion"

export default function SearchBasedAvailability({stores, locations: locationsFromSearch, isLoading}) {
  const queryParams = useQueryParams()
  const {locations} = useLocations()
  // I'm converting by default into an array because currently there's only one result
  // as the client asked to change it so the user can select only 1
  // TODO SINGULAR STORES Fix this problem
  let storesFromParams = queryParams.find(QUERY_PARAM_STORE) ? [queryParams.find(QUERY_PARAM_STORE)] : []
  storesFromParams = storesFromParams ? storesFromParams.map(store => parseInt(store)) : []
  const intersection = _.intersection(storesFromParams, stores)
  const difference = _.difference(stores, storesFromParams)

  return (
      <div className="search-based-availability">
        {
          intersection.length > 0 &&
          <>
            <p className="subtitle"><strong>Based on your search, available in:</strong></p>
            {
              intersection.map(store => (
                  <StoreAccordion
                      key={store}
                      store={_.find(locations, {id: store})}/>
              ))
            }
          </>
        }
        {
          (locationsFromSearch && locationsFromSearch.length > 0 && difference.length > 0)
              ?
              <div className="search-based-availability__also-in">
                <p className="subtitle">
                  {
                    intersection.length > 0
                        ?
                        <strong>Also available in:</strong>
                        :
                        <strong>Currently available in:</strong>
                  }
                </p>
                {
                  difference.map(store => {
                    const found = _.find(locationsFromSearch, {id: store})

                    if (!found) return null

                    return (
                        <StoreInfoCard
                            key={store}
                            store={found}/>
                    )
                  })
                }
              </div>
              :
              (
                  isLoading
                      ?
                      <div>
                        <LoadingCassette/>
                      </div>
                      :
                      <div>
                        <p>We didn't find any stores matching your search criteria.</p>
                      </div>
              )

        }
      </div>
  )
}

SearchBasedAvailability.propTypes = {
  stores: PropTypes.array,
  isLoading: PropTypes.bool
}