import { connect } from 'react-redux';
import Wishlist from "../index";
import {receiveWishListItems} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveWishListItems: creditCards => dispatch(receiveWishListItems(creditCards))
  };
};

const WishListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Wishlist);

export default WishListContainer;