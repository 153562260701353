import { connect } from 'react-redux';
import Header from "../index";
import {receiveCart} from "scenes/Store/actions/index";
import {setMainMenuIsOpen} from "components/MainMenu/actions/index";

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCart: cart => dispatch(receiveCart(cart)),
    setMainMenuIsOpen: mainMenuIsOpen => dispatch(setMainMenuIsOpen(mainMenuIsOpen))
  };
};

const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

export default HeaderContainer;