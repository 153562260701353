import React, {Component} from 'react';
import thanksImage from "./img/thanks-bg@2x.png";
import {Link} from "react-router-dom";
import {isAuthenticated} from "services/auth/index";
import SEO from "components/SEO/index";
import SpamWarning from "scenes/Store/scenes/Checkout/scenes/Success/SpamWarning/index";

class Success extends Component {
  componentDidMount() {
    this.props.emptyCart();
  }

  render() {
    const {orderUUID} = this.props.match.params;
    return (
        <section id="cart">
          <div className="wrapper">
            <SpamWarning/>
            <div className="empty-message thanks">
              <h1>Thank you, your order has been placed.</h1>
              <p>An email confirmation has been sent to you.</p>
              <br/>
              <p className="text-center">
                <img
                    src={thanksImage}
                    className="img-responsive"
                    alt="Your order has been placed"/>
              </p>
              <br/>
              <Link
                  to={!isAuthenticated() ? `/store/order/${orderUUID}` : {
                    pathname: "/my-account/order-history",
                    search: !isAuthenticated() ? '?from=/my-account/order-history' : null
                  }}
                  className="btn btn-block">
                GO TO ORDER
              </Link>
            </div>
          </div>
          <SEO url='checkout/success'/>
        </section>
    )
  }
}

export default Success