import { connect } from 'react-redux';
import CreditCard from "../index";
import {removeCreditCard, selectCreditCard, setCreditCardAsDefault} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectCreditCard: creditCard => dispatch(selectCreditCard(creditCard)),
    removeCreditCard: creditCard => dispatch(removeCreditCard(creditCard)),
    setCreditCardAsDefault: creditCard => dispatch(setCreditCardAsDefault(creditCard))
  };
};

const CreditCardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCard);

export default CreditCardContainer;