import { connect } from 'react-redux';
import OrderDetails from "../index";
import {selectOrder} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrder: order => dispatch(selectOrder(order))
  };
};

const OrderDetailsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderDetails);

export default OrderDetailsContainer;