import React, {Component} from 'react';
import classNames from "classnames/bind";
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "scenes/Home/index";
import Footer from "components/Footer/index";
import ProductDetailsContainer from "scenes/Store/scenes/ProductDetails/container";
import LoginContainer from "scenes/Auth/scenes/Login/container/index";
import SignUpContainer from "scenes/Auth/scenes/SignUp/container/index";
import MyAccountContainer from "scenes/MyAccount/container/index";
import LogoutContainer from "scenes/Auth/scenes/Logout/container/index";
import CartContainer from "scenes/Store/scenes/Cart/container/index";
import CheckoutContainer from "scenes/Store/scenes/Checkout/container/index";
import About from "scenes/About";
import Locations from "scenes/Locations";
import Contact from "scenes/Contact";
import HeaderContainer from "components/Header/container/index";
import ProductsListContainer from "scenes/Store/scenes/ProductsList/container/index";
import AdvancedSearchContainer from "scenes/Store/scenes/AdvancedSearch/container/index";
import FiltersOptionsContainer
  from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/components/FiltersOptions/container/index";
import ForgotPasswordContainer from "scenes/Auth/scenes/ForgotPassword/container/index";
import GiftCards from "scenes/GiftCards/index";
import GuestOrderDetailsContainer from "scenes/MyAccount/scenes/GuestOrderDetails/container/index";
import MainMenuContainer from "components/MainMenu/container/index";
import SearchBarContainer from "components/SearchBar/container/index";
import WishlistShare from "scenes/MyAccount/scenes/WishlistShare/index";
import {WishlistSharePage} from "scenes/MyAccount/scenes/WishlistShare/components/WishlistSharePage";
import ResetPassword from "scenes/Auth/scenes/ResetPassword/index";
import RecentlyViewed from "scenes/Store/scenes/RecentlyViewed/index";
import OldProductsDetails from "utils/components/OldVintageProduct/OldProductDetails/index";
import NotFound from "scenes/ServerErrors/scenes/NotFound";
import MainMenuBlockerContainer from "components/MainMenu/components/MainMenuBlocker/container/index";
import FiltersListV2 from "scenes/Store/scenes/FiltersListV2"
import ValidateEmailExpired from "scenes/Auth/scenes/ValidateEmailExpired"
import ValidateEmailContainer from "scenes/Auth/scenes/ValidateEmail/container"

class VintageStock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainMenuIsOpen: false
    };
  }

  /**
   * Verify if pathname is auth
   */
  get isAuth() {
    const {pathname} = this.props.location;
    return pathname.indexOf('auth') > -1;
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.location) !== JSON.stringify(prevProps.location)) {
      const blacklist = [
        "/store/filters"
      ]
      if (blacklist.indexOf(this.props.location.pathname) === -1) {
        const appContainer = document.getElementsByClassName('vintagestock-app__page')[0];
        setTimeout(appContainer.scrollTo(0, 0), 100);
      }
    }
  }

  render() {
    const {mainMenuIsOpen} = this.props.mainMenuReducer;

    return (
        <div className={classNames({
          "vintagestock-app": true
        })}>
          <div className={classNames({
            "vintagestock-app__page": true,
            "vintagestock-app__page--menu-open": mainMenuIsOpen,
            "vintagestock-app__page--menu-open--bg-auth": this.isAuth
          })}>
            <HeaderContainer
                hideCart={this.isAuth}/>
            {
              !this.isAuth &&
              <SearchBarContainer/>
            }
            {/*Routes for Old Vintage Stock*/}
            <Switch>
              <Route exact path="/products/:productSlug([a-zA-Z0-9.-]+.html)" component={OldProductsDetails}/>
              <Route exact path="/:productSlug([a-zA-Z0-9.-]+.html)" component={OldProductsDetails}/>
              <Route exact path="/products/:categorySlug/:productSlug([a-zA-Z0-9.-]+.html)"
                     component={OldProductsDetails}/>
              <Route exact path="/products/:category1Slug/:category2Slug/:productSlug([a-zA-Z0-9.-]+.html)"
                     component={OldProductsDetails}/>
              <Route exact
                     path="/products/:category1Slug/:category2Slug/:category3Slug/:productSlug([a-zA-Z0-9.-]+.html)"
                     component={OldProductsDetails}/>
              <Route exact path="/products.html" render={() => <Redirect to="/store/products"/>}/>
              <Route exact path="/contacts/" render={() => <Redirect to="/contact"/>}/>
              <Route exact path="/about-vintage-stock/" render={() => <Redirect to="/about"/>}/>
              <Route exact path="/now-hiring" render={() => <Redirect to="/about"/>}/>
              <Route exact path="/privacy-policy" render={() => <Redirect to="/about/privacy-policy"/>}/>
              <Route exact path="/terms-of-use" render={() => <Redirect to="/about/terms-of-use"/>}/>
              <Route exact path="/catalogsearch/advanced/" render={() => <Redirect to="/store/advanced-search"/>}/>
              <Route exact path="/customer/account/login/" render={() => <Redirect to="/auth/login"/>}/>
              <Route exact path="/customer/account/forgotpassword/"
                     render={() => <Redirect to="/auth/forgot-password"/>}/>
              <Route exact path="/customer/account/create/" render={() => <Redirect to="/auth/signup"/>}/>
              <Route exact path="/customer/account/" render={() => <Redirect to="/my-account"/>}/>
              <Route exact path="/customer/account/edit/" render={() => <Redirect to="/my-account/profile"/>}/>
              <Route exact path="/customer/account/edit/changepass/([0-9])/"
                     render={() => <Redirect to="/my-account/password"/>}/>
              <Route exact path="/sales/order/history/" render={() => <Redirect to="/my-account/order-history"/>}/>
              <Route exact path="/customer/address/new/" render={() => <Redirect to="/my-account/address-book"/>}/>
              <Route exact path="/wishlist/" render={() => <Redirect to="/my-account/wishlist"/>}/>
              <Route exact path="/checkout/cart/" render={() => <Redirect to="/store/cart"/>}/>
              <Route exact path="/checkout/onepage/" render={() => <Redirect to="/store/checkout/address"/>}/>

              <Route exact path="/" component={Home}/>
              <Route exact path="/gift-cards" component={GiftCards}/>
              <Route exact path="/store/products" component={ProductsListContainer}/>
              <Route exact path="/store/recently-viewed" component={RecentlyViewed}/>
              <Route exact path="/store/filters" component={FiltersListV2}/>
              <Route path="/store/products/:productSlug" component={ProductDetailsContainer}/>
              <Route exact path="/store/cart" component={CartContainer}/>
              <Route exact path="/store/order/:orderUUID" component={GuestOrderDetailsContainer}/>
              <Route path="/store/checkout" component={CheckoutContainer}/>
              <Route exact path="/store/advanced-search" component={AdvancedSearchContainer}/>
              <Route path="/store/advanced-search/filter" component={FiltersOptionsContainer}/>
              <Route path="/my-account" component={MyAccountContainer}/>
              <Route path="/wishlist/:wishListId" component={WishlistShare}/>
              <Route path="/share/wishlist" component={WishlistSharePage}/>
              <Route path="/about" component={About}/>
              <Route path="/contact" component={Contact}/>
              <Route path="/locations" component={Locations}/>
              <Route exact path="/auth/forgot-password" component={ForgotPasswordContainer}/>
              <Route exact path="/auth/reset-password/:uuidToken" component={ResetPassword}/>
              <Route exact path="/auth/login" component={LoginContainer}/>
              <Route exact path="/auth/signup" component={SignUpContainer}/>
              <Route exact path="/auth/logout" component={LogoutContainer}/>
              <Route exact path="/auth/validate-email/expired" component={ValidateEmailExpired}/>
              <Route path="/auth/validate-email/:token" component={ValidateEmailContainer}/>
              <Route component={NotFound}/>
            </Switch>
            {
              !this.isAuth &&
              <Footer/>
            }
          </div>
          <MainMenuContainer/>
          <MainMenuBlockerContainer/>
        </div>
    )
  }
}

export default VintageStock;