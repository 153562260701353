import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import {Panel, PanelGroup} from "react-bootstrap";
import AsyncGoogleMap from "./components/AsyncGoogleMap/index";
import Location from "./components/Location/index";
import {
  LOCATION_MILE_BY_DEFAULT, LOCATIONS_MILE, SEARCH_QUERY_BY_DEFAULT,
  STORE_TYPES
} from "scenes/Locations/constants/constants";
import SEO from "components/SEO/index";
import "./styles/Locations.scss";

class Locations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: SEARCH_QUERY_BY_DEFAULT,
      locationMile: LOCATION_MILE_BY_DEFAULT,
      loadingLocations: false,
      locations: null,
      mapCenter: {
        lat: 37.05578,
        lng: -94.515577
      },
      activeLocation: ''
    };

    this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
    this.onLocationPanelClick = location => this._onLocationPanelClick.bind(this, location);
    this.handleLocationMileChange = this._handleLocationMileChange.bind(this);
    this.searchQuery = this._searchQuery.bind(this);
    this.openInfoWindow = this._openInfoWindow.bind(this);
    this.closeInfoWindow = this._closeInfoWindow.bind(this);
  }

  componentDidMount() {
    this.fetchLocations().then(response => {
      this.setState({
        loadingLocations: false,
        locations: response.data.results
      }, () => {
        // Simulate click in the first location
        if (response.data && response.data.results && response.data.results.length > 0){
          this._onLocationPanelClick(response.data.results[0]);
        }
      });
    });
  }

  /**
   * Get locations from the API
   * @returns {AxiosPromise}
   */
  fetchLocations() {
    const params = {};
    const {searchQuery, locationMile} = this.state;
    if (searchQuery)
      params['search'] = searchQuery;
    if (locationMile)
      params['miles'] = locationMile;
    const promise = vintageAxios.get('/misc/locations/', {
      params: params
    });
    return promise;
  }

  /**
   * Handle changes on search query input field
   * @param {Object} event
   */
  handleSearchQueryChange(event) {
    const value = event.target.value;
    this.setState({
      searchQuery: value
    });
  }

  /**
   * Change the center of the map to the selected location
   * @param {Object} location
   */
  _onLocationPanelClick(location) {
    this.setState({
      mapCenter: {
        lat: location.geo_lat,
        lng: location.geo_long
      }
    }, () => {
      // In this case infoWindow is open. Will close
      if (location.showInfoWindow)
        this._closeInfoWindow(location);
      else
        this._openInfoWindow(location);
    });
  }

  static getStoreTypeDisplay(status) {
    for (let [key, value] of STORE_TYPES) {
      if (key === status) return value;
    }
  }

  /**
   * Handle change of mile
   * @param event
   */
  _handleLocationMileChange(event) {
    this.setState({
      locationMile: event.target.value
    })
  }

  /**
   * Execute the search query
   */
  _searchQuery() {
    this.setState({
      loadingLocations: true
    }, () => {
      this.fetchLocations().then(response => {
        this.setState({
          loadingLocations: false,
          locations: response.data.results
        }, () => {
          // Simulate click in the first location
          if (response.data && response.data.results && response.data.results.length > 0){
            this._onLocationPanelClick(response.data.results[0]);
          }
        });
      });
    });
  }

  /**
   * Open info window and save active location
   * @param location
   */
  _openInfoWindow(location) {
    this.setState({
      locations: this.state.locations.map(_location => {
        _location.showInfoWindow = _location === location;
        return _location;
      }),
      activeLocation: location.id
    });
  }

  /**
   * close info window and reset active location
   * @param location
   */
  _closeInfoWindow(location) {
    this.setState({
      locations: this.state.locations.map(_location => {
        if (_location === location)
          _location.showInfoWindow = false;
        return _location;
      }),
      activeLocation: ''
    });
  }

  render() {
    const {searchQuery, locations, loadingLocations, mapCenter, locationMile, activeLocation} = this.state;
    const getHeader = (storeType, name) => {
      return (
          <div>
            <span dangerouslySetInnerHTML={{__html: Locations.getStoreTypeDisplay(storeType)}}/>&nbsp;-&nbsp;{name}
          </div>
      );
    };

    return (
        <section className="page-content">
          <div className="container">
            <h1>Find your nearest store</h1>
            <div className="wrapper locations">
              <div className="row">
                <div className="col-sm-12">
                  <div className="finder">
                    <form className="form-inline">
                      <label htmlFor="search_location">Enter your Address or Zipcode</label>
                      <div className="custom-input">
                        <div className="input">
                          <input
                              id="search_location"
                              autoComplete="off"
                              className="form-control"
                              value={searchQuery}
                              onChange={this.handleSearchQueryChange}
                              placeholder="Enter your Address or Zipcode"/>
                        </div>
                        <div className="submit">
                          <span className="vline"/>
                          <button
                              type="button"
                              aria-label={"search location button"}
                              className="btn"
                              onClick={this.searchQuery}>
                            <span className="icon-loup" aria-hidden="true"/>
                          </button>
                        </div>
                      </div>
                      <div className="row location__wrapper-button">
                        <div className="col-xs-7">
                          <div className="form-group">
                            <div className="form-field">
                              <select
                                  value={locationMile}
                                  name="location_mile"
                                  className="form-control location-select-mile"
                                  onChange={this.handleLocationMileChange}>
                                <option value='' disabled={true}>
                                  Select radius
                                </option>
                                {
                                  LOCATIONS_MILE.map(mile => (
                                      <option
                                          key={`location_mile${mile}`}
                                          value={mile}>
                                        {mile} mile{mile > 1 ? 's' : ''}
                                      </option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-5">
                          <div className="form-group">
                            <button
                                type="button"
                                className="btn-custom"
                                onClick={this.searchQuery}>
                              SEARCH
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="results">
                      {
                        locations === null || loadingLocations ? (
                            <LoadingCassette/>
                        ) : (locations.length > 0 ? (
                                <PanelGroup
                                    accordion
                                    id={"location-accordion"}
                                    activeKey={activeLocation}>
                                  {
                                    locations.map((location, index) => (
                                        <Panel
                                            key={`vintageStockLocation${index}`}
                                            header={getHeader(location.store_type, location.name)}
                                            eventKey={location.id}
                                            collapsible
                                            onClick={this.onLocationPanelClick(location)}>
                                          <Location
                                              key={`vintageStockLocation${location.id}`}
                                              location={location}/>
                                        </Panel>
                                    ))
                                  }
                                </PanelGroup>
                            ) : (
                                <p className="text-center">
                                  No locations found for your search "{searchQuery}".
                                </p>
                            )
                        )
                      }
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="col-sm-12">
                  <AsyncGoogleMap.Map
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD8D-unY-MvXdWLGgNqZeNll3K7ohCaelw"
                      loadingElement={
                        <LoadingCassette/>
                      }
                      containerElement={
                        <div id="map"/>
                      }
                      mapElement={
                        <div style={{height: `100%`, width: `100%`}}/>
                      }
                      center={mapCenter}
                      locations={locations}
                      openInfoWindow={this.openInfoWindow}
                      closeInfoWindow={this.closeInfoWindow}/>
                </div>
              </div>
            </div>
          </div>
          <SEO url='locations'/>
        </section>
    )
  }
}

export default Locations
