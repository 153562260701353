import { connect } from 'react-redux';
import OrderReturn from "../index";
import {selectOrder, validateOrderItemsToBeReturned} from "scenes/MyAccount/actions/index";
import {validateReturnReasonOrderItem} from "scenes/MyAccount/actions";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectOrder: order => dispatch(selectOrder(order)),
    validateOrderItemsToBeReturned: () => dispatch(validateOrderItemsToBeReturned()),
    validateReturnReasonOrderItem: itemsToBeReturned => dispatch(validateReturnReasonOrderItem(itemsToBeReturned))
  };
};

const OrderReturnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderReturn);

export default OrderReturnContainer;