import React, {Component} from 'react';
import {Panel, PanelGroup} from "react-bootstrap";
import Breadcrumb from "utils/components/Breadcrumb/index";
import {FREQUENTLY_ASKED_QUESTIONS} from './constants/faqs';
import SEO from "components/SEO/index";
import "./styles/FAQ.scss";

const PanelComponent = ({header, eventKey, nodeHtml, id}) => {
  return (
      <div
          className="about-faq__wrapper-panel-content"
          id={id}>
        <Panel header={header}
               eventKey={eventKey} collapsible>
          <span dangerouslySetInnerHTML={{__html: nodeHtml}}/>
        </Panel>
      </div>);
}

class FAQ extends Component {
  render() {
    return (
        <div className="about-faq">
          <Breadcrumb
              crumbs={[
                {title: "About", to: "/about"},
                {title: "FAQ", to: "/about/faq"},
              ]}/>
          <div className="container">
            <div className="wrapper">
              <h1>FAQ</h1>
              <PanelGroup
                  accordion
                  id="faq-accordion"
                  defaultActiveKey="1">
                {
                  FREQUENTLY_ASKED_QUESTIONS.map((item, index) => (
                      <PanelComponent
                          id={item.anchor ? item.anchor : `faq-${index}`}
                          header={item.header}
                          eventKey={(index + 1).toString()}
                          key={index}
                          nodeHtml={item.nodeHtml}/>
                  ))
                }
              </PanelGroup>
            </div>
          </div>
          <SEO url='about/faq'/>
        </div>
    )
  }
}

export default FAQ
