import React, {Component} from 'react';
import vintageHistory from "services/browser-history/index";
import Breadcrumb from "utils/components/Breadcrumb/index";
import WishlistShareForm from "scenes/MyAccount/scenes/WishlistShare/components/WishlistSharePage/WishlistShareForm/index";
import "./styles/WishlistSharePage.scss";
import {INITIAL_STATUS, LOADING_STATUS, SUCCESSFUL_STATUS} from "assets/constants/constants";
import vintageAxios from "services/api/index";
import {getWishListId} from "services/store/wishlist/index";

export class WishlistSharePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: INITIAL_STATUS
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAfterShare = this.onAfterShare.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  /**
   *
   * @param {Object} email
   * @returns {AxiosPromise<any>}
   */
  static sendWishlistToEmail(email) {
    const promise = vintageAxios
        .post(`/management/wish-lists/share/${getWishListId()}/email/`, email);
    return promise;
  }

  /**
   * Handle submit email form.
   * @param {Object} data
   */
  handleSubmit(data) {
    this.setState({
      status: LOADING_STATUS
    }, () => {
      WishlistSharePage
          .sendWishlistToEmail(data).then(response => {
        this.setState({
          status: SUCCESSFUL_STATUS
        });
      });
    });
  }

  /**
   * Callback function for after of share by social
   */
  onAfterShare() {
    vintageHistory.push('/my-account/wishlist');
  }

  /**
   * Callback function for cancel
   */
  cancel() {
    vintageHistory.push('/my-account/wishlist');
  }

  render() {
    const {status} = this.state;

    return (
        <section className="page-content">
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "Wishlist", to: "/my-account/wishlist"},
              ]}/>
          <div className="container">
            <div className="wrapper">
              <h2 className="wishlist-share-page__header__title">Share Wish List</h2>
              <WishlistShareForm
                  onSubmit={this.handleSubmit}
                  status={status}
                  onAfterShare={this.onAfterShare}
                  cancel={this.cancel}/>
            </div>
          </div>
        </section>
    )
  }
}

export default WishlistSharePage;
