import React from "react"
import {useHistory, useLocation} from "react-router";
import QueryParams from "utils/QueryParams";
import {
  QUERY_PARAM_IN_STOCK,
  QUERY_PARAM_PICKUP_AVAILABLE
} from "scenes/Store/scenes/ProductsList/constants/query-params"
import FiltersBox from "scenes/Store/scenes/FiltersListV2/components/FiltersBox"

const options = [
  {
    name: "In Store",
    queryParam: QUERY_PARAM_PICKUP_AVAILABLE
  },
  {
    name: "Online",
    queryParam: QUERY_PARAM_IN_STOCK
  }
]

export default function AvailabilityFilter() {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new QueryParams(location.search)

  /**
   * Handles change event on the checkboxes
   * @param event {Object}
   * @param option {Object}
   */
  function handleChange(event, option) {
    queryParams.update({
      [option.queryParam]: event.target.checked ? true : null
    })
    history.push({
      pathname: location.pathname,
      search: queryParams.asSearchString
    })
  }

  return (
      <FiltersBox
          title="In Store / Online items">
        {
          options.map((option, index) =>
              <div>
                <input
                    type="checkbox"
                    id={index}
                    onChange={event => handleChange(event, option)}
                    value={option}
                    checked={queryParams.find(option.queryParam) !== undefined}/>
                <label htmlFor={index}>
                  <span className="text">&nbsp;&nbsp;{option.name}</span>
                </label>
              </div>
          )
        }
      </FiltersBox>
  )
}