import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {addToCart} from "services/store";
import {
  GIFT_CARDS,
  OTHER_COMPONENTS
} from "scenes/Store/scenes/ProductDetails/components/AddToCartButton/constants/contexts";

class AddToCartButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adding: false,
      added: false
    };

    this.handleAddToCartClick = this.handleAddToCartClick.bind(this);
  }

  /**
   * Add an item to the cart.
   * @returns {AxiosPromise<any>}
   */
  addToCart() {
    const {props} = this;
    const promise = addToCart({
      contentTypeId: props.contentTypeId,
      productId: props.productId,
      quantity: props.quantity,
      condition: props.selectedCondition
    });
    promise.then(response => {
      this.setState({adding: false, added: true}, () => {
        setTimeout(() => {
          this.setState({added: false});
        }, 1500);
      });
      this.props.receiveCartSingleItem(response.data);
    });
    promise.catch(error => {
      this.setState({adding: false});
    });
    return promise;
  }

  /**
   * Handles clicks on add to cart button.
   */
  handleAddToCartClick() {
    return this.setState({
      adding: true
    }, () => {
      return this.addToCart();
    });
  }

  render() {
    const {adding, added} = this.state;
    const {className, onlyIcon, customLabel} = this.props;

    switch (this.props.context) {
      case OTHER_COMPONENTS:
        return (
            <button
                className={className}
                disabled={adding || added}
                onClick={this.handleAddToCartClick}>
              {
                adding ? (
                    <span>
                  <i className="fa fa-spinner fa-spin"/>
                      &nbsp;
                      <span>{onlyIcon ? "" : "Adding..."}</span>
                </span>
                ) : (
                    added ?
                        <span>
                      <i className="fa fa-check"/>
                          {onlyIcon ? "" : "Added" }
                    </span>
                        :
                        <span>
                      <span className="icon icon-Cart"/>
                          &nbsp;
                          {onlyIcon ? "" : customLabel ? customLabel : "Add to Cart"}
                    </span>
                )
              }
            </button>
        );

      case GIFT_CARDS:
        return (
            <a
                disabled={adding || added}
                onClick={this.handleAddToCartClick}>
              {
                adding ? (
                    <div>
                      <span className="icon icon-Cart"/>&nbsp;ADDING...
                    </div>
                ) : (
                    <div>
                      <span className="icon icon-Cart"/>&nbsp;ADD TO CART
                    </div>
                )
              }
            </a>
        );

      default:
        return (null);
    }
  }
}

AddToCartButton.defaultProps = {
  context: OTHER_COMPONENTS,
  onlyIcon: false
};

AddToCartButton.propTypes = {
  contentTypeId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  className: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  selectedCondition: PropTypes.number.isRequired,
  onlyIcon: PropTypes.bool.isRequired,
  customLabel: PropTypes.string
};

export default AddToCartButton;