import { connect } from 'react-redux';
import FiltersOptions from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/components/FiltersOptions/index";
import {receiveCategories, selectFilter, updateSelectFilter} from "scenes/Store/actions/index";
import {change} from "redux-form";

const mapStateToProps = (state) => {
  return {
    filter: state.storeReducer.filter,
    categories: state.storeReducer.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectFilter: (filter, options) => dispatch(updateSelectFilter(filter, options)),
    selectFilter: filter => dispatch(selectFilter(filter)),
    receiveCategories: categories => dispatch(receiveCategories(categories)),
    changeFieldValue: (field, value) => dispatch(
        change('vintageAdvancedSearchForm', field, value)
    )
  };
};

const FiltersOptionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltersOptions);

export default FiltersOptionsContainer;