import React from 'react';

import "./styles/SpamWarning.scss";

const SpamWarning = () => {
  return (
    <div className="checkout-success-alert">
      <i className="icon-mail"/>
      <h4>
        Please check spam filters and add <a href="mailto:no-reply@vintagestock.com">no-reply@vintagestock.com</a> to your address book for the order confirmation/tracking info emails.
      </h4>
    </div>
  );
};

SpamWarning.propTypes = {

};

export default SpamWarning;
