import React from 'react';
import PropTypes from 'prop-types';
import slugify from "utils/snippets/slugify";
import {ORDER_STATUSES} from "scenes/MyAccount/scenes/OrderHistory/components/Order/constants/status";
import {Link} from "react-router-dom";
import OrderItemList from "./components/OrderItemList";

const Order = ({order, showDetailsLink, selectOrder}) => {
  /**
   * Get display version of status from ORDER_STATUSES constant.
   */
  const getStatusDisplay = status => {
    for( let [key, value] of ORDER_STATUSES ){
      if( key === status ) return value;
    }
  };

  /**
   * Handles click on the link component to set selectedOrder in redux Store
   */
  const handleLinkClick = () => {
    selectOrder(order);
  };

  return (

      <div className="order-item">
        <div className="row">
          <div className="col-md-12">
            <div className={`status ${slugify(getStatusDisplay(order.status))}`}>
              <span className="circle"/>
              <span className="text">{getStatusDisplay(order.status)}</span>
            </div>
            <p>
              Order No.
              <span>{order.id}</span>
            </p>
            <p>
              Date:
              <span>{`${new Date(order.created_at).toDateString()}`}</span>
            </p>
            <div className="items-section">
                <OrderItemList itemList={order.items.normal}/>
                <OrderItemList itemList={order.items.cancelled}/>
                <OrderItemList itemList={order.items.shipment}/>
                <OrderItemList itemList={order.items.returned}/>
            </div>
            {
              showDetailsLink &&
              <div className="more-details">
                {/*<div className="more-items">*/}
                {/*<p>You have <strong>(2)</strong> products more</p>*/}
                {/*</div>*/}
                <p>
                  <Link
                      to={`/my-account/order-history/${order.uuid}`}
                      onClick={handleLinkClick}>
                    View Order Details
                  </Link>
                  <span className="icon icon-arrow-right"/>
                </p>
              </div>
            }
          </div>
        </div>
      </div>
  );
};

Order.propTypes = {
  order: PropTypes.object.isRequired,
  showDetailsLink: PropTypes.bool.isRequired
};

Order.defaultProps = {
  showDetailsLink: true
};

export default Order;