import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Location extends Component {

  /**
   * Generate a capitalized string
   * @param {String} string
   */
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  /**
   * Generate a string from an array of days strings
   * @param {Array} days
   */
  getDays(days){
    const first = days[0].substr(0, 3);
    const last = days[days.length - 1].substr(0, 3);
    return days.length > 1 ? `${this.capitalize(first)} - ${this.capitalize(last)}` : this.capitalize(first);
  };

  render(){
    const {location} = this.props;

    return(
        <div>
          <p>
            {location.address_1}{location.address_2 && `, ${location.address_2}`}
          </p>
          <p>
            {location.city_name}, {location.region_name} {location.zip_code}
          </p>
          {
            location.email &&
            <p>Email: <a href={`mailto:${location.email}`}>{location.email}</a></p>
          }
          {
            location.phone_number_1 &&
            <p>Phone: <a href={`tel:${location.phone_number_1}`}>{location.phone_number_1}</a></p>
          }
          {
            location.phone_number_2 &&
            <p>Phone: <a href={`tel:${location.phone_number_2}`}>{location.phone_number_2}</a></p>
          }
          {/*<p>
            {
              location.store_hours.map(hour => (
                  [
                    <span key={`hour${hour.id}`}>
                      <strong>{this.getDays(hour.days)}: </strong> {hour.start_time} – {hour.end_time}
                    </span>,
                    <br key={`hourSpace${hour.id}`}/>
                  ]
              ))
            }
          </p>*/}
        </div>
    )
  }
}

Location.propTypes = {
  location: PropTypes.object.isRequired
};

export default Location;