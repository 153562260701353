import { connect } from 'react-redux';
import CartCoupon from "../index";
import {receiveDiscountCode} from "scenes/Store/actions";

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveDiscountCode: discountCodeData => dispatch(receiveDiscountCode(discountCodeData))
  };
};

const CartCouponContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartCoupon);

export default CartCouponContainer;