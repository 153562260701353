import { connect } from 'react-redux';
import Summary from "../index";

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer,
    checkoutReducer: state.checkoutReducer,
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const SummaryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Summary);

export default SummaryContainer;