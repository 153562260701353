import React, {Component} from 'react';
import Breadcrumb from "utils/components/Breadcrumb/index";
import {getParsedQueryParams, updateQueryParams} from "services/browser-history/index";
import NewsletterSubscribe from "components/NewsletterSubscribe/index";
import SEO from "components/SEO/index";
import AdvancedSearchReduxContainer from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/container/index";
import {
  PRICE_GTE,
  PRICE_LTE,
  PRICE_NEW_GTE,
  PRICE_NEW_LTE,
  PRICE_USED_GTE,
  PRICE_USED_LTE,
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_DESCRIPTION,
  QUERY_PARAM_ESRB_RATING,
  QUERY_PARAM_GENRES, QUERY_PARAM_MEDIA_TYPE, QUERY_PARAM_FORMAT,
  QUERY_PARAM_MPAA_RATING,
  QUERY_PARAM_NAME, QUERY_PARAM_CAST, QUERY_PARAM_SKU
} from "scenes/Store/scenes/ProductsList/constants/query-params";

class AdvancedSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      queryParams: null
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.getInitialValues();
  }

  getInitialValues() {
    const {storeReducer} = this.props;
    let esrbRating = null;
    let mpaaRating = null;
    let mediaType = null;
    let format = null;
    let platform = null;
    const priceGte = getParsedQueryParams()[PRICE_NEW_GTE] || getParsedQueryParams()[PRICE_USED_GTE];
    const priceLte = getParsedQueryParams()[PRICE_NEW_LTE] || getParsedQueryParams()[PRICE_USED_LTE];

    if (Object.keys(storeReducer.advancedSearch).length === 0) {
      if (getParsedQueryParams()[QUERY_PARAM_ESRB_RATING]) {
        esrbRating = getParsedQueryParams()[QUERY_PARAM_ESRB_RATING];
        esrbRating = typeof esrbRating === "object" ? esrbRating : [esrbRating]
      }

      if (getParsedQueryParams()[QUERY_PARAM_MPAA_RATING]) {
        mpaaRating = getParsedQueryParams()[QUERY_PARAM_MPAA_RATING];
        mpaaRating = typeof mpaaRating === "object" ? mpaaRating : [mpaaRating];
      }

      if (getParsedQueryParams()[QUERY_PARAM_MEDIA_TYPE]) {
        mediaType = getParsedQueryParams()[QUERY_PARAM_MEDIA_TYPE];
        mediaType = typeof mediaType === "object" ? mediaType : [mediaType];
      }

      if (getParsedQueryParams()[QUERY_PARAM_FORMAT]) {
        format = getParsedQueryParams()[QUERY_PARAM_FORMAT];
        format = typeof format === "object" ? format : [format];
      }

      if (getParsedQueryParams()["platform"]) {
        platform = getParsedQueryParams()["platform"];
        platform = typeof platform === "object" ? platform : [platform];
      }

      this.setState({
        queryParams: {
          [QUERY_PARAM_NAME]: getParsedQueryParams()[QUERY_PARAM_NAME],
          [QUERY_PARAM_DESCRIPTION]: getParsedQueryParams()[QUERY_PARAM_DESCRIPTION],
          [QUERY_PARAM_SKU]: getParsedQueryParams()[QUERY_PARAM_SKU],
          [PRICE_GTE]: priceGte,
          [PRICE_LTE]: priceLte,
          [QUERY_PARAM_CAST]: getParsedQueryParams()[QUERY_PARAM_CAST],
          [QUERY_PARAM_GENRES]: getParsedQueryParams()[QUERY_PARAM_GENRES],
          [QUERY_PARAM_MEDIA_TYPE]: mediaType,
          platform: platform,
          [QUERY_PARAM_ESRB_RATING]: esrbRating,
          [QUERY_PARAM_MPAA_RATING]: mpaaRating,
          [QUERY_PARAM_FORMAT]: format,
          [QUERY_PARAM_CATEGORIES]: getParsedQueryParams()[QUERY_PARAM_CATEGORIES]
        }
      }, () => {
        this.props.receiveAdvancedSearch(this.state.queryParams);
      });
    }
  }

  /**
   * Handles form submit.
   * @param {Object} data
   */
  handleFormSubmit(data) {
    console.log(data)
    let queryParams = {};
    Object.assign(queryParams, data);

    // For that could apply filter by both conditions
    queryParams[PRICE_NEW_GTE] = data[PRICE_GTE];
    queryParams[PRICE_USED_GTE] = data[PRICE_GTE];
    queryParams[PRICE_NEW_LTE] = data[PRICE_LTE];
    queryParams[PRICE_USED_LTE] = data[PRICE_LTE];
    // Remove price__gte and price__lte properties for that don't send by query params
    delete queryParams[PRICE_GTE];
    delete queryParams[PRICE_LTE];

    updateQueryParams(queryParams, '/store/products');
    this.props.receiveAdvancedSearch(data);
  }

  render() {
    const {queryParams} = this.state;

    return (
        <div>
          <Breadcrumb
              crumbs={[
                {title: "Home", to: "/"},
                {title: "Advanced Search", to: "/store/advanced-search"},
              ]}/>
          <div className="container">
            <div className="wrapper">
              <h1>Advanced Search</h1>
              <AdvancedSearchReduxContainer
                  onSubmit={this.handleFormSubmit}
                  initialValues={queryParams}/>
              <NewsletterSubscribe/>
            </div>
          </div>
          <SEO url="advanced-search"/>
        </div>
    )
  }
}

export default AdvancedSearch;