import React, {Component} from 'react';
import Slider from "react-slick";
import "./styles/CategorySlider.scss";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import {Link} from "react-router-dom";
import vintageAxios from "services/api/index";

class CategorySlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promos: null
    }
  }

  componentDidMount() {
    this.fetchPromos();
  }

  /**
   * Fetch promos
   * @return {Promise<AxiosResponse<any>>}
   */
  fetchPromos() {
    const promise = vintageAxios.get('/misc/site-promos/');
    return promise.then(response => {
      this.setState({
        promos: response.data
      });
    });
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const {promos} = this.state;

    return (
        <section id="slider">
          <div className="home-slider">
            <Slider {...settings}>
              {
                promos && promos.map((promo, index) => (
                    <Link
                        key={`homePromo${index}`}
                        to={promo.url}>
                      <div className="category-box" style={{
                        backgroundImage: `url(${promo.background})`
                      }}>
                        <div className="text">
                          <h3 dangerouslySetInnerHTML={{__html: promo.description}}/>
                        </div>
                        <div className="image" style={{
                          backgroundImage: `url(${promo.image_over})`
                        }}/>
                        <span className={"icon-arrow-right-stroke button-redirect"}></span>
                      </div>
                    </Link>

                  ))
              }
            </Slider>
          </div>
        </section>
    )
  }
}

export default CategorySlider;
