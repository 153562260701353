import { connect } from 'react-redux';
import AddAddressModal from "../index";
import {addOrUpdateAddress, deselectAddress, setAddressBookAsDefault} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deselectAddress: () => dispatch(deselectAddress()),
    addOrUpdateAddress: address => dispatch(addOrUpdateAddress(address)),
    setAddressBookAsDefault: addressBook => dispatch(setAddressBookAsDefault(addressBook))
  };
};

const AddAddressModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAddressModal);

export default AddAddressModalContainer;