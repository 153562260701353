import { connect } from 'react-redux';
import ProductsList from "../";
import {toggleProductsViewAs} from "scenes/Store/actions";

const mapStateToProps = (state) => {
  return {
    productsList: state.storeReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleProductsViewAs: viewAs => dispatch(toggleProductsViewAs(viewAs))
  };
};

const ProductsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsList);

export default ProductsListContainer;