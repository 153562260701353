import React from 'react';
import ReactDOM from 'react-dom';
import {unregister} from 'registerServiceWorker';
import {Provider} from 'react-redux';
import "assets/styles/screen.scss";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "react-select/dist/react-select.css";
import "assets/fonts/icomoon/style.css";
import "assets/fonts/icomoon-2/style.css";
import "assets/fonts/icomoon-3/style.css";
import "assets/styles/VintageStock.scss";
import VintageStockContainer from "app/container/index";
import reduxStore from "redux/store";
import {BrowserRouter, Router} from "react-router-dom";
import vintageHistory from './services/browser-history';

ReactDOM.render(
    <Provider store={reduxStore}>
      <Router history={vintageHistory}>
        <VintageStockContainer/>
      </Router>
    </Provider>, document.getElementById('root'));
unregister();
