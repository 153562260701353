import React from 'react';
import {Route} from "react-router-dom";
import AboutMenu from "scenes/About/scenes/AboutMenu";
import AboutUs from "scenes/About/scenes/AboutUs";
import OurHistory from "scenes/About/scenes/OurHistory/index";
import FAQ from "scenes/About/scenes/FAQ/index";
import CareerOpportunities from "scenes/About/scenes/CareerOpportunities/index";
import TermsOfUse from "scenes/About/scenes/TermsOfUse/index";
import "./styles/About.scss";
import PrivacyPolicy from "scenes/About/scenes/PrivacyPolicy/index";
import ReturnPolicy from "scenes/About/scenes/ReturnPolicy";

const About = ({match}) => (
    <section className="page-content">
      <Route exact path={match.url} component={AboutMenu}/>
      <Route exact path={`${match.url}/us`} component={AboutUs}/>
      <Route exact path={`${match.url}/our-history`} component={OurHistory}/>
      <Route exact path={`${match.url}/faq`} component={FAQ}/>
      <Route exact path={`${match.url}/now-hiring`} component={CareerOpportunities}/>
      <Route exact path={`${match.url}/privacy-policy`} component={PrivacyPolicy}/>
      <Route exact path={`${match.url}/terms-of-use`} component={TermsOfUse}/>
      <Route exact path={`${match.url}/return-policy`} component={ReturnPolicy}/>
    </section>
);

export default About;
