import React, {Component} from 'react';
import {Redirect, useLocation} from "react-router-dom";
import SEO from "components/SEO/index";
import {FROM} from "scenes/Store/scenes/ProductsList/constants/query-params";
import {getParsedQueryParams} from "services/browser-history";
import LoginIsolatedContainer from "scenes/Auth/scenes/Login/components/LoginIsolated/container/index";
import QueryParams from "utils/QueryParams";

export default function Login({authReducer}) {
  const location = useLocation()
  const queryParams = new QueryParams(location.search)

  const from = queryParams.find(FROM)
  let redirectTo = "/my-account"
  if (from)
    redirectTo = from

  if (authReducer.isAuthenticated)
    return <Redirect to={redirectTo}/>;

  return (
      <div className="container">
        <div className="wrapper wrapper--bg-auth-form">
          <h1>Login to your account</h1>
          <br/>
          <LoginIsolatedContainer/>
        </div>
        <SEO url='auth/login'/>
      </div>
  )
}