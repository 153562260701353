import React, {Component} from 'react';
import "./styles/Cart.scss";
import {Link} from "react-router-dom";
import CartTotalsContainer from "scenes/Store/scenes/Cart/components/CartTotals/container/index";
import CartItemContainer from "scenes/Store/scenes/Cart/components/CartItem/container/index";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import CartCouponContainer from "scenes/Store/scenes/Cart/components/CartCoupon/container";
import SEO from "components/SEO/index";
import TrustElements from "utils/components/TrustElements";
import {getCartSession} from "services/store"

function EmptyMessage() {
  return (
      <div className="empty-message">
        <h1>Looks like you haven’t added any items yet.</h1>
        <div className="footer">
          <div className="bg cart"/>
          <p>
            <Link to="/store/products">
              <span className="btn-custom">GO SHOPPING</span>
            </Link>
          </p>
        </div>
      </div>
  )
}

function CartItems({storeReducer}) {
  if (!getCartSession())
    return <EmptyMessage/>
  else if (storeReducer.cart.cart_items.length === 0)
    return <EmptyMessage/>
  else if (storeReducer.cart.cart_items.length > 0)
    return (
        <div>
          <div className="wrapper">
            <h2>ORDER SUMMARY</h2>
            <CartTotalsContainer/>
            <p>
              <Link
                  to="/store/checkout/address"
                  className="btn-custom">
                GO TO CHECKOUT
              </Link>
            </p>
            <br/>
            <TrustElements/>
          </div>
          <CartCouponContainer
              couponBoxStyle="large"/>
          <div className="wrapper">
            {
              storeReducer.cart.cart_items.map(item =>
                  <CartItemContainer
                      item={item}
                      key={`cartItem${item.id}`}/>
              )
            }
          </div>
        </div>
    )
  else
    return <LoadingCassette/>
}

class Cart extends Component {
  /**
   * The Cart data is fetched on Header Component.
   */
  render() {
    const {storeReducer} = this.props;

    return (
        <section id="cart">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>My Cart</h1>
                <div className="alert alert-warning" role="alert" style={{textTransform: "none"}}>
                  If purchasing multiple items, you might expect separate deliveries.
                </div>
                <CartItems
                    storeReducer={storeReducer}/>
              </div>
            </div>
          </div>
          <SEO url="cart"/>
        </section>
    );
  }
}

export default Cart;
