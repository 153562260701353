import {connect} from "react-redux";
import VintageStock from "../index";
import {withRouter} from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    mainMenuReducer: state.mainMenuReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const VintageStockContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VintageStock);

export default withRouter(VintageStockContainer);