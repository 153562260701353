import { connect } from 'react-redux';
import SearchBar from "components/SearchBar/index";
import {setResetQuerySearch} from "components/MainMenu/actions";

const mapStateToProps = (state) => {
  return {
    searchByCategory: state.storeReducer.searchByCategory,
    routerReducer: state.routerReducer,
    mainMenuReducer: state.mainMenuReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setResetQuerySearch: resetQuerySearch => dispatch(setResetQuerySearch(resetQuerySearch))
  };
};

const SearchBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchBar);

export default SearchBarContainer;