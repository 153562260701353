import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CreditCardInfo from "utils/components/CreditCardInfo/index";
import vintageAxios from "services/api/index";
import "./styles/CreditCard.scss";
import classNames from 'classnames/bind';
import reduxStore from "redux/store";
import {addOrUpdateCreditCard, setCreditCardAsDefault} from "scenes/MyAccount/actions";
import {saveCreditCardInLocalStorage} from "scenes/Store/scenes/Checkout/services/storage";

class CreditCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      settingAsDefault: false
    };

    this.handleSetAsDefaultClick = this.handleSetAsDefaultClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  /**
   * Handles clicks on edit button to select credit card in redux store.
   */
  handleEditClick() {
    this.props.selectCreditCard(this.props.creditCard);
  }

  /**
   * Deletes credit card through the API.
   * @returns {AxiosPromise}
   */
  deleteCreditCard() {
    const {creditCard} = this.props;
    const promise = vintageAxios.delete(`/management/customers/credit-cards/${creditCard.id}/`);
    promise.then(response => {
      this.props.removeCreditCard(creditCard);
      this.setState({deleting: false});
    });
    return promise;
  }

  /**
   * Handles clicks on delete button.
   */
  handleDeleteClick() {
    this.setState({
      deleting: true
    }, () => {
      this.deleteCreditCard();
    });
  }

  /**
   * Set credit card as default in the API.
   * @returns {AxiosPromise}
   */
  setAsDefault() {
    const {creditCard} = this.props;
    const promise = CreditCard.setCreditCardAsDefault(creditCard);
    promise.then(response => {
      this.props.setCreditCardAsDefault(response.data);
      this.setState({
        settingAsDefault: false
      });
    });
    return promise;
  }

  static setCreditCardAsDefault(creditCard) {
    const promise = vintageAxios.patch(`/management/customers/credit-cards/${creditCard.id}/`, {
      default: true
    });
    return promise;
  }

  /**
   * 1. Create or update an credit card in the API, dispatch addOrUpdateCreditCard action
   * 2. If this credit card is default, calls CreditCard.setCreditCardAsDefault and dispatch
   *  setCreditCardAsDefault action.
   * 3. Calls saveCreditCardInLocalStorage utils function.
   * @param creditCard
   * @return {AxiosPromise}
   */
  static createOrUpdateCreditCard(creditCard) {
    let promise;
    creditCard.cc_type = 1;
    if (creditCard.id)  // edit mode
      promise = vintageAxios.put(`/management/customers/credit-cards/${creditCard.id}/`, creditCard);
    else  // create mode
      promise = vintageAxios.post('/management/customers/credit-cards/', creditCard);

    promise.then(response => {
      reduxStore.dispatch(addOrUpdateCreditCard(response.data));
      saveCreditCardInLocalStorage(response.data);

      if (creditCard.byDefault) {
        let promiseCreditCardAsDefault = CreditCard.setCreditCardAsDefault(response.data);

        promiseCreditCardAsDefault.then(creditCard => {
          reduxStore.dispatch(setCreditCardAsDefault(creditCard.data));
        });
      }
    });
    return promise
  }

  /**
   * Handles clicks on the set as default button.
   */
  handleSetAsDefaultClick() {
    if (!this.state.settingAsDefault)
      this.setState({
        settingAsDefault: true
      }, () => {
        this.setAsDefault();
      })
  }

  render() {
    const {deleting, settingAsDefault} = this.state;
    const {creditCard} = this.props;

    return (
        <div className={classNames({
          "payment-item": true,
          "payment-item--default": !!creditCard.default
        })}>
          <CreditCardInfo
              creditCard={creditCard}/>
          <p>
            <span className="exp">Expires:</span>
            <span>{creditCard.exp_mo}/{creditCard.exp_yr}</span>
          </p>
          <hr/>
          <div className="payment-item__actions">
            <div className="payment-item__actions__edit">
              <i className="fa fa-pencil"/>
              <a
                  className="options__edit"
                  onClick={this.handleEditClick}>
                <span className="green">Edit</span>
              </a>
            </div>
            <div className="payment-item__actions__delete">
              <i className="fa fa-trash"/>
              <a
                  className="options__delete right"
                  onClick={this.handleDeleteClick}>
                <span className="red">{deleting ? 'Deleting...' : 'Delete'}</span>
              </a>
            </div>
            <div className="payment-item__actions__status">
              {
                creditCard.default ? (
                    <span className="payment-item__actions__status__default">Default</span>
                ) : (
                    settingAsDefault ? (
                        <span
                            className="payment-item__actions__status__setting">Setting...</span>
                    ) : (
                        <a
                            className="payment-item__actions__hover payment-item__actions__status__set-as-default"
                            onClick={this.handleSetAsDefaultClick}>
                          <i className="fa fa-check-square"/>Set as default
                        </a>
                    )
                )
              }
            </div>
          </div>
        </div>
    )
  }
}

CreditCard.propTypes = {
  creditCard: PropTypes.object.isRequired
};

export default CreditCard
