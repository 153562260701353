import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import {saveUser} from "services/auth/index";
import MyProfileReduxFormContainer from "scenes/MyAccount/scenes/MyProfile/MyProfileForm/container/index";
import {getUser} from "services/auth";
import Breadcrumb from "utils/components/Breadcrumb";
import SEO from "components/SEO/index";

class MyProfile extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false,
      success: false,
      errors: {}
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Submit the form and save user information.
   * @param {Object} data
   * @returns {AxiosPromise<any>}
   */
  submit(data){
    const promise = vintageAxios.put('/management/customers/', data);
    promise.then(response => {
      this.setState({
        loading: false,
        success: true
      });
      setTimeout(() => {
        this.setState({success: false});
      }, 1000);
      saveUser(response.data.user);
      // Using getUser instead of response.data.user in order to have exact the
      // same data in the redux store as I have in localStorage.
      this.props.receiveUser(getUser());
    });
    promise.catch(error => {
      this.setState({
        loading: false,
        errors: error.response.data
      });
      // TODO show errors coming from the API
    });
    return promise;
  }

  /**
   * Handles form submit.
   * @param {Object} data
   */
  handleFormSubmit(data){
    return this.setState({
      loading: true
    }, () => {
      this.submit(data);
    });
  }

  render(){
    const {loading, errors, success} = this.state;

    return(
        <div>
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "My Profile", to: "/my-account/profile"},
              ]}/>
          <div className="container">
            <div className="wrapper">
              <h2>
                <span className="icon icon-user"/>
                <span className="text">My Profile</span>
              </h2>
              <MyProfileReduxFormContainer
                  isLoading={loading}
                  errors={errors}
                  success={success}
                  onSubmit={this.handleFormSubmit}/>
            </div>
          </div>
          <SEO url="my-account/profile" />
        </div>
    )
  }
}

export default MyProfile