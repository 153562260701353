import React, {PureComponent} from "react";
import {renderCheckbox} from "utils/forms/renderers";
import {Field, reduxForm} from "redux-form";
import "./styles/FiltersOptionsForm.scss";
import {PropTypes} from 'prop-types';

export class FiltersOptionsForm extends PureComponent {
  render() {
    const {handleSubmit, filter, initialValues} = this.props;

    return (
        <section className="page-content">
          <div className="container">
            <h1>{filter.label}</h1>
            <br/>
            <form
                id="filters-options-form"
                className="form"
                onSubmit={handleSubmit}>
              <div className="wrapper">
                <ul>
                  {
                    filter.options.slice(1).map((option, index) =>
                        <li className="filter" key={`option${index}`}>
                          <label htmlFor={`${option.value}`}>{option.label}</label>
                          <Field
                              name={`${option.value}`}
                              id={`${option.value}`}
                              defaultChecked={initialValues && initialValues[option.value]}
                              component={renderCheckbox}/>
                        </li>
                    )
                  }
                </ul>
              </div>
              <br/>
              <input type="submit" value="APPLY"/>
            </form>
          </div>
        </section>
    );
  }
}

FiltersOptionsForm.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired
  })
};

const FiltersOptionsReduxForm = reduxForm({
  form: 'vintageFiltersOptionsForm'
})(FiltersOptionsForm);

export default FiltersOptionsReduxForm;
