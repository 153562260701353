import React, {Component} from 'react';
import CheckoutAddressFormContainer from "scenes/Store/scenes/Checkout/scenes/SelectAddress/components/CheckoutAddressForm/container/index";
import {Link, Redirect} from "react-router-dom";
import {SHIPPING} from "scenes/Store/scenes/Checkout/scenes/SelectAddress/components/CheckoutAddressForm/components/CheckoutAddress/constants/address-types";
import SEO from "components/SEO/index";
import {isAuthenticated} from "services/auth/index";
import "./styles/SelectAddress.scss";

class SelectAddress extends Component {

  render() {
    const {cart} = this.props.storeReducer;

    /*
    IMPORTANT: This process should always begin in the Cart component, in the particular case of
    this component there won't be problem if the cart is not in the store, could be fetched directly
    on here, but the redirect is only to be consistent in all steps of the checkout process.
     */

    if (!cart)
      return <Redirect to="/store/cart"/>;

    return (
        <section id="cart">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Checkout</h1>
                <div className="alert alert-warning" role="alert" style={{textTransform: "none"}}>
                  If purchasing multiple items, you might expect separate deliveries.
                </div>
                {
                  !isAuthenticated() &&
                  <div className="alert alert-info alert-no-spacing" role="alert">
                    If you already have an account, please&nbsp;<Link
                      to={{
                        pathname: "/auth/login",
                        search: '?from=/store/checkout/address'
                      }}>
                    <strong>login here</strong>
                  </Link>.
                  </div>
                }
                <br/>
                <CheckoutAddressFormContainer
                    addressType={SHIPPING}/>
              </div>
            </div>
          </div>
          <SEO url='checkout/address' />
        </section>
    )
  }
}

export default SelectAddress
