import { connect } from 'react-redux';
import Login from "../index";
import {login} from "scenes/Auth/actions/index";
import {receiveCart} from "scenes/Store/actions/index";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: user => dispatch(login(user)),
    receiveCart: user => dispatch(receiveCart(user))
  };
};

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

export default LoginContainer;