import React, {Component} from 'react';
import {logout as authLogout} from "services/auth";
import {Redirect} from "react-router-dom";
import {removeCartSession} from "services/store";

export default function Logout({logout, emptyCart, emptyAccountInformation, emptyCheckoutInformation}) {
  logout();
  emptyCart();
  emptyAccountInformation();
  emptyCheckoutInformation();
  removeCartSession();
  authLogout();

  return (
      <Redirect to="/"/>
  )
}