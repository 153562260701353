import React, {Component} from 'react';
import PropTypes from "prop-types";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import CheckoutAddressContainer
  from "scenes/Store/scenes/Checkout/scenes/SelectAddress/components/CheckoutAddressForm/components/CheckoutAddress/container/index";
import vintageHistory from "services/browser-history/index";
import {
  BILLING,
  SHIPPING
} from "scenes/Store/scenes/Checkout/scenes/SelectAddress/components/CheckoutAddressForm/components/CheckoutAddress/constants/address-types";
import {isAuthenticated} from "services/auth/index";
import {getStoredAddresses} from "scenes/Store/scenes/Checkout/services/storage/index";
import AddAddressModalContainer from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/container/index";
import vintageAxios from "services/api/index";
import {
  DEFAULT_BY_BILLING,
  DEFAULT_BY_BILLING_AND_SHIPPING,
  DEFAULT_BY_SHIPPING
} from "scenes/MyAccount/scenes/AddressBook/components/Address/constants/index";
import SelectedAddress from "scenes/Store/scenes/Checkout/scenes/SelectPaymentMethod/components/SelectedAddress/index";
import "./styles/CheckoutAddressForm.scss"
import AddressReduxFormContainer
  from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressForm/container";
import Address from "scenes/MyAccount/scenes/AddressBook/components/Address";
import reduxStore from "redux/store";
import {submit} from 'redux-form'
import {ADDRESS_FORM_NAME} from "scenes/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressForm/constants";
import {withRouter} from "react-router-dom"

class CheckoutAddressForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressListIsOpen: false,
      loading: false,
      success: false,
      errors: {}
    };

    this.onAfterCreateAddress = this.onAfterCreateAddress.bind(this);
    this.handleChooseShippingMethodButton = this.handleChooseShippingMethodButton.bind(this);
    this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
    this.handleAddressFormSubmit = this.handleAddressFormSubmit.bind(this);
    this.handleNewAddressButton = this.handleNewAddressButton.bind(this);
  }

  componentDidMount() {
    this.fetchAddresses();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.authReducer.isAuthenticated !== this.props.authReducer.isAuthenticated) && this.props.authReducer.isAuthenticated) {
      this.fetchAddresses()
    }
  }

  onAfterCreateAddress() {
    if (this.props.myAccountReducer.addresses.length === 1) {
      // Just created an address, so the new one is the only one
      this.selectCheckoutShippingAddressByDefault();
    }
  }

  /**
   * Checks if addresses are already in the store and returns a promise to fetch
   * them from the API if not.
   * @returns {AxiosPromise<any>}
   */
  get addressesPromise() {
    const {addresses} = this.props.myAccountReducer;

    // Addresses already fetched, no need to re fetch them
    if (addresses && addresses.length > 0)
      return Promise.resolve({data: []});

    // Simulate promise returning empty array when user is not authenticated
    const promise = isAuthenticated() ?
        vintageAxios.get('/management/customers/addresses/')
        :
        Promise.resolve({data: getStoredAddresses()});
    return promise;
  }

  /**
   * Fetch addresses from the API.
   * @returns {AxiosPromise<any>}
   */
  fetchAddresses() {
    // TODO get addresses by type (billing, shipping or both) in the Checkout page.
    const promise = this.addressesPromise;
    promise.then((response) => {
      const {addressType, checkoutReducer} = this.props;

      if (!this.props.myAccountReducer.addresses)
        this.props.receiveAddresses(response.data);

      if ((addressType === SHIPPING && !checkoutReducer.selectedShippingAddressId)
          ||
          (addressType === BILLING && !checkoutReducer.selectedBillingAddressId))
          // Select address by default only if the default was not already selected
        this.selectCheckoutShippingAddressByDefault();
    });
    return promise;
  }

  /**
   * Select the first address in the list by default
   */
  selectCheckoutShippingAddressByDefault() {
    // TODO look for the address marked as default when that feature is ready
    const {addresses} = this.props.myAccountReducer;
    if (addresses && addresses.length > 0) {
      if (this.props.addressType === SHIPPING) {
        const selectedShippingAddress = addresses.filter(address =>
            address.default
            && (address.address_type === DEFAULT_BY_SHIPPING || address.address_type === DEFAULT_BY_BILLING_AND_SHIPPING))[0] ||
            // otherwise select the first one
            addresses[0];
        this.props.selectCheckoutShippingAddress(selectedShippingAddress.id);
      } else {
        const selectedBillingAddress = addresses.filter(address =>
            address.default
            &&
            (address.address_type === DEFAULT_BY_BILLING || address.address_type === DEFAULT_BY_BILLING_AND_SHIPPING))[0] ||
            // otherwise select the first one
            addresses[0];
        this.props.selectCheckoutBillingAddress(selectedBillingAddress.id);
      }
    }
  }

  /**
   * Handles use this address button click
   * Remote submit with redux form:
   * @see: https://redux-form.com/6.6.2/examples/remotesubmit/
   */
  handleChooseShippingMethodButton() {
    if (this.props.checkoutReducer.selectedShippingAddressId)
      this.nextCheckoutStep();
    else
      reduxStore.dispatch(submit(ADDRESS_FORM_NAME));
  }

  handleChangeBillingAddress() {
    this.setState({addressListIsOpen: !this.state.addressListIsOpen})
  }

  /**
   * Handles address form submit and calls Address.createOrUpdateAddress with form data
   * Dispatch selectCheckoutShippingAddress and calls nextCheckoutStep method
   * @param data
   */
  handleAddressFormSubmit(data) {
    Address.createOrUpdateAddress(data)
        .then(response => {
          this.props.selectCheckoutShippingAddress(response.data.id);
          this.setState({
            loading: false,
            success: true
          }, () => {
            this.nextCheckoutStep()
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            errors: error.response.data
          });
        })
  }

  /**
   * If exists a selected shipping address,redirect to shipping method step
   */
  nextCheckoutStep() {
    if (this.props.checkoutReducer.selectedShippingAddressId)
      this.props.history.push({
        pathname: '/store/checkout/shipping'
      });
  }

  handleNewAddressButton() {
    const address = {}; // A new, empty address
    this.props.selectAddress(address);
  }

  render() {
    const {addresses} = this.props.myAccountReducer;
    const {addressType, checkoutReducer, showSubmitButton, addressFormIsValid} = this.props;
    const {addressListIsOpen, loading, errors, success} = this.state;

    return (
        <div className="checkout-step checkout-step--shipping-address">
          <div className="checkout-step__header">
            <h2>
              <span className="circle">1</span><span>Shipping Address</span>
            </h2>
          </div>
          <div className="checkout-step__body">
            {
              addresses === null ? (
                  <LoadingCassette/>
              ) : (
                  addresses.length === 0 ? (
                      <AddressReduxFormContainer
                          onSubmit={this.handleAddressFormSubmit}
                          isLoading={loading}
                          success={success}
                          errors={errors}
                          removeOptions={true}/>
                  ) : (
                      checkoutReducer.selectedShippingAddressId && !addressListIsOpen ?
                          <div className="selected-shipping-address">
                            <SelectedAddress
                                address={addresses.find(item => item.id === checkoutReducer.selectedShippingAddressId)}/>
                            <div className="billing-address__change-link">
                              <a onClick={this.handleChangeBillingAddress}>Change Address</a>
                            </div>
                          </div>
                          :
                          (
                              <form>
                                {
                                  addresses.map(address =>
                                      <CheckoutAddressContainer
                                          key={`checkoutAddress${address.id}`}
                                          address={address}
                                          addressType={addressType}/>
                                  )
                                }
                              </form>
                          )
                  )
              )
            }
            <div className="checkout-step__body--actions">
              {
                addressListIsOpen &&
                <div className="checkout-step__body--list-actions">
                  <a
                      className="btn btn-block btn-gray"
                      onClick={this.handleNewAddressButton}>
                    ADD NEW ADDRESS
                  </a>
                  <a
                      className="btn btn-block"
                      onClick={this.handleChangeBillingAddress}>
                    USE THIS ADDRESS
                  </a>
                </div>
              }
              {
                showSubmitButton && !addressListIsOpen &&
                <button
                    className="btn btn-block"
                    disabled={!addressFormIsValid}
                    onClick={this.handleChooseShippingMethodButton}>
                  CHOOSE SHIPPING METHOD
                </button>
              }
            </div>
          </div>
          <AddAddressModalContainer onAfterCreated={this.onAfterCreateAddress}/>
        </div>
    )
  }
}

CheckoutAddressForm.propTypes = {
  showSubmitButton: PropTypes.bool.isRequired,
  addressType: PropTypes.string.isRequired
};

CheckoutAddressForm.defaultProps = {
  showSubmitButton: true
};

export default withRouter(CheckoutAddressForm)
