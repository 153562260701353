import {
  QUERY_PARAM_COMING_SOON,
  QUERY_PARAM_MOST_VIEWED, QUERY_PARAM_NEW_THIS_WEEK, VIEW_TYPE
} from "scenes/Store/scenes/ProductsList/constants/query-params";

export const MOST_VIEWED = {
  title: "Most Viewed",
  url: `/products/all/`,
  buttonLabel: "View All Most Viewed",
  categoryParam: {
    [VIEW_TYPE]: QUERY_PARAM_MOST_VIEWED
  }
};

export const THIS_WEEK = {
  title: "New Releases",
  url: `/products/all/`,
  buttonLabel: "View All Recently Added",
  categoryParam: {
    [VIEW_TYPE]: QUERY_PARAM_NEW_THIS_WEEK
  }
};

export const COMING_SOON = {
  title: "Coming soon",
  url: `/products/all/`,
  buttonLabel: "View All Coming Soon",
  categoryParam: {
    [VIEW_TYPE]: QUERY_PARAM_COMING_SOON
  }
};