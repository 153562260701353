import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ProductAdditionalInfo from "./components/ProductAdditionalInfo";
import {
  TAB_ADDITIONAL_INFO,
  TAB_DESCRIPTION
} from "scenes/Store/scenes/ProductDetails/components/ProductTabs/constants/index";

class ProductTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: TAB_DESCRIPTION
    };

    this.changeTab = name => this._changeTab.bind(this, name);
  }

  /**
   * Handle change of tabs
   * @param name
   * @private
   */
  _changeTab(name) {
    this.setState({
      selectedTab: name
    });
  }

  render() {
    const {product} = this.props;

    return (
        <div className="bottom">
          <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className={this.state.selectedTab === TAB_DESCRIPTION ? 'active' : ''}>
              <a onClick={this.changeTab(TAB_DESCRIPTION)}>DESCRIPTION</a>
            </li>
            <li role="presentation" className={this.state.selectedTab === TAB_ADDITIONAL_INFO ? 'active' : ''}>
              <a onClick={this.changeTab(TAB_ADDITIONAL_INFO)}>ADDITIONAL INFO</a>
            </li>
          </ul>
          <div className="tab-content">
            <div className={'tab-pane' + (this.state.selectedTab === TAB_DESCRIPTION ? ' active' : '')}>
              <p dangerouslySetInnerHTML={{__html: product.description}}/>
            </div>
            <div className={'tab-pane' + (this.state.selectedTab === TAB_ADDITIONAL_INFO ? ' active' : '')}>
              <ProductAdditionalInfo
                  product={product}/>
            </div>
          </div>
        </div>
    )
  }
}

ProductAdditionalInfo.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductTabs