import { connect } from 'react-redux';
import HomeProducts from "../index";
import {selectProduct} from "scenes/Store/actions/index";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectProduct: product => dispatch(selectProduct(product))
  };
};

const HomeProductsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeProducts);

export default HomeProductsContainer;