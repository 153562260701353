import React, {useState} from 'react';
import "./styles/MainMenu.scss";
import classNames from "classnames/bind";
import {Link, useHistory, useLocation} from "react-router-dom";
import {
  ALL,
  GIFT_CARDS,
  PRODUCT_CATEGORIES_GROUPS,
  QUERY_SLUG_CATEGORIES,
  RECENTLY_VIEWED
} from "scenes/Store/scenes/ProductsList/constants/product-categories";
import {Panel, PanelGroup} from "react-bootstrap";
import LoginIsolatedContainer from "scenes/Auth/scenes/Login/components/LoginIsolated/container/index";
import {MAIN_MENU} from "scenes/Auth/scenes/Login/components/LoginIsolated/constants/contexts";
import QueryParams from "utils/QueryParams";

export default function MainMenu({authReducer, mainMenuReducer, setMainMenuIsOpen, setResetQuerySearch}) {
  const history = useHistory();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("login-key");
  const queryParams = new QueryParams(location.search);

  function handleClick(queryParam) {
    let filters = queryParam;

    /*Clear the excluyent parameter from url
      * Example: for view_type parameter, will cleaning categories parameter from url*/
    QUERY_SLUG_CATEGORIES.forEach(querySlug => {
      if (Object.keys(queryParam)[0] !== querySlug)
        filters[querySlug] = null;
    });
    onLinkClick();
    queryParams.update(filters);
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    })
  }

  function handleClickPanelToggle(newActiveKey) {
    setActiveKey(newActiveKey !== activeKey ? newActiveKey : '');
  }

  function onLinkClick() {
    setMainMenuIsOpen(false);
    setResetQuerySearch(true);
  }

  return (
      <nav className={classNames({
        "vintagestock-app__main-menu": true,
        "vintagestock-app__main-menu--visible": mainMenuReducer.mainMenuIsOpen
      })}>
        {
          !authReducer.isAuthenticated &&
          <PanelGroup
              accordion
              id="main-menu-accordion"
              activeKey={activeKey}
              expanded={false}>
            <Panel
                header={
                  <div onClick={() => handleClickPanelToggle('login-key')}
                       className="clearfix">
                    <div className="vintagestock-app__main-menu__login-left">
                      <i className="icon icon-user">&nbsp;</i>
                      <span>LOG IN</span>
                    </div>
                    <div className="vintagestock-app__main-menu__login-right">
                      <i className="fa fa-caret-down"/>
                    </div>
                  </div>
                }
                eventKey="login-key">
              <LoginIsolatedContainer
                  onLinkClick={onLinkClick}
                  context={MAIN_MENU}/>
            </Panel>
          </PanelGroup>
        }
        <ul>
          <li>
            <Link
                to="/my-account"
                onClick={onLinkClick}>
              MY ACCOUNT
            </Link>
          </li>
          {
            PRODUCT_CATEGORIES_GROUPS.map((group, indexGroup) =>
                [
                  <li
                      key={`productCategories${indexGroup}`}
                      className="sub-title">
                    {group.title.toUpperCase()}
                  </li>,
                  (
                      group.categories.map((category, index) =>
                          <li
                              key={`group${indexGroup}Category${index}`}>
                            {
                              category.name === ALL ||
                              category.name === RECENTLY_VIEWED ||
                              category.name === GIFT_CARDS ? (
                                  <Link
                                      to={category.to}
                                      onClick={onLinkClick}>
                                    {category.name.toUpperCase()}
                                  </Link>
                              ) : (
                                  <a onClick={() => handleClick(category.reactQueryParams)}>
                                    {category.name.toUpperCase()}
                                  </a>
                              )
                            }
                          </li>
                      )
                  )
                ]
            )
          }
          <li className="sub-title">COMPANY</li>
          <li>
            <Link
                to="/about"
                onClick={onLinkClick}>
              ABOUT US
            </Link>
          </li>
          <li>
            <Link
                to="/contact"
                onClick={onLinkClick}>
              CONTACT US
            </Link>
          </li>
          <li>
            <Link
                to="/locations"
                onClick={onLinkClick}>
              STORE LOCATIONS
            </Link>
          </li>
          <li className="sub-title">CUSTOMER SERVICE</li>
          <li>
            <Link
                to="/about/terms-of-use"
                onClick={onLinkClick}>
              TERMS
            </Link>
          </li>
          <li>
            <Link
                to="/about/privacy-policy"
                onClick={onLinkClick}>
              PRIVACY
            </Link>
          </li>
        </ul>
      </nav>
  )
}