import {
  LOCAL_STORAGE_ADDRESSES_KEY,
  LOCAL_STORAGE_CREDIT_CARDS_KEY
} from "scenes/Store/scenes/Checkout/services/storage/constants";
import {isAuthenticated} from "services/auth/index";

/**
 * Push a new item to an array saved in the local storage
 * @param {String} key
 * @param item
 * @returns {Array} newArray
 */
export function pushToLocalStorageArray(key, item) {
  const foundArray = localStorage.getItem(key);
  let newArray = [item];
  if (foundArray) {
    const parsed = JSON.parse(foundArray);
    if (Array.isArray(parsed)) {
      // search element in array
      const index = parsed.findIndex(elem => elem.id === item.id);
      if (index !== -1)
        parsed[index] = item;
      else
        parsed.push(item);
      newArray = parsed;
    }
  }
  localStorage.setItem(key, JSON.stringify(newArray));
  return newArray;
}

/**
 * Get addresses array stored in local storage
 * @returns {Array}
 */
export function getStoredAddresses() {
  const parsed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ADDRESSES_KEY));
  return Array.isArray(parsed) ? parsed : [];
}

/**
 * Get credit cards array stored in local storage
 * @returns {Array}
 */
export function getStoredCards() {
  const parsed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CREDIT_CARDS_KEY));
  return Array.isArray(parsed) ? parsed : [];
}

/**
 * Save an address in the local storage. Do it only if the user is not authenticated.
 * This method is being used in the src/scenes/MyAccount/actions file.
 * @param {Object} address
 * @returns {Array}
 */
export function saveAddressInLocalStorage(address) {
  return isAuthenticated() ? [] : pushToLocalStorageArray(LOCAL_STORAGE_ADDRESSES_KEY, address);
}

/**
 * Save a credit card in the local storage. Do it only if the user is not authenticated.
 * This method is being used in the src/scenes/MyAccount/actions file.
 * @param {Object} creditCard
 * @returns {Array}
 */
export function saveCreditCardInLocalStorage(creditCard) {
  return isAuthenticated() ? [] : pushToLocalStorageArray(LOCAL_STORAGE_CREDIT_CARDS_KEY, creditCard);
}

/**
 * Clear a credit card in the local storage.
 * This method is being used in the src/services/auth/index file.
 */
export function removeCreditCardInLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_CREDIT_CARDS_KEY);
}

/**
 * Clear an address in the local storage.
 * This method is being used in the src/services/auth/index file.
 */
export function removeAddressInLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_ADDRESSES_KEY);
}