import React from 'react';
import {useHistory, useLocation} from "react-router-dom";
import QueryParams from "utils/QueryParams";
import {getPageSize} from "services/pagination";
import {QUERY_PARAM_PAGE} from "scenes/Store/scenes/ProductsList/constants/query-params";

export default function PaginationFilter({totalItemsCount}) {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new QueryParams(location.search);

  const page = currentPage();
  const pagesCount = Math.ceil(totalItemsCount / getPageSize());

  /**
   * Get current page as number
   * @returns currentPage {number}
   */
  function currentPage() {
    return parseInt(queryParams.find(QUERY_PARAM_PAGE)) || 1;
  }

  /**
   * Navigate to the given page
   * @param page {Number}
   */
  function goToPage(page) {
    queryParams.update({
      [QUERY_PARAM_PAGE]: page
    });
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    });
  }

  /**
   * Handles click on the go to previous page button
   */
  function handleDecreaseBtnClick() {
    goToPage(currentPage() - 1);
  }

  /**
   * Handles click on the go to next page button
   */
  function handleIncreaseBtnClick() {
    goToPage(currentPage() + 1);
  }

  /**
   * Handles change on the page input
   * @param event {Object}
   */
  function handleInputChange(event) {
    const value = event.target.value;
  }

  return (
      <div className="products-pagination">
        <button
            type="button"
            className="btn-views"
            aria-label={"Decrease page"}
            disabled={currentPage() === 1}
            onClick={handleDecreaseBtnClick}>
          <i className="fa fa-angle-left"/>
        </button>
        <input
            type="number"
            className="form-control"
            onChange={handleInputChange}
            aria-label="Current page"
            aria-valuenow={page}
            value={page}/>
        <label>
          &nbsp;&nbsp;of&nbsp;&nbsp;
          <strong>{pagesCount}</strong>
        </label>
        <button
            type="button"
            className="btn-views"
            aria-label={"Decrease page"}
            disabled={currentPage() === pagesCount}
            onClick={handleIncreaseBtnClick}>
          <i className="fa fa-angle-right"/>
        </button>
      </div>
  )
}