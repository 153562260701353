export const SORTING_PARAMS = [
  {
    label: "Recent first",
    value: "-created_at"
  },
  {
    label: "Name (asc)",
    value: "name"
  },
  {
    label: "Name (desc)",
    value: "-name"
  },
  {
    label: "Released (asc)",
    value: "release_date"
  },
  {
    label: "Released (desc)",
    value: "-release_date"
  },
  {
    label: "Price new (asc)",
    value: "price_new"
  },
  {
    label: "Price new (desc)",
    value: "-price_new"
  },
  {
    label: "Price used (asc)",
    value: "price_used"
  },
  {
    label: "Price used (desc)",
    value: "-price_used"
  }
];