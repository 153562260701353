import { connect } from 'react-redux';
import Address from "../index";
import {removeAddress, selectAddress, setAddressBookAsDefault} from "scenes/MyAccount/actions/index";

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectAddress: address => dispatch(selectAddress(address)),
    removeAddress: address => dispatch(removeAddress(address)),
    setAddressBookAsDefault: addressBook => dispatch(setAddressBookAsDefault(addressBook))
  };
};

const AddressContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Address);

export default AddressContainer;