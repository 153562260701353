import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import Breadcrumb from "utils/components/Breadcrumb/index";
import ReturnItemsFormContainer from "./components/ReturnItemsForm/container";
import vintageHistory from "services/browser-history";
import {CANCEL, RETURN} from "scenes/MyAccount/constants/action";
import {ORDERS_DETAILS_CONTEXT} from "components/SEO/constants/contexts";
import SEO from "components/SEO/index";
import {NORMAL_ITEM, SHIPMENT_ITEM} from "scenes/MyAccount/constants/order-item-type";
import OrderDetails from "scenes/MyAccount/scenes/OrderDetails";
import {Redirect} from "react-router-dom";

class OrderReturn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerId: null,
      loading: false,
      nothingSelected: false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /**
   * Fetches the customer info so I can create returns (because customer id is required)
   * @returns {AxiosPromise}
   */
  fetchCustomer() {
    // TODO remove this from here when the API detects customer by Token automatically.
    const promise = vintageAxios.get('/management/customers/');
    promise.then(response => {
      this.setState({customerId: response.data.id});
    });
    return promise;
  }

  /**
   * Fetch orders from the redux store or the API (fallback).
   * @returns {*}
   */
  fetchOrder() {
    const {selectedOrder} = this.props.myAccountReducer;
    const {match} = this.props;
    let promise;
    if (selectedOrder)
      promise = Promise.resolve({
        data: selectedOrder
      });
    else {
      promise = vintageAxios.get(`/management/orders/${match.params.orderUUID}/`);
      promise.then(response => {
        this.props.selectOrder(response.data);
      });
    }
    return promise;
  }

  /**
   * Creates a return or cancellation item depending on the action type name.
   * @param {data} Array
   * @returns {AxiosPromise}
   */
  createReturnItem(urlEndpoint, data) {
    const promise = vintageAxios.post(urlEndpoint, data);
    promise.then(response => {

    });
    return promise;
  }

  /**
   * Handles the <form> submit event.
   * @param {Object} itemsReturned
   */
  handleFormSubmit(itemsReturned) {
    const data = itemsReturned.items.filter(item => {
      return item.will_be_returned;
    }).map(item => {
      return {
        order_item: item.item,
        reasons: item.return_reason
      }
    });

    if (data.length > 0) {
      this.setState({loading: true}, () => {
        const {orderUUID} = this.props.match.params;
        const {action} = this.props.match.params;
        const urlEndpoint = action === CANCEL ? `/management/orders/cancellations/items/`
            : `/management/orders/returns/items/`;
        const urlSuccess = action === CANCEL ? `/my-account/order-history/${orderUUID}/cancel/success`
            : `/my-account/order-history/${orderUUID}/return/success`;
        this.createReturnItem(urlEndpoint, data).then(response => {
          this.setState({loading: false}, () => {
            vintageHistory.push({
              pathname: urlSuccess
            });
          })
        });
      });
    } else {
      this.setState({
        nothingSelected: true
      })
    }
  }

  componentDidMount() {
    this.fetchCustomer();
    this.fetchOrder();
  }

  render() {
    const order = this.props.myAccountReducer.selectedOrder;
    const {nothingSelected, loading} = this.state;
    const action = this.props.match.params.action;

    if (order === null)
      return <LoadingCassette/>;

    const permittedActions = OrderDetails.permittedActions(order);
    if ((action === RETURN && !permittedActions.return) || (action === CANCEL && !permittedActions.cancel))
      return <Redirect to={`/my-account/order-history/${order.uuid}`}/>;

    return (
        <div>
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "Order History", to: "/my-account/order-history"},
                {title: "Order No. " + order.id, to: "/my-account/order-history/" + order.uuid},
                {
                  title: action === CANCEL ? "Cancel" : "Return",
                  to: "/my-account/order-history/" + order.uuid + `/${action}`
                },
              ]}/>
          <div className="container">
            <div className="wrapper return">
              <div className="row">
                <div className="col-md-12">
                  <h6>Choose items to {action}</h6>
                  <p>Select the items that you want to {action}:</p>
                  {
                    nothingSelected && (
                        <div className="alert alert-danger">
                          You have not selected any product yet
                        </div>
                    )
                  }
                  <p>Order No.<span id="order-number" className="value">{order.uuid}</span></p>
                  <p>Date:<span id="date" className="value">{`${new Date(order.created_at).toDateString()}`}</span></p>
                  <hr/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <ReturnItemsFormContainer
                      onSubmit={this.handleFormSubmit}
                      action={action}
                      loading={loading}
                      initialValues={{
                        items: order.items[action === CANCEL ? NORMAL_ITEM : SHIPMENT_ITEM].map(item => ({
                          will_be_returned: false,  // by default for all of them
                          item: item.order_item_data.id,
                          return_reason: null
                        }))
                      }}/>
                </div>
              </div>
            </div>
          </div>
          <SEO url='my-account/order-history/:orderUUID'
               context={ORDERS_DETAILS_CONTEXT}
               information={{title: `${action === CANCEL ? "Cancel" : "Return"} - ${order.id}`}}/>
        </div>
    )
  }
}

export default OrderReturn;