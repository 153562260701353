export const GIFT_CARD_ITEMS = [
  {
    price: 10,
    productId: parseInt(process.env.REACT_APP_GIFT_CARD_10_USD_ID, 10)
  },
  {
    price: 25,
    productId: parseInt(process.env.REACT_APP_GIFT_CARD_25_USD_ID, 10)
  },
  {
    price: 50,
    productId: parseInt(process.env.REACT_APP_GIFT_CARD_50_USD_ID, 10)
  },
  {
    price: 100,
    productId: parseInt(process.env.REACT_APP_GIFT_CARD_100_USD_ID, 10)
  }
];