import * as types from "./types";

export const setMainMenuIsOpen = mainMenuIsOpen => ({
  type: types.MAIN_MENU_IS_OPEN_SET,
  mainMenuIsOpen
});

export const setResetQuerySearch = resetQuerySearch => ({
  type: types.RESET_QUERY_SEARCH_SET,
  resetQuerySearch
});