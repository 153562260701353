import * as types from '../actions/types';

export const initialState = {
  mainMenuIsOpen: false,
  resetQuerySearch: false
};

export const mainMenuReducer = (state=initialState, action) => {
  switch(action.type){

    case types.MAIN_MENU_IS_OPEN_SET:
      return {
        ...state,
        mainMenuIsOpen: action.mainMenuIsOpen
      };

    case types.RESET_QUERY_SEARCH_SET:
      return {
        ...state,
        resetQuerySearch: action.resetQuerySearch
      };
    default:
      return state;
  }
};