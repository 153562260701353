import React, {useEffect, useState} from 'react';
import vintageAxios from "services/api/index";
import ProductContainer from "scenes/Store/scenes/ProductsList/components/Product/container";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import classNames from "classnames/bind";
import "./styles/ProductsList.scss";
import {
  PRICE_GTE,
  PRICE_LTE,
  PRICE_NEW_GTE,
  PRICE_NEW_LTE,
  PRICE_USED_GTE,
  PRICE_USED_LTE,
  QUERY_PARAM_CAST,
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_DESCRIPTION,
  QUERY_PARAM_ESRB_RATING,
  QUERY_PARAM_FORMAT,
  QUERY_PARAM_GAMING_PLATFORM,
  QUERY_PARAM_GENRES, QUERY_PARAM_IN_STOCK,
  QUERY_PARAM_MEDIA_TYPE,
  QUERY_PARAM_MPAA_RATING,
  QUERY_PARAM_NAME,
  QUERY_PARAM_ORDERING,
  QUERY_PARAM_PAGE, QUERY_PARAM_PICKUP_AVAILABLE,
  QUERY_PARAM_SEARCH,
  QUERY_PARAM_SKU,
  QUERY_PARAM_STATUS, QUERY_PARAM_STORE,
  VIEW_TYPE
} from "scenes/Store/scenes/ProductsList/constants/query-params";
import {clearQueryParamFromUrl} from "services/browser-history";
import {getPageSize} from "services/pagination";
import {saveProductsViewAs} from "services/store";
import {PRODUCTS_LIST_AS_GRID, PRODUCTS_LIST_AS_LIST} from "scenes/Store/scenes/ProductsList/constants/constants";
import SEO from "components/SEO/index";
import {PRODUCTS_LIST_CONTEXT} from "components/SEO/constants/contexts";
import {useHistory, useLocation} from "react-router";
import QueryParams from "utils/QueryParams";
import PaginationFilter from "scenes/Store/scenes/ProductsList/components/PaginationFilter";
import AvailabilityCheckboxes from "scenes/Store/scenes/ProductsList/components/AvailabilityCheckboxes"

export default function ProductsList({productsList, toggleProductsViewAs}) {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new QueryParams(location.search);
  const [products, setProducts] = useState(null);
  const [pagination, setPagination] = useState({
    activePage: parseInt(queryParams.find(QUERY_PARAM_PAGE), 10) || 1,
    count: 0
  });

  /**
   * Fetch products the first time
   */
  useEffect(() => {
    if (products === null)
      fetchProducts();
  }, [products]);

  /**
   * Fetch products on every change of the search query
   */
  useEffect(() => {
    setProducts(null);
  }, [location.search])

  // componentDidMount() {
  //   this.fetchProducts();
  // }
  //
  // componentDidUpdate(prevProps) {
  //   if (this.props.location.search !== prevProps.location.search)
  //     this.fetchProducts();
  // }

  /**
   * Fetch products from the API.
   * @returns {AxiosPromise}
   */
  function fetchProducts() {
    setProducts(null);  // reset the list
    const newQueryParams = axiosQueryParams();

    // const ordering = queryParams.find(QUERY_PARAM_ORDERING);
    //
    // // THIS IS SO HARD-CODED, it's a quick fix needed to be in production ASAP
    // if (queryParams.find(VIEW_TYPE) !== "coming-soon" && (!queryParams.find(QUERY_PARAM_SEARCH) && !ordering))
    //   newQueryParams.ordering = !ordering
    //       ? '-created_at'
    //       : ordering;
    // else
    //   newQueryParams.ordering = ordering;

    // queryParams.ordering = queryParams.find(QUERY_PARAM_ORDERING)
    //     ? queryParams.find(QUERY_PARAM_ORDERING)
    //     : '-created_at';

    const promise = vintageAxios.get(`/products/all/`, {
      params: newQueryParams
    });
    promise.then(response => {
      setProducts(response.data.results);
      setPagination({
        ...pagination,
        activePage: parseInt(queryParams.find(QUERY_PARAM_PAGE), 10) || 1,
        count: response.data.count
      });
    });
    return promise;
  }

  function axiosQueryParams() {
    let defaultQueryParams = {};
    const mediaType = queryParams.find(QUERY_PARAM_MEDIA_TYPE);
    const categories = queryParams.find(QUERY_PARAM_CATEGORIES);

    defaultQueryParams['page'] = queryParams.find(QUERY_PARAM_PAGE);
    defaultQueryParams['search'] = queryParams.find(QUERY_PARAM_SEARCH);
    defaultQueryParams['page_size'] = getPageSize();
    defaultQueryParams[QUERY_PARAM_CATEGORIES] = categories;
    defaultQueryParams[QUERY_PARAM_DESCRIPTION] = queryParams.find(QUERY_PARAM_DESCRIPTION);
    defaultQueryParams[QUERY_PARAM_NAME] = queryParams.find(QUERY_PARAM_NAME);
    defaultQueryParams[QUERY_PARAM_FORMAT] = queryParams.find(QUERY_PARAM_FORMAT);
    defaultQueryParams[VIEW_TYPE] = queryParams.find(VIEW_TYPE);
    defaultQueryParams[QUERY_PARAM_STATUS] = queryParams.find(QUERY_PARAM_STATUS);
    defaultQueryParams[QUERY_PARAM_GAMING_PLATFORM] = queryParams.find(QUERY_PARAM_GAMING_PLATFORM);
    defaultQueryParams[QUERY_PARAM_GENRES] = queryParams.find(QUERY_PARAM_GENRES);
    defaultQueryParams[QUERY_PARAM_SKU] = queryParams.find(QUERY_PARAM_SKU);
    defaultQueryParams[QUERY_PARAM_CAST] = queryParams.find(QUERY_PARAM_CAST);
    defaultQueryParams[QUERY_PARAM_IN_STOCK] = queryParams.find(QUERY_PARAM_IN_STOCK);
    defaultQueryParams[QUERY_PARAM_PICKUP_AVAILABLE] = queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE);
    defaultQueryParams[QUERY_PARAM_STORE] = queryParams.find(QUERY_PARAM_STORE);

    defaultQueryParams[QUERY_PARAM_ESRB_RATING] = queryParams.find(QUERY_PARAM_ESRB_RATING);
    defaultQueryParams[QUERY_PARAM_MPAA_RATING] = queryParams.find(QUERY_PARAM_MPAA_RATING);

    // Why the media type reference to categories (DVD, Blu-ray, Games, etc)
    if (mediaType)
      defaultQueryParams[QUERY_PARAM_CATEGORIES] = [
        ...Array.isArray(mediaType) ? mediaType : [mediaType],
        categories
      ];

    if (queryParams.find(PRICE_GTE))
      defaultQueryParams[PRICE_GTE] = parseFloat(queryParams.find(PRICE_GTE));
    if (queryParams.find(PRICE_LTE))
      defaultQueryParams[PRICE_LTE] = parseFloat(queryParams.find(PRICE_LTE));
    if (queryParams.find(PRICE_NEW_GTE))
      defaultQueryParams[PRICE_NEW_GTE] = queryParams.find(PRICE_NEW_GTE);
    if (queryParams.find(PRICE_USED_GTE))
      defaultQueryParams[PRICE_USED_GTE] = parseFloat(queryParams.find(PRICE_USED_GTE));
    if (queryParams.find(PRICE_NEW_LTE))
      defaultQueryParams[PRICE_NEW_LTE] = parseFloat(queryParams.find(PRICE_NEW_LTE));
    if (queryParams.find(PRICE_USED_LTE))
      defaultQueryParams[PRICE_USED_LTE] = parseFloat(queryParams.find(PRICE_USED_LTE));

    return defaultQueryParams;
  }


  /**
   * Go to Filter View
   * @private
   */
  function goToFilters() {
    const newQueryParams = clearQueryParamFromUrl(
        [QUERY_PARAM_CATEGORIES, VIEW_TYPE, QUERY_PARAM_STATUS,
          QUERY_PARAM_GAMING_PLATFORM, PRICE_GTE, PRICE_LTE, PRICE_NEW_GTE,
          PRICE_USED_GTE, PRICE_NEW_LTE, PRICE_USED_LTE]
    );
    queryParams.update({
      QUERY_PARAM_CATEGORIES: null,
      VIEW_TYPE: null,
      QUERY_PARAM_STATUS: null,
      QUERY_PARAM_GAMING_PLATFORM: null,
      PRICE_GTE: null,
      PRICE_LTE: null,
      PRICE_NEW_GTE: null,
      PRICE_USED_GTE: null,
      PRICE_NEW_LTE: null,
      PRICE_USED_LTE: null
    });
    history.push({
      pathname: "/store/filters",
      search: queryParams.asSearchString
    });
  }

  /**
   * Handles clicks on the view as buttons
   * @param {String} viewAs
   * @private
   */
  function handleViewAsButtonClick(viewAs) {
    saveProductsViewAs(viewAs);  // save in local storage
    toggleProductsViewAs(viewAs);
  }


  return (
      <div>
        <section className="products-filter">
          <div className="clearfix container">
            <div className="product-filter-table--view-as products-filter products-filter--view-as">
              <label htmlFor="viewAs">View as:</label>
              <button id="btn-view-as-grid"
                      aria-label={"View products as a grid"}
                      type="button"
                      className={classNames({
                        "btn-views": true,
                        "btn-views--active": productsList.viewAs === PRODUCTS_LIST_AS_GRID
                      })}
                      onClick={() => handleViewAsButtonClick(PRODUCTS_LIST_AS_GRID)}>
                <span className="icon icon-grid"/>
              </button>
              <button id="btn-view-as-list"
                      aria-label={"View products as a list"}
                      type="button"
                      className={classNames({
                        "btn-views": true,
                        "btn-views--active": productsList.viewAs === PRODUCTS_LIST_AS_LIST
                      })}
                      onClick={() => handleViewAsButtonClick(PRODUCTS_LIST_AS_LIST)}>
                <span className="icon icon-list"/>
              </button>
            </div>
            <div className="product-filter-table--filter">
              <button onClick={goToFilters}>
                <span>Filters</span>
                <span className="icon icon-arrow-right"/>
              </button>
            </div>
          </div>
          <div className="clearfix container availability-filters">
            <AvailabilityCheckboxes/>
          </div>
        </section>
        <div className="div-top-pagination-filter">
          <div className="container">
            <form className="form-inline">
              <div className="form-group products-filter products-filter--pagination">
                <div className="products-filter--pagination container">
                  <label>
                    <strong>{pagination.count || 0}</strong> items
                  </label>
                  <PaginationFilter
                      totalItemsCount={pagination.count}/>
                </div>
              </div>
            </form>
          </div>
        </div>
        <section className="products">
          <div className="container">
            {
              products ? (
                  <div>
                    {
                      products.length === 0 ? (
                          <div className="empty-message search">
                            <h1>No Search Results.</h1>
                            <p>Sorry, we couldn’t find what you were looking for.</p>
                          </div>
                      ) : (
                          <React.Fragment>
                            {
                              products.map(product =>
                                  <ProductContainer
                                      key={product.id}
                                      viewAs={productsList.viewAs}
                                      product={product}/>
                              )
                            }
                          </React.Fragment>
                      )
                    }
                    <form className="form-inline">
                      <div className="form-group products-filter products-filter--pagination">
                        <label>
                          <strong>{pagination.count || 0}</strong> items
                        </label>
                        <PaginationFilter
                            totalItemsCount={pagination.count}/>
                      </div>
                    </form>
                  </div>
              ) : (
                  <LoadingCassette/>
              )
            }
          </div>
        </section>
        <SEO url='store/products'
             context={PRODUCTS_LIST_CONTEXT}/>
      </div>
  )
}