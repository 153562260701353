import { connect } from 'react-redux';
import AddToCartButton from "../index";
import {receiveCart, receiveCartSingleItem} from "scenes/Store/actions/index";

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCart: cart => dispatch(receiveCart(cart)),
    receiveCartSingleItem: item => dispatch(receiveCartSingleItem(item))
  };
};

const AddToCartButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddToCartButton);

export default AddToCartButtonContainer;