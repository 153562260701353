import { connect } from 'react-redux';
import FiltersOptionsReduxForm from "scenes/Store/scenes/AdvancedSearch/components/AdvancedSearchForm/components/FiltersOptions/components/FiltersOptionsForm/index";
import {convertArrayToObject} from "utils/snippets/slugify";

const mapStateToProps = (state) => {
  return {
    initialValues: state.storeReducer.filter ?
        convertArrayToObject(state.storeReducer.advancedSearch[state.storeReducer.filter.name], true)
        : {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const FiltersOptionsReduxFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltersOptionsReduxForm);

export default FiltersOptionsReduxFormContainer;