import React, {useContext} from 'react';
import "./styles/ProductGridView.scss"
import NotifyMeWhenAvailableButton from "utils/components/NotifyMeWhenAvailableButton/index";
import AddToCartButtonContainer from "scenes/Store/scenes/ProductDetails/components/AddToCartButton/container/index";
import {ProductContext, ProductImage, ProductIcon} from '@vintagestock/vintage-common-components';
import Moment from "react-moment";
import moment from "moment";
import WishlistHeartBtnContainer from "scenes/Store/components/WishlistHeartBtn/container";
import {RADIO_ONLINE_NEW, RADIO_ONLINE_USED} from "scenes/Store/components/PriceTable/constants/radios"
import PriceTable from "scenes/Store/components/PriceTable"
import ProductAvailableIn from "scenes/Store/scenes/ProductsList/components/Product/components/ProductAvailableIn"
import FindInStoreBtn from "scenes/Store/components/FindInStoreBtn"

export default function ProductGridView({productUrl, onClick}) {
  const {product, condition, quantity, activeRadio} = useContext(ProductContext);

  return (
      <div className="product-in-grid">
        <div className="product-card">
          <div className="product-card__icon">
            <ProductIcon icon={product.icon}/>
          </div>
          <div className="product-card__wishlist-btn">
            <WishlistHeartBtnContainer/>
          </div>
          <ProductImage
              productName={product.name}
              productImageUrl={product.list_image_thumbnail}
              productUrl={productUrl}
              productIcon={product.icon}
              onClick={onClick}/>
          <div className="product-card-info">
            <h6 className="product-card-info__name">
              {product.name}
            </h6>
            {
              moment(product.release_date) > moment(new Date()) &&
              <p className="product-card-info__release-date">
                Release:&nbsp;
                <Moment format="MMM D, YYYY">
                  {product.release_date}
                </Moment>
              </p>
            }
            {
              product.artist &&
              <p className="product-card-info__artist">
                {product.artist}
              </p>
            }
          </div>
          <div className="product-card-actions">
            <PriceTable/>
            {
              product.in_stock || product.pickup_available
                  ?
                  <React.Fragment>
                    {
                      (activeRadio === RADIO_ONLINE_NEW || activeRadio === RADIO_ONLINE_USED)
                          ?
                          <div className="product-card-actions__actions">
                            <AddToCartButtonContainer
                                contentTypeId={product.content_type}
                                productId={product.id}
                                quantity={quantity}
                                productData={{
                                  sku: product.sku,
                                  name: product.name
                                }}
                                className="btn btn-block"
                                selectedCondition={condition}
                                hideIcon={product.is_pre_order}
                                customLabel={product.is_pre_order ? "PRE-ORDER" : "Add to cart"}
                                size="small"/>
                          </div>
                          :
                          /* I've temporarily put the same class as the add to cart button because stylesheets of this project sucks */
                          <>
                            <ProductAvailableIn stores={product.stores}/>
                            <FindInStoreBtn
                                className="btn btn-block"/>
                          </>
                    }
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {
                      product.is_pre_order &&
                      <NotifyMeWhenAvailableButton
                          condition={condition}
                          stockNew={product.total_stock_new}
                          stockUsed={product.total_stock_used}
                          productId={product.id}
                          onlyIcon/>
                    }
                  </React.Fragment>
            }
          </div>
        </div>
      </div>
  );
};

ProductGridView.defaultProps = {
  quantity: 1
};
