import React, {Component} from 'react';
import vintageAxios from "services/api/index";
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import CreditCardContainer from "scenes/MyAccount/scenes/CreditCardsInfo/components/CreditCard/container/index";
import AddCreditCardModalContainer from "scenes/MyAccount/scenes/CreditCardsInfo/components/AddCreditCardModal/container/index";
import "./styles/CreditCardsInfo.scss";
import Breadcrumb from "utils/components/Breadcrumb/index";
import SEO from "components/SEO/index";

class CreditCardsInfo extends Component {
  constructor(props){
    super(props);

    this.handleAddCreditCardButtonClick = this.handleAddCreditCardButtonClick.bind(this);
  }

  /**
   * Fetches credit cards from the API.
   * @returns {AxiosPromise<any>}
   */
  fetchCreditCards(){
    const promise = vintageAxios.get('/management/customers/credit-cards/');
    promise.then(response => {
      this.props.receiveCreditCards(response.data);
    });
    return promise;
  }

  /**
   * Handles clicks on the add credit card button
   */
  handleAddCreditCardButtonClick(){
    const creditCard = {};  // a new, empty credit card
    this.props.selectCreditCard(creditCard);
  }

  componentDidMount(){
    this.fetchCreditCards();
  }

  render(){
    const {creditCards} = this.props.myAccountReducer;

    return(
        <div>
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "Credit Card Info", to: "/my-account/credit-cards"},
              ]}/>
          <div className="container">
            <div className="wrapper payments">
              <div className="credit-card-info-header">
                <div className="filters">
                  <button
                      type="button"
                      className="btn btn-sm"
                      onClick={this.handleAddCreditCardButtonClick}>
                    <span className="icon icon-plus"/>
                    <span>ADD</span>
                  </button>
                </div>
                <h2>
                  <span className="icon icon-card"/>
                  <span className="text">Credit Card Info</span>
                </h2>
              </div>
              {
                creditCards === null ? (
                    <LoadingCassette/>
                ) : (
                    creditCards.length === 0 ? (
                        <div className="empty-message">
                          <h1>Your Credit Card Info is Empty.</h1>
                          <p>Looks like you haven’t add any credit card yet.</p>
                          <div className="footer">
                            <div className="bg payments"/>
                            <p>
                              <button
                                  type="button"
                                  className="btn btn-block"
                                  onClick={this.handleAddCreditCardButtonClick}>
                                ADD A CREDIT CARD
                              </button>
                            </p>
                          </div>
                        </div>
                    ) : (
                        creditCards.map(creditCard =>
                            <CreditCardContainer
                                key={`creditCard${creditCard.id}`}
                                creditCard={creditCard}/>
                        )
                    )
                )
              }
              <AddCreditCardModalContainer/>
            </div>
          </div>
          <SEO url="my-account/credit-cards-info"/>
        </div>
    )
  }
}

export default CreditCardsInfo
