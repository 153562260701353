import React from "react";
import PropTypes from "prop-types"
import "./styles/FiltersBox.scss"

export default function FiltersBox({title, children}) {
  return (
      <div className="filters-box">
        <header className="filters-box__header">
          <h5>{title}</h5>
        </header>
        <div className="filters-box__body">
          {children}
        </div>
      </div>
  )
}

FiltersBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}