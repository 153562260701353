import React from "react"
import useQueryParams from "hooks/useQueryParams"
import {QUERY_PARAM_STORE} from "scenes/Store/scenes/ProductsList/constants/query-params"
import {useHistory} from "react-router-dom"
import "./styles/LocationCard.scss"

function Input({location}) {
  const history = useHistory()
  const queryParams = useQueryParams()

  /**
   * Handles changes on checkboxes
   * @param event {Object}
   */
  function handleChange(event) {
    const {checked, value} = event.target
    if (checked) {
      queryParams.update({
        [QUERY_PARAM_STORE]: value
      })
      history.push({
        pathname: "/store/products",
        search: queryParams.asSearchString
      })
    }
  }

  return (
      <input
          id={`locationCheckbox${location.id}`}
          name="location-checkbox"
          type="radio"
          value={location.id}
          checked={queryParams.find(QUERY_PARAM_STORE) && queryParams.find(QUERY_PARAM_STORE) == location.id}
          onChange={handleChange}/>
  )
}

export default function LocationCard({location}) {
  return (
      <div
          className="location-card">
        <label htmlFor={`locationCheckbox${location.id}`}>
          <div>
            <Input location={location}/>
          </div>
          <div>
            <p>
              <strong>
                {location.name}
              </strong>
            </p>
            <p>
              {location.address_1}{location.address_2 && `, ${location.address_2}`} - {location.city_name}, {location.region_name} {location.zip_code}
            </p>
            {
              location.phone_number_1 &&
              <p>
                Call us&nbsp;
                <a href={`tel:${location.phone_number_1}`}>{location.phone_number_1}</a>&nbsp;
                to pickup or ship from your store. <span className="ship-limit-message">Ship from store maximum order is $100.</span>
              </p>
            }
          </div>
        </label>
      </div>
  )
}