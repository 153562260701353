import * as types from './types';

export const receiveOrders = orders => ({
  type: types.ORDERS_RECEIVE,
  orders
});

export const selectOrder = order => ({
  type: types.ORDER_SELECT,
  order
});

export const clearOrders = () => ({
  type: types.ORDERS_CLEAR
});

export const toggleOrderItemWillBeReturned = item => ({
  type: types.ORDER_ITEM_TOGGLE_RETURN_FLAG,
  item
});

export const updateOrderItemReturnReason = (item, reason) => ({
  type: types.ORDER_ITEM_UPDATE_RETURN_REASON,
  item,
  reason
});

export const validateOrderItemsToBeReturned = () => ({
  type: types.ORDER_ITEMS_TO_BE_RETURNED_VALIDATE
});

export const receiveAddresses = addresses => ({
  type: types.ADDRESSES_RECEIVE,
  addresses
});

export const selectAddress = address => ({
  type: types.ADDRESS_SELECT,
  address
});

export const deselectAddress = () => ({
  type: types.ADDRESS_DESELECT
});

export const addOrUpdateAddress = address => ({
  type: types.ADDRESS_ADD_OR_UPDATE,
  address
});

export const removeAddress = address => ({
  type: types.ADDRESS_REMOVE,
  address
});

export const receiveCreditCards = creditCards => ({
  type: types.CREDIT_CARDS_RECEIVE,
  creditCards
});

export const selectCreditCard = creditCard => ({
  type: types.CREDIT_CARD_SELECT,
  creditCard
});

export const deselectCreditCard = () => ({
  type: types.CREDIT_CARD_DESELECT
});

export const addOrUpdateCreditCard = creditCard => ({
  type: types.CREDIT_CARD_ADD_OR_UPDATE,
  creditCard
});

export const removeCreditCard = creditCard => ({
  type: types.CREDIT_CARD_REMOVE,
  creditCard
});

export const receiveWishListItems = wishListItems => ({
  type: types.WISH_LIST_ITEMS_RECEIVE,
  wishListItems
});

export const addWishListItem = wishListItem => ({
  type: types.WISH_LIST_ITEM_ADD,
  wishListItem
})

export const removeWishListItem = wishListItem => ({
  type: types.WISH_LIST_ITEM_REMOVE,
  wishListItem
});

export const validateReturnReasonOrderItem = itemsToBeReturned => ({
    type: types.ORDER_ITEMS_VALIDATE_RETURN_REASON,
    itemsToBeReturned
});

export const setCreditCardAsDefault = creditCard => ({
  type: types.CREDIT_CARD_SET_AS_DEFAULT,
  creditCard
});

export const setAddressBookAsDefault = addressBook => ({
  type: types.ADDRESS_BOOK_SET_AS_DEFAULT,
  addressBook
});

export const emptyAccountInformation = () => ({
  type: types.EMPTY_ACCOUNT_INFORMATION
});
