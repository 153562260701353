import React, {Component} from 'react';

class MainMenuBlocker extends Component {
  constructor() {
    super();

    this.handleClickOrTouchStart = this.handleClickOrTouchStart.bind(this);
  }

  handleClickOrTouchStart() {
    this.props.setMainMenuIsOpen(false)
  }

  render() {
    const {mainMenuIsOpen} = this.props.mainMenuReducer;

    if (!mainMenuIsOpen)
      return null;

    return (
        <div
            className="vintagestock-app__main-menu-blocker"
            onClick={this.handleClickOrTouchStart}
            onTouchStart={this.handleClickOrTouchStart}/>
    )
  }
}

MainMenuBlocker.propTypes = {};

export default MainMenuBlocker;