import { connect } from 'react-redux';
import SelectAddress from "../index";
import {receiveCart} from "scenes/Store/actions/index";

const mapStateToProps = (state) => {
  return {
    storeReducer: state.storeReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCart: cart => dispatch(receiveCart(cart))
  };
};

const SelectAddressContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectAddress);

export default SelectAddressContainer;