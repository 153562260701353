import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ProductAdditionalInfo extends Component {
  render() {
    let items = [
      'Platform',
      'Featured',
      'Media Type',
      'SKU',
      'Runtime',
      'Genres',
      'MPAA Rating',
      'ESRB Rating',
      'CARA Explanation',
      'Year',
      'Director',
      'Publisher',
      'Cast',
    ];
    items = items.map((item) => {
      let key = item.toLowerCase().replace(' ', '_'),
          value = this.props.product[key];

      const translateValue = (key, value) => {
        switch (typeof value) {
          case 'boolean':
            return value === true ? 'Yes' : 'No';
          case 'object':
            if (value)
              switch (key) {
                case 'genres':
                  return value.map(obj => obj.name).join(', ');
                case 'platform':
                  return value.name;
                default:
                  return value;
              }
            else // In this case the object is null
              return value;
          default:
            return value;
        }
      };

      return {
        name: item,
        value: translateValue(key, value)
      }
    });
    items = items.filter((item) => item.value !== undefined);

    return (
        <div className="table-responsive">
          <table className="table table-bordered">
            <colgroup>
              <col className="col-xs-4"/>
              <col className="col-xs-8"/>
            </colgroup>
            <tbody>
            {
              items.map((item, index) => (
                  <tr key={`productDetail${index}`}>
                    <th>{item.name}</th>
                    <td>{item.value}</td>
                  </tr>
              ))
            }
            </tbody>
          </table>
        </div>
    )
  }
}

ProductAdditionalInfo.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductAdditionalInfo;