import React, {useContext, useState} from "react";
import _ from "lodash";
import classNames from "classnames/bind";
import "./styles/WishlistHeartBtn.scss";
import {ProductContext} from '@vintagestock/vintage-common-components';
import {addToWishList, removeFromWishList} from "services/store/wishlist";
import {useHistory} from "react-router";

export default function WishlistHeartBtn({authReducer, myAccountReducer, addWishListItem, removeWishListItem}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {isAuthenticated} = authReducer;
  const {product, condition} = useContext(ProductContext);
  const {wishListItems} = myAccountReducer;

  const wishListItem = wishListItems ? _.find(wishListItems, {object_id: product.id}) : undefined;
  const alreadyAdded = wishListItem !== undefined;

  /**
   * Handles click on the wishlist button
   * @param event
   */
  function handleClick(event) {
    if (!isAuthenticated)
      history.push({
        pathname: '/auth/login',
        search: `?from=/store/products/${product.slug}`
      });
    else
      alreadyAdded ? remove() : add();
  }

  /**
   * Adds the product to the wishlist
   */
  function add() {
    setLoading(true);
    const promise = addToWishList({
      productId: product.id,
      contentType: product.content_type,
      condition: condition
    });
    promise.then(response => {
      addWishListItem(response.data);
      setLoading(false);
    });
    promise.catch(() => setLoading(false));
  }

  /**
   * Removes the wishlist item from the wishlist
   */
  function remove() {
    if (wishListItem) {
      setLoading(true);
      const promise = removeFromWishList(wishListItem);
      promise.then(response => {
        removeWishListItem(wishListItem);
        setLoading(false);
      });
      promise.catch(() => setLoading(false));
    }
  }

  return (
      <button
          aria-label={`Add to wishlist ${product.name}`}
          className={classNames({
            "wishlist-heart-btn": true,
            "wishlist-heart-btn--active": alreadyAdded
          })}
          onClick={handleClick}
          disabled={loading}>
        <i className="fa fa-heart"/>
      </button>
  );
}