import React, {useEffect, useState} from 'react';
import "./styles/SearchBar.scss";
import {Link} from "react-router-dom";
import {KEY_CODE_ENTER} from "components/SearchBar/constants/key-codes";
import onClickOutside from "react-onclickoutside";
import CategoriesSelect from "components/SearchBar/components/CategoriesSelect";
import {useHistory} from "react-router";
import SuggestionsBox from "components/SearchBar/components/SuggestionsBox";
import useQueryParams from "hooks/useQueryParams"
import {QUERY_PARAM_CATEGORIES, QUERY_PARAM_SEARCH} from "scenes/Store/scenes/ProductsList/constants/query-params"

function SearchBar() {
  const history = useHistory()
  const queryParams = useQueryParams()
  const [query, setQuery] = useState("")
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [selectedProductType, setSelectedProductType] = useState(0)
  const [suggestionsBoxIsOpen, setSuggestionsBoxIsOpen] = useState(null)
  const searchQueryFromParams = queryParams.find(QUERY_PARAM_SEARCH)

  /**
   * Reset the search query whenever the url changes and it's not present in the query params
   */
  useEffect(() => {
    if (searchQueryFromParams === undefined)
      setQuery("")
  }, [searchQueryFromParams])

  /**
   * Toggle open status of the suggestions box.
   */
  function handleInputFocus() {
    setSuggestionsBoxIsOpen(true)
  }

  /**
   * Toggle open status of the suggestions box only if there's no query
   */
  function handleInputBlur() {
    if (!query)
      setSuggestionsBoxIsOpen(false)
  }

  /**
   * Handles submit on <form> to prevent default behaviour.
   * @param {Object} event
   */
  function handleFormSubmit(event) {
    event.preventDefault();
  }

  /**
   * Handles key down events on search input.
   * @param {Object} event
   */
  function handleInputKeyDown(event) {
    if (event.keyCode === KEY_CODE_ENTER) {
      event.preventDefault()  // prevent normal form submit
      handleSearch()
      setSuggestionsBoxIsOpen(false)
      event.target.blur()
    }
  }

  /**
   * handle submit form input search
   * @param event
   */
  function handleSearch(event) {
    queryParams.update({
      [QUERY_PARAM_SEARCH]: query
    })
    // TODO don't use numbers to compare here
    if (selectedCategory > 0)
      queryParams.update({
        [QUERY_PARAM_CATEGORIES]: selectedCategory
      })
    history.push({
      pathname: '/store/products',
      search: queryParams.asSearchString
    })
  }

  /**
   * Handle changes on the search input
   * @param {Object} event
   */
  function handleInputChange(event) {
    setQuery(event.target.value)
  }

  /**
   * Handles changes on the <CategoriesSelect/>
   * @param {Object} category
   */
  function handleCategoryChange(category) {
    setSelectedCategory(category.id)
    setSelectedProductType(category.product_type)
  }

  function onAfterSuggestionClick() {
    setQuery("")
    setSuggestionsBoxIsOpen(false)
  }

  /**
   * Click outside box
   */
  SearchBar.handleClickOutside = () => setSuggestionsBoxIsOpen(false)

  return (
      <section className="search-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form
                  className="search-bar__form"
                  onSubmit={handleFormSubmit}>
                <div className="search-bar__select-wrapper search-bar__select-wrapper-noimage">
                  <CategoriesSelect
                      onChange={handleCategoryChange}/>
                </div>
                <div className="search-bar__input-wrapper has-feedback">
                  <a onClick={handleSearch}>
                    <span className="icon icon-loup"/>
                  </a>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="I’m looking for…"
                      value={query}
                      aria-label="Search field"
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}/>
                  <Link to="/store/advanced-search" aria-label="advanced search">
                    <i className="fa fa-filter form-control-feedback fa-padding"/>
                  </Link>
                </div>
                {
                  suggestionsBoxIsOpen &&
                  <SuggestionsBox
                      query={query}
                      productType={selectedProductType}
                      onAfterSuggestionClick={onAfterSuggestionClick}/>
                }
              </form>
            </div>
          </div>
        </div>
      </section>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => SearchBar.handleClickOutside
};

export default onClickOutside(SearchBar, clickOutsideConfig);
