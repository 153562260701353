import {connect} from "react-redux";
import ValidateEmail from "../index";

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

const ValidateEmailContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ValidateEmail);

export default ValidateEmailContainer;