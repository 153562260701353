import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from "redux-form";
import {Field} from "redux-form";
import {renderInput} from "utils/forms/renderers";
import {required, creditCardCvv, creditCardCvvData} from "utils/forms/validators";

class CVVCodeForm extends Component {
  render() {
    const {handleSubmit} = this.props;
    return (
        <form
            onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="ccv_code">*CVV code</label>
              <Field
                  name="ccv_code"
                  id="ccv_code"
                  type="password"
                  component={renderInput}
                  validate={[required, creditCardCvv, creditCardCvvData]}
                  placeholder="Type the CVV code"
                  className="form-control"/>
            </div>
          </div>
        </form>
    )
  }
}

CVVCodeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const CVVCodeReduxForm = reduxForm({
  form: 'cvvCodeForm'
})(CVVCodeForm);

export default CVVCodeReduxForm;