import * as types from './types';

export const selectCheckoutShippingAddress = addressId => ({
  type: types.CHECKOUT_SHIPPING_ADDRESS_SELECT,
  addressId
});

export const selectCheckoutBillingAddress = addressId => ({
  type: types.CHECKOUT_BILLING_ADDRESS_SELECT,
  addressId
});

export const selectCheckoutCreditCard = creditCardId => ({
  type: types.CHECKOUT_CREDIT_CARD_SELECT,
  creditCardId
});

export const setCheckoutCVVCode = cvvCode => ({
  type: types.CHECKOUT_CVV_CODE_SET,
  cvvCode
});

export const emptyCheckoutInformation = () => ({
  type: types.EMPTY_CHECKOUT_INFORMATION
});
