import React, {Component} from 'react';
import LoadingCassette from "utils/components/misc/LoadingCassette/index";
import {getWishListItems} from "services/store/wishlist/index";
import {Link, withRouter} from "react-router-dom";
import "./styles/WishList.scss";
import WishListItemContainer from "scenes/MyAccount/scenes/WishList/components/WishListItem/container/index";
import vintageAxios from 'services/api';
import Breadcrumb from "utils/components/Breadcrumb/index";
import SEO from "components/SEO/index";
import WishListShareButton from "scenes/MyAccount/scenes/WishlistShare/components/WishlistShareButton/index";

export class WishList extends Component {
  componentDidMount() {
    this.fetchWishListItems();
  }

  /**
   * Fetches wish list from the API.
   * @returns {AxiosPromise<any>}
   */
  fetchWishListItems() {
    const {location, match} = this.props;
    let promise;
    if (location.pathname === "/my-account/wishlist")
      promise = getWishListItems();
    else
      promise = vintageAxios.get(`/management/wish-lists/${match.params.wishListId}/`);

    promise.then(response => {
      this.props.receiveWishListItems(location.pathname === "/my-account/wishlist"
          ? response.data
          : response.data.wish_list_items);
    });
    return promise
  }

  render() {
    const {location} = this.props;
    const {wishListItems} = this.props.myAccountReducer;

    return (
        <div>
          <Breadcrumb
              crumbs={[
                {title: "My Account", to: "/my-account"},
                {title: "Wishlist", to: "/my-account/wishlist"},
              ]}/>
          <div className="container">
            <div className="wrapper wishlist">
              <div className="wishlist__header">
                <h2 className="wishlist__header__title">
                  <span className="icon icon-wishlist-book"/>
                  <span className="text">Wishlist</span>
                </h2>
                <WishListShareButton/>
              </div>
              {
                wishListItems === null ? (
                    <LoadingCassette/>
                ) : (
                    wishListItems.length === 0 ? (
                        <div className="empty-message">
                          <h1>Your Wishlist is Empty.</h1>
                          <p>Looks like you haven’t add any product to your wishlist yet.</p>
                          <div className="footer">
                            <div className="bg wish"/>
                            <p>
                              <Link to="/store/products">
                                <span className="btn-custom">GO TO STORE</span>
                              </Link>
                            </p>
                          </div>
                        </div>
                    ) : (
                        wishListItems.map(wishListItem =>
                            <WishListItemContainer
                                readOnly={location.pathname !== "/my-account/wishlist"}
                                key={`wishListItem${wishListItem.id}`}
                                item={wishListItem}/>
                        )
                    )
                )
              }
            </div>
          </div>
          <SEO url="my-account/wishlist"/>
        </div>
    )
  }
}

export default withRouter(WishList)
