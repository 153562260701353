import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import _ from 'lodash';
import {Meta} from "components/SEO/constants/meta";
import PropTypes from "prop-types";
import {
  ORDERS_DETAILS_CONTEXT, OTHER_CONTEXT, PRODUCTS_DETAILS_CONTEXT,
  PRODUCTS_LIST_CONTEXT
} from "components/SEO/constants/contexts";
import vintageAxios from "services/api/index";
import {withRouter} from "react-router-dom";
import {getParsedQueryParams} from "services/browser-history/index";
import {QUERY_PARAM_CATEGORIES, VIEW_TYPE} from "scenes/Store/scenes/ProductsList/constants/query-params";
import {PRODUCT_CATEGORIES_GROUPS} from "scenes/Store/scenes/ProductsList/constants/product-categories";

class SEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      hierarchyCategories: null
    };
  }

  /**
   * Fetch categories from the API.
   * @returns {AxiosPromise}
   */
  fetchCategories() {
    const promise = vintageAxios.get('/products/categories/');
    promise.then(response => {
      this.setState({
        categories: response.data
      });
    });
    return promise;
  }

  componentDidMount() {
    this.fetchCategories();
  }

  /**
   * Get hierarchy categories.
   * @returns {String} hierarchyCategories
   */
  get getHierarchyCategories() {
    let parent = "";
    const {categories} = this.state;
    const queryParamCategory = parseInt(getParsedQueryParams()[QUERY_PARAM_CATEGORIES], 10);
    if (queryParamCategory) {
      if (categories) {
        let category = categories.find(_category => _category.id === queryParamCategory);
        let hierarchyCategories = category.name;
        while (category.parent) {
          parent = categories.find(_category => _category.id === category.parent.id);
          hierarchyCategories = `${hierarchyCategories} - ${parent.name}`;
          category = parent;
        }

        return hierarchyCategories;
      }

      return null;
    } else {
      const viewType = getParsedQueryParams()[VIEW_TYPE];

      if (viewType) {
        const nameCategory = PRODUCT_CATEGORIES_GROUPS[1].categories.find(
            productCategory => productCategory.reactQueryParams
                ? productCategory.reactQueryParams[VIEW_TYPE] === viewType
                : false
        ).name;

        return `${nameCategory} - Products`;
      } else {
        return 'Products';
      }
    }
  }

  render() {
    const {ogImage, ogImageWidth, ogImageHeight} = this.props;

    let content = Object.assign({}, _.find(Meta, {url: this.props.url}));

    switch (this.props.context) {
      case PRODUCTS_DETAILS_CONTEXT:
      case ORDERS_DETAILS_CONTEXT:
        const defaultText = Object.assign({}, _.find(Meta, {url: this.props.url}));
        content.title = `${this.props.information.title} - ${defaultText.title}`;
        break;
      case PRODUCTS_LIST_CONTEXT:
        content = Object.assign({}, _.find(Meta, {url: this.props.url}));
        const defaultTitle = content.title;
        content.title = `${this.getHierarchyCategories} ${defaultTitle}`;
        break;
      default:
        const {title, description} = this.props;

        if (!content)
          content = _.find(Meta, {url: 'default'});

        if (title)
          content.title = title;

        if (description)
          content.description = description;
        break;
    }

    return (
        <Helmet>
          <title>{content.title}</title>
          <meta name="description" content={content.description}/>
          <meta property="og:locale" content="es"/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content={content.title}/>
          <meta property="og:description" content={content.description}/>
          <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}${this.props.location.pathname}`}/>
          <meta property="og:site_name" content={`${process.env.REACT_APP_SITE_NAME}`}/>
          <meta property="og:image" content={ogImage}/>
          <meta property="og:image:width" content={ogImageWidth}/>
          <meta property="og:image:height" content={ogImageHeight}/>
          <meta property="og:image:secure_url" content={ogImage}/>
          <meta property="fb:admins" content={`${process.env.REACT_APP_FB_ADMINS}`}/>
          <meta property="fb:app_id" content={`${process.env.REACT_APP_FB_APP_ID}`}/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:title" content={content.title}/>
          <meta name="twitter:description" content={content.description}/>
          <meta name="twitter:site" content={`${process.env.REACT_APP_TWITTER_SITE}`}/>
          <meta name="twitter:image" content={ogImage}/>
          <meta name="twitter:creator" content={`${process.env.REACT_APP_TWITTER_SITE}`}/>
        </Helmet>
    );
  }
}


SEO.propTypes = {
  information: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  url: PropTypes.string.isRequired,
  ogImage: PropTypes.string,
  ogImageWidth: PropTypes.number,
  ogImageHeight: PropTypes.number
};

SEO.defaultProps = {
  context: OTHER_CONTEXT,
  ogImage: "http://www.aboutbluesprings.com/image.axd?picture=VintageStock.jpg",
  ogImageWidth: 2048,
  ogImageHeight: 1536
};

SEO = withRouter(SEO);

export default SEO;