import React from "react";
import TrustItem from "utils/components/TrustElements/components/TrustItem";
import "./styles/TrustElements.scss";

const TrustElements = (props) => (
    <div className="trust-elements">
      <div className="trust-elements__item">
        <TrustItem
            align="left"
            iconClassName="icon-circle-check" text="Product Guarantee"/>
      </div>
      <div className="trust-elements__item">
        <TrustItem
            align="left"
            iconClassName="icon-safe-purchase" text="100% Safe & Secure Checkout"/>
      </div>
      <div className="trust-elements__item">
        <TrustItem
            align="left"
            iconClassName="icon-returns" text="Easy Returns"/>
      </div>
      <div className="trust-elements__item">
        <TrustItem
            align="left"
            iconClassName="icon-customer-service" text={
          <span>Customer service: <a href="mailto:support@vintagestock.com">support@vintagestock.com</a></span>
        }/>
      </div>
    </div>
);

export default TrustElements;
