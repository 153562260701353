import React, {Component} from 'react';
import Slider from "react-slick";
import vintageAxios from "services/api/index";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "./styles/HomeSlider.scss";

class HomeSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slides: []
    };
  }

  componentDidMount() {
    this.fetchSlides();
  }

  /**
   * Fetch slides from api.
   * @returns {AxiosPromise<any>}
   */
  fetchSlides() {
    const promise = vintageAxios.get('/misc/mobile-site-sliders/');
    promise.then(response => {
      this.setState({
        slides: response.data
      });
    });
    return promise;
  }


  render() {
    const {slides} = this.state;
    const settings = {
      speed: 500,
      infinite: true,
      dots: true,
      autoplay: slides.length > 1,
      slidesToScroll: 1,
      slidesToShow: 1,
      autoplaySpeed: 5000
    };

    return (
        <section id="home-slider">
          <div id="home-carousel" className="carousel slide">
            <div className="carousel-inner">
              <Slider {...settings}>
                {
                  slides.map(slide => (
                      <div
                          key={slide.id}
                          className="item">
                        <a href={slide.url}>
                          <img
                              src={slide.image}
                              alt="Vintage Stock"/>
                        </a>
                      </div>
                  ))
                }
              </Slider>
            </div>
          </div>
        </section>
    )
  }
}

export default HomeSlider;
