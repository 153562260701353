import React from 'react';
import "./styles/GiftCards.scss";
import GiftCardsPublicity from "scenes/GiftCards/components/GiftsCardsPublicity/index";
import GiftCardsItem from "scenes/GiftCards/components/GiftCardsItem/index";
import {GIFT_CARD_ITEMS} from 'scenes/GiftCards/constants/constants'
import SEO from "components/SEO/index";

const GiftCards = () => (
    <section className="page-content">
      <div className="container">
        <div className="gift-cards-disclaimer">
          <h5>
            GIFT CARDS CAN ONLY BE USED FOR IN-STORE PURCHASES, NOT ONLINE.
          </h5>
        </div>
      </div>
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="col-sm-12">
              <GiftCardsPublicity/>
            </div>
          </div>
          {
            GIFT_CARD_ITEMS.map((giftCard, index) => (
                <div className="row" key={`giftCard${index}`}>
                  <div className="col-sm-12">
                    <GiftCardsItem
                        price={giftCard.price}
                        productId={giftCard.productId}/>
                  </div>
                </div>
            ))
          }
        </div>
      </div>
      <SEO url='gift-cards'/>
    </section>
);

export default GiftCards;
