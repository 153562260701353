import React from "react"
import _ from "lodash"
import useQueryParams from "hooks/useQueryParams"
import mapMarker from "./img/map-pin.svg"
import "./styles/ProductAvailableIn.scss"
import {QUERY_PARAM_STORE} from "scenes/Store/scenes/ProductsList/constants/query-params"
import LocationName from "utils/components/LocationName"

export default function ProductAvailableIn({stores}) {
  const queryParams = useQueryParams()
  let storeFromParams = queryParams.find(QUERY_PARAM_STORE)

  if (!storeFromParams) return null

  return (
      <div className="product-available-in">
        <div>
          <img src={mapMarker} alt=""/>
        </div>
        <div>
          <strong>
            <LocationName id={storeFromParams}/>
          </strong>
          {
            stores.length > 1 &&
            <span> and <strong>{stores.length - 1}+</strong></span>
          }
        </div>
      </div>
  )
}