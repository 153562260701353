import { connect } from 'react-redux';
import CheckoutCreditCardForm from "../index";
import {receiveCreditCards, selectCreditCard} from "scenes/MyAccount/actions";
import {selectCheckoutCreditCard, selectCheckoutBillingAddress, setCheckoutCVVCode} from "scenes/Store/scenes/Checkout/actions";
import {submit} from 'redux-form'

const mapStateToProps = (state) => {
  return {
    myAccountReducer: state.myAccountReducer,
    checkoutReducer: state.checkoutReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    receiveCreditCards: creditCards => dispatch(receiveCreditCards(creditCards)),
    selectCheckoutCreditCard: creditCardId => dispatch(selectCheckoutCreditCard(creditCardId)),
    selectCheckoutBillingAddress: addressId => dispatch(selectCheckoutBillingAddress(addressId)),
    submitCreditCardForm: () => dispatch(submit('accountCreditCardForm')),
    selectCreditCard: creditCard => dispatch(selectCreditCard(creditCard)),
    submitCVVCodeForm: () => dispatch(submit('cvvCodeForm')),
    setCheckoutCVVCode: cvvCode => dispatch(setCheckoutCVVCode(cvvCode)),
  };
};

const CheckoutCreditCardFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutCreditCardForm);

export default CheckoutCreditCardFormContainer;